import React, {
  CSSProperties,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_HEADER_HEIGHT, GIFT_SHOP_ROOT_MENU_WIDTH} from 'consts'
import {useWindowMode} from 'windows'
import {useTranslation} from 'i18n'
import {TemplateAuthAccessModal, ValidateKYCModal} from 'pages'
import {ThemeColor} from 'themes'
import {GiftShopNavBarOption, WindowModeType} from 'types'
import {
  getThemeColor,
  showModalLimitedAccess,
  showModalReprocessKyc,
  useHistory,
} from 'utils'
import {InputPicker} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopRootScreenProps} from './GiftShopRootScreenProps'
import {
  GiftShopRootBottomNavBar,
  GiftShopRootHeader,
  GiftShopRootNavBar,
} from '../containers'

interface StyledSectionChildrenProps {
  mode: WindowModeType
  hideSideNavbar?: boolean
}

interface StyledChildrenContentContainerProps {
  darkBar?: boolean
}

interface StyledSectionOverlayHeaderProps {
  scrollbar?: boolean
  opaqueHeader?: boolean
}

interface StyledSectionHeaderProps {
  borderColor?: ThemeColor
  headerHeight?: CSSProperties['height']
}

const StyledSectionHeader = styled.div<StyledSectionHeaderProps>`
  ${({theme, borderColor, headerHeight}) => ({
    borderColor: borderColor ? theme[borderColor] : theme.gray_1,
    height: headerHeight ?? convertUnit(GIFT_SHOP_HEADER_HEIGHT),
  })};
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  border-bottom-style: solid;
  border-bottom-width: ${convertUnit(1)};

  @media (max-width: 390px) {
    ${({headerHeight}) => ({
      height: headerHeight ?? 'unset',
    })};
  }
`

const StyledSectionOverlayHeader = styled.div<StyledSectionOverlayHeaderProps>`
  display: flex;
  flex-direction: row;
  height: ${convertUnit(GIFT_SHOP_HEADER_HEIGHT)};
  position: absolute;
  z-index: 1;
  ${({scrollbar}) => ({
    width: scrollbar ? `calc(100% - ${convertUnit(25)})` : `100%`,
  })}
  ${({theme, opaqueHeader}) =>
    opaqueHeader
      ? {
          background: 'white',
        }
      : {
          background: `linear-gradient(360deg, #00000000 0%, ${getThemeColor(
            theme,
            'black',
            0.5,
          )} 100%) `,
        }}
`

const StyledSectionContent = styled.div`
  overflow-x: hidden;
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
`

const StyledSectionChildren = styled.div<StyledSectionChildrenProps>`
  ${({mode, hideSideNavbar}) => ({
    paddingLeft:
      mode === 'website' && !hideSideNavbar
        ? convertUnit(GIFT_SHOP_ROOT_MENU_WIDTH)
        : undefined,
  })}
  display: flex;
  flex: 1;
`

const StyledChildrenBaseContainer = styled.div`
  flex: 1;
  position: relative;
`

const StyledChildrenContainer = styled.div<CSSProperties>`
  background-color: ${({theme, backgroundColor}) =>
    backgroundColor ? theme[backgroundColor] : theme.white_1};
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`

const StyledChildrenContentContainer = styled.div<StyledChildrenContentContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${({theme, darkBar}) =>
    darkBar ? theme.black : undefined};
`

export default function GiftShopRootScreen({
  id,
  headerTitle,
  headerSubtitle,
  headerSubtitleSize,
  headerButtons,
  rightButtons,
  children,
  childrenContainerStyle,
  hideBottomNavbar = false,
  hideSideNavbar = false,
  prevScreen,
  showBackIcon,
  showBadgeIcon,
  showHeader = true,
  scrollbar = false,
  overlayHeader = false,
  opaqueHeader = false,
  darkBar = false,
  darkHeader = false,
  showLeftBtn,
  leftButton,
  leftElement,
  darkBarColor,
  headerBorderColor,
  showMessageIcon,
  showLanguageToggle,
  headerHeight,
  hideSideGap,
  onBackButtonClicked,
  onClickHeader,
  onClickLeftBtn,
}: GiftShopRootScreenProps) {
  const history = useHistory()
  const ref = useRef<HTMLInputElement>(null)
  const {access_token, username} = useSelector('user') || {}
  const {kycStatus, creatorStatus} = useSelector('yuserActivationState')
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const {status: childStatus} = useSelector('parentalConsentState')
  const {translate} = useTranslation()
  const mode = useWindowMode()

  const [loginModal, setLoginModal] = useState(false)
  const [valKycModal, setValKycModal] = useState(false)
  const {lastViewedProfileTab} = useSelector('giftshopState')

  const handleNavigateProfile = useCallback(() => {
    if (access_token) {
      history.pushQuery({
        path: 'giftshop_profile',
        state: {self: true},
        queryObject: {
          type:
            creatorStatus !== 'verified' || lastViewedProfileTab === 'post'
              ? 'post'
              : 'all',
        },
        recursivePath: username,
      })
      return
    }
    /**
     * TODO: Implement proper handling for the path parameter
     *
     * The current implementation uses whitespace as a workaround to avoid returning ':username' in the path.
     * However, this approach may not be reliable in the long term and could introduce potential issues with the
     * application's behavior.
     */
    return history.push('giftshop_profile', {self: true}, ' ')
  }, [access_token, creatorStatus, history, lastViewedProfileTab, username])

  const handleFeatureDisabled = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    if (kycStatus === 'processing') {
      setValKycModal(true)
      return
    }
    history.push('giftshop_robopet', {})
  }, [childStatus, history, kycStatus, similarStatus])

  const navbarOptions = useMemo<GiftShopNavBarOption>(
    () => ({
      primary: [
        {
          key: 'home',
          label: translate('forkygram:home'),
          icon: id === 'home' ? 'home' : 'home-outline',
          onClick: () => history.push('giftshop_feed', {}),
        },
        {
          key: 'fotoyu',
          label: translate('global:fotoyu'),
          icon: 'fotoyu',
          onClick: () =>
            kycStatus === 'unregistered'
              ? history.push('giftshop_robopet_activation_selfie', {})
              : history.push('giftshop_explore', {}),
        },
        {
          key: 'profile',
          label: translate('forkygram:myAccount'),
          icon: id === 'profile' ? 'person' : 'person-ol',
          onClick: handleNavigateProfile,
        },
      ],
      secondary: [
        {
          key: 'fototree',
          label: translate('global:fototree'),
          icon: 'tree-feature',
          onClick: () => history.push('tree_fototree', {}),
        },
        {
          key: 'send_to_face',
          label: translate('giftShop:sendToFace'),
          icon: 'send-to-face-ol-icon',
          isSendToFace: true,
          onClick: () => {
            if (id !== 'send_to_face') {
              if (access_token) {
                if (
                  childStatus === 'UNDERAGE' ||
                  !(kycStatus === 'verified' || kycStatus === 'verified_check')
                ) {
                  handleFeatureDisabled()
                  return
                }
                ref.current?.click()
              } else setLoginModal(true)
            }
          },
        },
        {
          key: 'search_friends',
          label: translate('forkygram:searchFriends'),
          icon: 'search-friends',
          onClick: () => {
            if (id !== 'search_friends') {
              if (access_token) {
                if (
                  childStatus === 'UNDERAGE' ||
                  !(kycStatus === 'verified' || kycStatus === 'verified_check')
                ) {
                  handleFeatureDisabled()
                  return
                }
                history.push('forkygram_search_friends', {})
              } else setLoginModal(true)
            }
          },
        },
      ],
    }),
    [
      access_token,
      childStatus,
      handleFeatureDisabled,
      handleNavigateProfile,
      history,
      id,
      kycStatus,
      translate,
    ],
  )

  const handleRenderSideNavBar = useMemo(
    () =>
      mode === 'website' &&
      !hideSideNavbar && (
        <GiftShopRootNavBar
          select={id}
          data={navbarOptions}
          darkBar={darkBar}
          darkBarColor={darkBarColor}
        />
      ),
    [mode, hideSideNavbar, id, navbarOptions, darkBar, darkBarColor],
  )

  const handleRenderBottomNavBar = useMemo(
    () =>
      mode !== 'website' &&
      !hideBottomNavbar && (
        <GiftShopRootBottomNavBar
          select={id}
          data={navbarOptions}
          darkBar={darkBar}
          darkBarColor={darkBarColor}
        />
      ),
    [darkBar, darkBarColor, hideBottomNavbar, id, mode, navbarOptions],
  )

  const handleRenderHeader = useMemo(
    () =>
      showHeader ? (
        overlayHeader ? (
          <StyledSectionOverlayHeader
            scrollbar={scrollbar}
            opaqueHeader={opaqueHeader}>
            <GiftShopRootHeader
              darkMode={darkHeader}
              prevScreen={prevScreen}
              hideSideNavbar={hideSideNavbar}
              headerTitle={headerTitle}
              headerSubtitle={headerSubtitle}
              buttons={headerButtons}
              rightButtons={rightButtons}
              overlayHeader={overlayHeader}
              opaqueHeader={opaqueHeader}
              showBackIcon={showBackIcon}
              showBadgeIcon={showBadgeIcon}
              showLeftBtn={showLeftBtn}
              showMessageIcon={showMessageIcon}
              leftButton={leftButton}
              leftElement={leftElement}
              hideSideGap={hideSideGap}
              onBackButtonClicked={onBackButtonClicked}
              onClickHeader={onClickHeader}
              onClickLeftBtn={onClickLeftBtn}
            />
          </StyledSectionOverlayHeader>
        ) : (
          <StyledSectionHeader
            borderColor={headerBorderColor}
            headerHeight={headerHeight}>
            <GiftShopRootHeader
              darkMode={darkHeader}
              prevScreen={prevScreen}
              hideSideNavbar={hideSideNavbar}
              headerTitle={headerTitle}
              headerSubtitle={headerSubtitle}
              headerSubtitleSize={headerSubtitleSize}
              buttons={headerButtons}
              rightButtons={rightButtons}
              showBackIcon={showBackIcon}
              showBadgeIcon={showBadgeIcon}
              showLeftBtn={showLeftBtn}
              showMessageIcon={showMessageIcon}
              leftButton={leftButton}
              leftElement={leftElement}
              showLanguageToggle={showLanguageToggle}
              hideSideGap={hideSideGap}
              onBackButtonClicked={onBackButtonClicked}
              onClickHeader={onClickHeader}
              onClickLeftBtn={onClickLeftBtn}
            />
          </StyledSectionHeader>
        )
      ) : null,
    [
      darkHeader,
      headerBorderColor,
      headerButtons,
      headerHeight,
      headerSubtitle,
      headerSubtitleSize,
      headerTitle,
      hideSideGap,
      hideSideNavbar,
      leftButton,
      leftElement,
      onBackButtonClicked,
      onClickHeader,
      onClickLeftBtn,
      opaqueHeader,
      overlayHeader,
      prevScreen,
      rightButtons,
      scrollbar,
      showBackIcon,
      showBadgeIcon,
      showHeader,
      showLanguageToggle,
      showLeftBtn,
      showMessageIcon,
    ],
  )

  const handleRenderValidateKycModal = useMemo(
    () => (
      <ValidateKYCModal
        toggleModal={() => setValKycModal((prev) => !prev)}
        visible={valKycModal}
      />
    ),
    [valKycModal],
  )

  const handleRenderLoginModal = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal],
  )

  const handleRenderInput = useMemo(
    () => (
      <InputPicker
        multiple
        inputRef={ref}
        onLoadFiles={(files) =>
          files.length > 0 && history.push('giftshop_send_to_face', {files})
        }
      />
    ),
    [history],
  )

  return (
    <StyledSectionContent>
      {handleRenderInput}
      {handleRenderValidateKycModal}
      {handleRenderLoginModal}
      {handleRenderSideNavBar}
      {handleRenderHeader}
      <StyledSectionChildren mode={mode} hideSideNavbar={hideSideNavbar}>
        <StyledChildrenBaseContainer>
          <StyledChildrenContainer style={childrenContainerStyle}>
            <StyledChildrenContentContainer darkBar={darkBar}>
              {children}
            </StyledChildrenContentContainer>
          </StyledChildrenContainer>
        </StyledChildrenBaseContainer>
      </StyledSectionChildren>
      {handleRenderBottomNavBar}
    </StyledSectionContent>
  )
}
