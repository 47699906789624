import React from 'react'
import styled from 'styled-components'
import {ThemeColor} from 'themes'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {ForkygramTemplateListGridContentProps} from './ForkygramTemplateListGridContentProps'

interface StyledInnerContainerProps {
  backgroundColor: ThemeColor
}

interface StyledHeaderContainerProps {
  clickable?: boolean
}

interface StyledContainerProps {
  margin: number
  height: number | string
}

const StyledContainer = styled.div<StyledContainerProps>`
  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-fill-available;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  ${({margin, height}) => ({
    minHeight: typeof height === 'string' ? height : convertUnit(height),
    margin: `0 ${convertUnit(margin)}`,
  })};
`

const StyledInnerContainer = styled.div<StyledInnerContainerProps>`
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
  background-color: ${({theme, backgroundColor}) => theme[backgroundColor]};
  border-radius: ${convertUnit(8)};
  box-sizing: border-box;
`

const StyledHeader = styled.div`
  display: flex;
  flex: 1;
  gap: ${convertUnit(8)};
`

const StyledHeaderContainer = styled.div<StyledHeaderContainerProps>`
  ${({clickable}) => ({cursor: clickable ? 'pointer' : undefined})}
  display: flex;
  align-items: center;
  padding: ${convertUnit(8)} ${convertUnit(12)};
`

export default function ForkygramTemplateListGridContent({
  backgroundColor = 'white_4',
  header = false,
  headerIcon,
  headerTitle,
  color = 'black',
  children,
  innerMargin = 20,
  containerHeight = 600,
  headerRightElement,
  onClickHeader,
}: ForkygramTemplateListGridContentProps) {
  return (
    <StyledContainer height={containerHeight} margin={innerMargin}>
      <StyledInnerContainer backgroundColor={backgroundColor}>
        {header && (
          <StyledHeaderContainer
            onClick={onClickHeader}
            clickable={onClickHeader !== undefined}>
            <StyledHeader>
              {headerIcon && <Icon color={color} type={headerIcon} size={20} />}
              {headerTitle && (
                <Paragraph color={color} fontSize="m" fontWeight="bold">
                  {headerTitle}
                </Paragraph>
              )}
            </StyledHeader>
            {headerRightElement}
          </StyledHeaderContainer>
        )}
        {children}
      </StyledInnerContainer>
    </StyledContainer>
  )
}
