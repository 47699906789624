import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload} from 'types'
import {getCurrencyValue, getFontFamily, parseDate} from 'utils'
import {Paragraph, ParsedText} from 'common/components'
import {useSelector} from 'lib/redux'

const StyledParagraph = styled(Paragraph)`
  white-space: pre-wrap;
`

export interface GiftShopMessageRoomContentItemNotificationWithdrawCancelledProps {
  payload: FirebaseMessageRoomItemPayload['notification_withdraw_cancelled']
}

export default function GiftShopMessageRoomContentItemNotificationWithdrawCancelled({
  payload: {withdraw_amount, withdraw_date, withdraw_id},
}: GiftShopMessageRoomContentItemNotificationWithdrawCancelledProps) {
  const {translate} = useTranslation()
  const {username} = useSelector('user')!
  const parsedAmount = parseInt(withdraw_amount, 10)

  return (
    <StyledParagraph fontSize="m">
      <ParsedText
        parse={[
          {
            pattern: /<bold>.*?<\/bold>/,
            style: {
              fontFamily: getFontFamily('bold'),
            },
            renderText: (text) => text.replace(/<bold>|<\/bold>/g, ''),
          },
        ]}>
        {translate('messages:notificationWithdrawCancelled', {
          username,
          withdraw_id,
          withdraw_amount: getCurrencyValue(parsedAmount),
          withdraw_date: parseDate(withdraw_date, 'D MMMM YYYY, HH:mm:ss'),
        })}
      </ParsedText>
    </StyledParagraph>
  )
}
