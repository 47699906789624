import React from 'react'
import {useTranslation} from 'i18n'
import GiftShopTemplateOptionsItem from './GiftShopTemplateOptionsItem'
import {GiftShopTemplateOptionsModalProps} from './GiftShopTemplateOptionsProps'
import GiftShopTemplateOptionsContent from './GiftShopTemplateOptionsContent'

export default function GiftShopFeedOptionsModal({
  isProfilePost,
  onBlock,
  onDelete,
  onRemoveCollab,
  onReport,
  onShare,
  toggleModal,
}: GiftShopTemplateOptionsModalProps) {
  const {translate} = useTranslation()

  return (
    <GiftShopTemplateOptionsContent visible toggleModal={toggleModal}>
      <div>
        {onShare && (
          <GiftShopTemplateOptionsItem
            icon="share"
            text={translate('global:shareContent')}
            onClickItem={onShare}
          />
        )}
        {onReport && (
          <GiftShopTemplateOptionsItem
            icon="report"
            text={translate('global:report')}
            onClickItem={onReport}
          />
        )}
        {onDelete && (
          <GiftShopTemplateOptionsItem
            icon="trash"
            text={translate(
              isProfilePost ? 'forkygram:postDelete' : 'global:deleteContent',
            )}
            onClickItem={onDelete}
          />
        )}
        {onRemoveCollab && (
          <GiftShopTemplateOptionsItem
            icon="circular-cross"
            color="danger_5"
            text={translate('tree:removeCollaboration')}
            onClickItem={onRemoveCollab}
          />
        )}
        {onBlock && (
          <GiftShopTemplateOptionsItem
            icon="block"
            text={translate('forkygram:blockYuserAcc')}
            onClickItem={onBlock}
          />
        )}
      </div>
    </GiftShopTemplateOptionsContent>
  )
}
