import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {
  AUTH_USERNAME_MAX_LENGTH,
  IMAGE_ASSET,
  REGEX_ALLOWED_USERNAME,
  REGEX_EMOJI,
} from 'consts'
import {useTranslation} from 'i18n'
import {AuthFormSimpleRegisterData, AuthRecoverAccountPayload} from 'types'
import {requestData} from 'services'
import {showSnackbar, useHistory} from 'utils'
import {Button, Image, Input, Paragraph} from 'common/components'
import {VALIDATION_AUTH_USERNAME} from 'common/validations'
import convertUnit from 'lib/unit'
import {useDispatch} from 'lib/redux'

const StyledContainer = styled.div`
  padding: ${convertUnit(20)};
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`
const StyledHeaderDescription = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
  text-align: center;
`
const StyledButton = styled(Button)`
  margin-top: ${convertUnit(25)};
`
const StyledIllustrationContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${convertUnit(200)};
  align-items: center;
  justify-content: center;
`
const StyledIllustration = styled(Image)`
  width: 100%;
  height: 100%;
  max-width: ${convertUnit(350)};
  object-fit: contain;
`

interface AuthRecoverAccountUsernameVerifProps {
  data?: AuthRecoverAccountPayload
}
export default function AuthRecoverAccountUsernameVerif({
  data,
}: AuthRecoverAccountUsernameVerifProps) {
  const history = useHistory()
  const {translate} = useTranslation()
  const [pageError, setPageError] = useState(false)
  const expiredImage = IMAGE_ASSET('giftshop', 'failed-illustration.png')
  const form = useForm<AuthFormSimpleRegisterData>({
    defaultValues: {
      username: '',
    },
  })
  const {watch, errors, setValue, setError} = form
  const {username} = watch()
  const {update} = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const isDisabled = useMemo(
    () => username === '' || errors.username !== undefined,
    [errors.username, username],
  )

  const handleVerifyUsername = useCallback(() => {
    if (data?.member_id && data.recovery_code && data.verification_code) {
      setIsLoading(true)
      const {member_id, recovery_code, verification_code} = data
      requestData('auth_recover_account', {
        data: {
          member_id,
          recovery_code,
          verification_code,
          username,
        },
        onRequestReceived: () => setIsLoading(false),
        onRequestSuccess: ({status, data: {result, detail}}) => {
          if (status === 200) {
            update('user', result)
            history.replace('auth_recover_account_create_password', {
              member_id,
              recovery_code,
              verification_code,
            })
          } else if (
            status === 400 &&
            detail &&
            detail.username === 'invalid'
          ) {
            setError('username', {
              type: 'unavailable',
              message: translate('auth:registerEasySignupUsernameNotMatch'),
            })
          } else {
            setPageError(true)
            showSnackbar(
              translate('global:messageError', {context: `code-${status}`}),
            )
          }
        },
      })
    }
  }, [data, history, setError, translate, update, username])

  const handleRenderTitle = useMemo(
    () => (
      <>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {translate('auth:registerEasySignupWelcomeTitle')}
        </StyledParagraph>
        <StyledHeaderDescription fontSize="m" color="gray_5">
          {translate('auth:registerEasySignupWelcomeMessage')}
        </StyledHeaderDescription>
      </>
    ),
    [translate],
  )

  const handleRenderForm = useMemo(
    () => (
      <Input
        name="username"
        form={form}
        autoComplete={false}
        formRules={VALIDATION_AUTH_USERNAME}
        label={translate('global:username')}
        placeholder={translate('auth:registerEasySignupWelcomePlaceholder')}
        containerStyle={{marginTop: convertUnit(25)}}
        rightIcon="delete"
        maxLength={AUTH_USERNAME_MAX_LENGTH}
        allowedCharacters={REGEX_ALLOWED_USERNAME}
        onChangeText={() =>
          setValue('username', username.toLowerCase().replace(REGEX_EMOJI, ''))
        }
      />
    ),
    [form, setValue, translate, username],
  )
  const handleRenderInput = useMemo(
    () => (
      <StyledContainer>
        {handleRenderTitle}
        {handleRenderForm}
        <StyledButton
          label={translate('global:continue')}
          disabled={isDisabled}
          isLoading={isLoading}
          onClick={handleVerifyUsername}
        />
      </StyledContainer>
    ),
    [
      handleRenderForm,
      handleRenderTitle,
      handleVerifyUsername,
      isDisabled,
      isLoading,
      translate,
    ],
  )

  const handleRenderFailed = useMemo(
    () => (
      <StyledContainer>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {translate('global:sorry')}
        </StyledParagraph>
        <StyledHeaderDescription fontSize="m" color="gray_5">
          {translate('global:messageError')}
        </StyledHeaderDescription>
        <StyledIllustrationContainer>
          <StyledIllustration src={expiredImage} alt="Something went wrong" />
        </StyledIllustrationContainer>
      </StyledContainer>
    ),
    [expiredImage, translate],
  )

  const handleRenderContent = useMemo(
    () => (pageError ? handleRenderFailed : handleRenderInput),
    [handleRenderFailed, handleRenderInput, pageError],
  )
  return handleRenderContent
}
