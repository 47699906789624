import {useCallback, useMemo, useRef} from 'react'
import {
  LayoutDimension,
  LayoutView,
  ListIndicatorStatus,
  ListLazyAction,
  ListLazyActionLoad,
  ListNotLazyActionParam,
} from 'types'
import {LIST_LOAD_LIMIT} from 'consts'
import {
  useDefaultState,
  useDidMount,
  useDidUpdate,
  useMapRef,
} from '../../objects'

export function useListNotLazyAction<ItemT, S>({
  data,
  stateData,
  search,
  refresh,
  refreshing,
  refreshDefaultEnabled = true,
  limitPerPage = LIST_LOAD_LIMIT,
  assertPage,
  defaultPage,
  limitContent,
  loadData,
  primaryExtractor,
  onEndReached,
  onContentSizeChange,
  onRefresh,
  onLayout,
  onLayoutDimension,
}: ListNotLazyActionParam<ItemT, S>) {
  const emptyData = useRef([]).current
  const stateItems = useDefaultState(data || emptyData, stateData)
  const [items, setItems] = stateItems
  const {map, setMapValue, updateMap} = useMapRef({
    initialize: !!items.length,
    loading: true,
    failed: false,
    endPage: false,
    page: defaultPage,
    refreshDefault: false,
  })
  const {initialize, loading, failed, page, refreshDefault} = map.current

  const status = useMemo((): ListIndicatorStatus => {
    if (!initialize) {
      return 'initialize'
    }
    if (!failed && !loading && items.length === 0) {
      return 'empty'
    }
    if (loading) {
      return 'loading'
    }
    if (failed) {
      return 'failed'
    }

    return 'normal'
  }, [failed, initialize, items.length, loading])

  const pageRefresh = useMemo(
    () =>
      refreshing !== undefined || !refreshDefaultEnabled
        ? undefined
        : refreshDefault,
    [refreshing, refreshDefaultEnabled, refreshDefault],
  )

  const handleLoadData = useCallback(
    async ({page: newPage = page}: ListLazyActionLoad) => {
      setMapValue({initialize: false})
      setItems((prev) => (!limitContent ? [] : prev))
      setMapValue({page: newPage, loading: true})

      if (limitContent) {
        setMapValue({
          initialize: true,
          refreshDefault: false,
          loading: false,
        })
        return
      }

      const responses = await loadData(newPage, limitPerPage, search)
      const primaryData = primaryExtractor
        ? responses?.filter(primaryExtractor)
        : responses

      if (responses === undefined) return

      setMapValue({
        initialize: true,
        refreshDefault: false,
        loading: false,
        failed: responses === null,
        endPage: !primaryData || primaryData.length < limitPerPage,
      })

      if (Array.isArray(responses)) {
        setItems(responses)
      } else {
        updateMap()
      }
    },
    [
      limitContent,
      limitPerPage,
      loadData,
      page,
      primaryExtractor,
      search,
      setItems,
      setMapValue,
      updateMap,
    ],
  )

  const handleSearch = useCallback(
    () => handleLoadData({merge: false, page: 1}),
    [handleLoadData],
  )

  const handleRefresh = useCallback(() => {
    onRefresh && onRefresh()
    setMapValue({refreshDefault: true})
    handleLoadData({merge: false, page: 1})
  }, [onRefresh, setMapValue, handleLoadData])

  const handleRetry = useCallback(() => handleLoadData({}), [handleLoadData])

  const handleEndReached = useCallback(
    (info: {distanceFromEnd: number}) => {
      onEndReached && onEndReached(info)
    },
    [onEndReached],
  )

  const handleLayout = useCallback(
    (layout: LayoutView) => {
      onLayout && onLayout(layout)
    },
    [onLayout],
  )

  const handleLayoutDimension = useCallback(
    (layout: LayoutDimension) => {
      onLayoutDimension && onLayoutDimension(layout)
    },
    [onLayoutDimension],
  )

  const handleContentSizeChange = useCallback(
    (width: number, height: number) => {
      onContentSizeChange && onContentSizeChange(width, height)
    },
    [onContentSizeChange],
  )

  useDidMount(() => {
    handleLoadData({page: assertPage})
  })

  useDidUpdate(() => {
    handleLoadData({page: assertPage})
  }, [assertPage])

  useDidUpdate(() => setItems(data || emptyData), [data])

  useDidUpdate(() => {
    initialize && handleSearch()
  }, [search])

  useDidUpdate(() => {
    initialize && handleRefresh()
  }, [refresh])

  return useMemo<ListLazyAction<ItemT>>(
    () => ({
      items,
      status,
      pageRefresh,
      handleRefresh,
      handleRetry,
      handleEndReached,
      handleContentSizeChange,
      handleLayout,
      handleLayoutDimension,
    }),
    [
      items,
      status,
      pageRefresh,
      handleRefresh,
      handleRetry,
      handleEndReached,
      handleContentSizeChange,
      handleLayout,
      handleLayoutDimension,
    ],
  )
}
