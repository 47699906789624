import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {Icon, ListLazy} from 'common/components'
import {FORKYGRAM_FEED_QUERY_LIMIT, WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import convertUnit from 'lib/unit'
import {TreeFotoTreeLeafData} from 'types'
import {getThemeColor, useHistory} from 'utils'
import {GiftShopFeedEmpty} from '../../../../gift-shop'
import {TemplateProfileDetailContentSkeleton} from '../../../../template'
import FototreeWishlistContent from './FototreeWishlistContent'
import FototreeMemoryContent from './FototreeMemoryContent'
import {FototreeMemoryContentListProps} from './FototreeContentProps'

const StyledIconContainer = styled.div`
  position: absolute;
  align-self: center;
  top: ${convertUnit(20)};
  z-index: 2;
  width: 100%;
  max-width: ${convertUnit(600)};

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    left: ${convertUnit(20)};
  }
`

const StyledRoundedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.4)};
  width: ${convertUnit(42)};
  height: ${convertUnit(42)};
  border-radius: ${convertUnit(28)};
  cursor: pointer;
`

export default function FototreeMemoryContentList({
  stateScrollPos,
  stateData,
  stateEdit,
  stateMute,
  statePause,
  selectedId,
  dataTag,
  initialPage,
  loadData,
}: FototreeMemoryContentListProps) {
  const history = useHistory()
  const [scrollPos, setScrollPos] = stateScrollPos
  const setData = stateData[1]
  const setEdit = stateEdit[1]

  const handleReport = useCallback(
    (id: string) => {
      setData((prev) =>
        prev.filter((post) => post.type === 'footprint' && post.post_id !== id),
      )
    },
    [setData],
  )

  const handleFollow = useCallback(
    (item: TreeFotoTreeLeafData, memberId: string) => {
      if (item.type !== 'footprint') return
      setData((prev) =>
        prev.map((x) =>
          x.type === 'footprint' &&
          x.collaborator_members.some((m) => m.id === memberId)
            ? {
                ...x,
                collaborator_members: x.collaborator_members.map((m) =>
                  m.id === memberId ? {...m, is_followed: true} : m,
                ),
              }
            : x,
        ),
      )
    },
    [setData],
  )

  const handleRemoveCollaborator = useCallback(
    (item: TreeFotoTreeLeafData, memberId: string) => {
      if (item.type !== 'footprint') return
      setData((prev) =>
        prev.map((x) =>
          x.type === 'footprint' && x.post_id === item.post_id
            ? {
                ...x,
                collaborator_members: x.collaborator_members.filter(
                  (m) => m.id !== memberId,
                ),
              }
            : x,
        ),
      )
    },
    [setData],
  )

  const selectedComponentRef = useCallback((node: HTMLDivElement) => {
    node &&
      node.scrollIntoView({
        block: 'start',
        inline: 'nearest',
      })
  }, [])

  const handleRenderHeader = useMemo(
    () => (
      <StyledIconContainer>
        <StyledRoundedIcon>
          <Icon
            type="back"
            size={convertUnit(20)}
            color="white_1"
            onClick={history.goBack}
          />
        </StyledRoundedIcon>
      </StyledIconContainer>
    ),
    [history.goBack],
  )

  const handleRenderItem = useCallback(
    (item: TreeFotoTreeLeafData, index: number) =>
      item.type === 'fotoyu' ? (
        <FototreeWishlistContent
          componentRef={
            selectedId === item.content_id ? selectedComponentRef : null
          }
          data={item}
          index={index}
          scrollPosition={scrollPos}
          statePause={statePause}
          stateMute={stateMute}
        />
      ) : (
        <FototreeMemoryContent
          componentRef={
            selectedId === item.post_id ? selectedComponentRef : null
          }
          data={item}
          dataTag={dataTag}
          index={index}
          statePause={statePause}
          stateMute={stateMute}
          scrollPosition={scrollPos}
          onReported={handleReport}
          onEdited={() => setEdit(item)}
          onFollowed={(memberId) => handleFollow(item, memberId)}
          onCollabRemoved={(memberId) =>
            handleRemoveCollaborator(item, memberId)
          }
        />
      ),
    [
      dataTag,
      handleFollow,
      handleRemoveCollaborator,
      handleReport,
      scrollPos,
      selectedComponentRef,
      selectedId,
      setEdit,
      stateMute,
      statePause,
    ],
  )

  const handleRenderList = useMemo(
    () => (
      <ListLazy
        keyExtractor={(item) =>
          item.type === 'fotoyu' ? item.content_id : item.post_id
        }
        primaryExtractor={(item) => item.type === 'footprint'}
        stateData={stateData}
        loadData={loadData}
        renderItem={handleRenderItem}
        listInitializeElement={<TemplateProfileDetailContentSkeleton />}
        listEmptyElement={<GiftShopFeedEmpty />}
        scrollbar={false}
        initialPage={initialPage}
        limitPerPage={FORKYGRAM_FEED_QUERY_LIMIT}
        scrollSnapType="y mandatory"
        onScrollList={(e) => setScrollPos(e.currentTarget.scrollTop)}
      />
    ),
    [handleRenderItem, initialPage, loadData, setScrollPos, stateData],
  )

  return (
    <>
      {handleRenderHeader}
      {handleRenderList}
    </>
  )
}
