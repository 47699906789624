import React, {useCallback, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {AuthFormSimpleRegisterData, ObjectState} from 'types'
import {requestData} from 'services'
import {showSnackbar} from 'utils'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch} from 'lib/redux'
import {GiftShopTemplateInputPassword} from '../Input'

const StyledTitle = styled(Paragraph)`
  text-align: center;
  margin-bottom: ${convertUnit(16)};
`
const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
`
const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(20)};
  gap: ${convertUnit(20)};
`
const StyledButton = styled(Button)`
  width: 100%;
`

interface GiftShopTemplateModalPasswordProps {
  stateModal: ObjectState<boolean>
  onCancel?(): void
  onSuccess?(): void
}

export default function GiftShopTemplateModalPassword({
  stateModal,
  onCancel,
  onSuccess,
}: GiftShopTemplateModalPasswordProps) {
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const [modalPassword, setModalPassword] = stateModal

  const form = useForm<AuthFormSimpleRegisterData>({
    defaultValues: {
      password: '',
      confirm: '',
    },
  })
  const {watch} = form
  const {password} = watch()
  const [loading, setLoading] = useState(false)
  const stateDisabled = useState(true)
  const disabled = stateDisabled[0]
  const statePasswordError = useState('')
  const setPasswordError = statePasswordError[1]

  const handleCancel = useCallback(() => {
    setModalPassword(false)
    setPasswordError('')
    onCancel && onCancel()
  }, [onCancel, setModalPassword, setPasswordError])

  const handleSave = useCallback(() => {
    setLoading(true)
    requestData('auth_patch_passwordsso', {
      data: {password},
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          showSnackbar(translate('auth:successCreatePassword'))
          update('user', {has_password: true})
          setModalPassword(false)
          onSuccess && onSuccess()
        } else {
          showSnackbar(translate('global:messageError', {context: status}))
        }
      },
    })
  }, [onSuccess, password, setModalPassword, translate, update])

  return (
    <StyledModal visible={modalPassword}>
      <StyledTitle fontSize="l" fontWeight="bold">
        {translate('auth:registerEasySignupCreatePassword')}
      </StyledTitle>
      <GiftShopTemplateInputPassword
        stateDisabled={stateDisabled}
        form={form}
        statePasswordError={statePasswordError}
      />
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          color="primary_5"
          backgroundColor="white_3"
          onClick={handleCancel}
        />
        <StyledButton
          label={translate('global:save')}
          disabled={disabled}
          isLoading={loading}
          onClick={handleSave}
        />
      </StyledButtonContainer>
    </StyledModal>
  )
}
