import React, {useCallback, useMemo, useRef, useState} from 'react'
import heic2any from 'heic2any'
import styled from 'styled-components'
import {
  FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE,
  FORKYGRAM_SPECIAL_IMAGE_UPLOAD_TYPE,
  FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE,
  FORKYGRAM_SPECIAL_VIDEO_UPLOAD_TYPE,
  FORKYGRAM_UPLOAD_MAX_IMAGE_SIZE,
  FORKYGRAM_UPLOAD_MAX_RESOLUTION,
  FORKYGRAM_UPLOAD_MAX_VIDEO_DURATION,
  FORKYGRAM_UPLOAD_MAX_VIDEO_SIZE,
  FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH,
  FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION,
  FORKYGRAM_UPLOAD_TYPE,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {
  ForkygramFootprintListGridContent,
  ForkygramPinnedPostListGridContent,
  ForkygramPostListGridContent,
} from 'pages'
import {
  useHistory,
  showSnackbar,
  showModalReprocessKyc,
  showModalLimitedAccess,
} from 'utils'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {Icon, Paragraph} from 'common/components'
import {TemplateSorryModalContent} from '../../../sorry-modal'
import {TemplateProfileEmptyTab} from '../EmptyTab'
import {TemplateProfileTabPostProps} from './TemplateProfileTabItemProps'

const StyledProfilePrivateContainer = styled.div`
  background-color: ${({theme}) => theme.white_5};
  height: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${convertUnit(20)};
`

const StyledListContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${convertUnit(20)} 0;
  gap: ${convertUnit(20)};
  background-color: ${({theme}) => theme.white_3};
  box-sizing: border-box;
  overflow-y: scroll;
  scrollbar-width: 0;

  ::-webkit-scrollbar {
    width: 0;
  }

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    height: calc(100% + 64);
  }
`

const StyledEmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledUploadInput = styled.input`
  width: 0;
  height: 0;
  overflow: hidden;
`

export default function TemplateProfileTabPost({
  self,
  memberId,
  kycStatus,
  profileData,
}: TemplateProfileTabPostProps) {
  const {id = ''} = useSelector('user') || {}
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const {status: childStatus} = useSelector('parentalConsentState')
  const {translate} = useTranslation()
  const history = useHistory()
  const [postModal, setPostModal] = useState(false)
  const uploadRef = useRef<HTMLInputElement>(null)

  const handleFeatureDisabled = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    setPostModal((prev) => !prev)
  }, [childStatus, history, kycStatus, similarStatus])

  const handleRenderUploadPostKYCModal = useMemo(
    () => (
      <TemplateSorryModalContent
        visible={postModal}
        upperDesc={translate('forkygram:uploadPostActivateRoboyuUpperDesc')}
        bottomDesc={translate('forkygram:uploadPostActivateRoboyuBottomDesc')}
        toggleModal={() => setPostModal((prev) => !prev)}
      />
    ),
    [postModal, translate],
  )

  const handleCheckImage = useCallback(() => {
    if (uploadRef.current?.files?.length) {
      let img = uploadRef.current.files[0]
      const {size, name} = img
      let {type} = img

      if (type === '') {
        type = name.substring(name.lastIndexOf('.') + 1).toLowerCase() ?? ''

        FORKYGRAM_UPLOAD_TYPE.some((currentType) => {
          const typeMatchesWithExtension = currentType.includes(type)
          if (typeMatchesWithExtension) type = currentType
          return typeMatchesWithExtension
        })
      }

      const handlingSpecialImageType = FORKYGRAM_SPECIAL_IMAGE_UPLOAD_TYPE.includes(
        type,
      )
      const handlingSpecialVideoType = FORKYGRAM_SPECIAL_VIDEO_UPLOAD_TYPE.includes(
        type,
      )

      if (!FORKYGRAM_UPLOAD_TYPE.includes(type)) {
        showSnackbar(
          img.type.startsWith('image')
            ? translate('forkygram:uploadInvalidImageFormat')
            : translate('forkygram:uploadInvalidVideoFormat'),
        )
        return
      }
      if (type.startsWith('image') && size > FORKYGRAM_UPLOAD_MAX_IMAGE_SIZE) {
        showSnackbar(translate('forkygram:uploadMaxImageSizeMessage'))
        return
      }

      if (type.startsWith('video') && size > FORKYGRAM_UPLOAD_MAX_VIDEO_SIZE) {
        showSnackbar(translate('forkygram:uploadMaxVideoSizeMessage'))
        return
      }

      const reader = new FileReader()
      reader.onload = async () => {
        const url = reader.result?.toString()

        if (!url) return

        if (
          url?.includes('image') ||
          (type.includes('image') && handlingSpecialImageType)
        ) {
          const image = document.createElement('img')

          if (handlingSpecialImageType) {
            type = FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE

            const newImage = await fetch(url)
              .then((res) => res.blob())
              .then((blob) =>
                heic2any({
                  blob,
                  toType: FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE,
                }),
              )
              .then((result) => {
                image.src = URL.createObjectURL(result as Blob)
                const finalName = name.replace(
                  /\.(.)+/g,
                  `.${
                    FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE.split('/')[1]
                  }`,
                )
                return new File([result as Blob], finalName, {
                  type: FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE,
                })
              })
              .catch(() => {})

            img = newImage as File
          } else {
            image.src = url
          }

          image.onload = () => {
            const {width, height} = image

            if (width * height > FORKYGRAM_UPLOAD_MAX_RESOLUTION) {
              showSnackbar(
                translate('forkygram:uploadMaxImageResolutionMessage'),
              )
              return
            }
            if (
              width < FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION ||
              height < FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION
            ) {
              showSnackbar(
                translate('forkygram:uploadMinImageResolutionMessage'),
              )
              return
            }
            history.push('forkygram_upload', {file: img})
          }
        }
        if (
          url?.includes('video') ||
          (type.includes('video') && handlingSpecialVideoType)
        ) {
          const vid = document.createElement('video')

          if (handlingSpecialVideoType) {
            type = FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE

            const finalName = name.replace(
              /\.(.)+/g,
              `.${FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE.split('/')[1]}`,
            )

            const newVideo = await fetch(url)
              .then((res) => res.blob())
              .then(
                (res) =>
                  new File([res], finalName, {
                    type: FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE,
                  }),
              )

            vid.src = URL.createObjectURL(newVideo)
            img = newVideo as File
          } else {
            vid.src = url
          }

          vid.onloadedmetadata = () => {
            const {videoWidth, duration} = vid

            if (duration > FORKYGRAM_UPLOAD_MAX_VIDEO_DURATION) {
              showSnackbar(translate('forkygram:uploadMaxVideoDurationMessage'))
              return
            }
            if (videoWidth > FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH) {
              showSnackbar(translate('forkygram:uploadMaxVideoWidthMessage'))
              return
            }
            history.push('forkygram_upload', {file: img})
          }
        }
      }
      reader.readAsDataURL(img)
    }
  }, [history, translate])

  const handleRenderEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        {self ? (
          <TemplateProfileEmptyTab
            title={translate('forkygram:postEmptyHeader')}
            description={translate('forkygram:postEmptyDescription')}
            buttonLabel={translate('forkygram:postEmptyButton')}
            onClickButton={() => {
              if (
                childStatus === 'UNDERAGE' ||
                !(kycStatus === 'verified' || kycStatus === 'verified_check')
              ) {
                handleFeatureDisabled()
                return
              }
              uploadRef.current?.click()
            }}
          />
        ) : (
          <TemplateProfileEmptyTab
            title={translate('forkygram:noPost')}
            description={
              profileData?.is_banned
                ? translate('forkygram:noPostDescription')
                : ''
            }
          />
        )}
      </StyledEmptyContainer>
    ),
    [
      self,
      translate,
      profileData?.is_banned,
      childStatus,
      kycStatus,
      handleFeatureDisabled,
    ],
  )

  const handleRenderInputFile = useMemo(
    () => (
      <StyledUploadInput
        type="file"
        ref={uploadRef}
        onChange={handleCheckImage}
        accept={FORKYGRAM_UPLOAD_TYPE.join(',')}
      />
    ),
    [handleCheckImage],
  )

  const handleRenderPrivateAccount = useMemo(
    () => (
      <StyledProfilePrivateContainer>
        <Icon type="padlock" size={convertUnit(120)} color="gray_5" />
        <Paragraph fontSize="l" fontWeight="bold">
          {translate('forkygram:privateAccount')}
        </Paragraph>
      </StyledProfilePrivateContainer>
    ),
    [translate],
  )

  return (
    <>
      {handleRenderInputFile}
      {handleRenderUploadPostKYCModal}
      {!self && profileData?.is_underage ? (
        handleRenderPrivateAccount
      ) : (
        <StyledListContainer>
          <ForkygramPinnedPostListGridContent
            self={self}
            memberId={self ? id : memberId}
          />
          <ForkygramFootprintListGridContent
            self={self}
            memberId={self ? id : memberId}
          />
          <ForkygramPostListGridContent
            self={self}
            memberId={self ? id : memberId}
            renderEmpty={handleRenderEmpty}
          />
        </StyledListContainer>
      )}
    </>
  )
}
