import {
  API_FORKYGRAM_ADMIN_STATUS,
  API_FORKYGRAM_MESSAGE,
  API_FORKYGRAM_MESSAGE_ROOM,
  API_MEMBER_OTHER_INFO,
  API_MEMBER_SEARCH,
  API_MEMBER_SEARCH_MESSAGE,
  API_MUSICS,
  API_POST_COLLABORATOR,
  API_POST_COLLABORATOR_STATUS,
  API_POST_COMMENT,
  API_POST_EDIT_POSTS,
  API_POST_FOLLOWING,
  API_POST_LEADERBOARD,
  API_POST_LIKE,
  API_POST_LIKE_COMMENT,
  API_POST_PIN_POSTS,
  API_POST_POSTS,
  API_POST_POSTS_DETAIL,
  API_POST_POSTS_LINK,
  API_POST_POSTS_SHARE,
  API_POST_REPLY,
  API_POST_SELF,
  API_POST_TIMELINE,
  API_POST_UGC,
  API_REPORT_BLOCK_ACCOUNT,
  API_REPORT_COMMENTS,
  API_REPORT_POSTS,
  API_REPORT_YUSER,
} from 'consts'
import {ServiceMap} from 'types'
import {getAPIBaseUrl} from 'utils'
import {ServiceParamForkygram} from './ServiceParamForkygram'

export const SERVICE_MAP_FORKYGRAM: ServiceMap<ServiceParamForkygram> = {
  forkygram_delete_collaborator: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_POST_COLLABORATOR,
  },
  forkygram_delete_comment: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_POST_COMMENT,
  },
  forkygram_delete_like: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_POST_LIKE,
  },
  forkygram_delete_like_comment: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_POST_LIKE_COMMENT,
  },
  forkygram_delete_pin_posts: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_POST_PIN_POSTS,
  },
  forkygram_delete_post: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_POST_POSTS,
  },
  forkygram_delete_unblock_account: {
    method: 'DELETE',
    url: getAPIBaseUrl() + API_REPORT_BLOCK_ACCOUNT,
  },
  forkygram_get_collaborator_status: {
    method: 'GET',
    url: getAPIBaseUrl() + API_POST_COLLABORATOR_STATUS,
  },
  forkygram_get_comment: {
    method: 'GET',
    url: getAPIBaseUrl() + API_POST_COMMENT,
  },
  forkygram_get_feeds: {
    method: 'GET',
    url: getAPIBaseUrl() + API_POST_TIMELINE,
  },
  forkygram_get_fototree_leaderboard: {
    method: 'GET',
    url: getAPIBaseUrl() + API_POST_LEADERBOARD,
  },
  forkygram_get_like: {
    method: 'GET',
    url: getAPIBaseUrl() + API_POST_LIKE,
  },
  forkygram_get_member: {
    method: 'GET',
    url: getAPIBaseUrl() + API_MEMBER_SEARCH,
  },
  forkygram_get_other_posts: {
    method: 'GET',
    url: getAPIBaseUrl() + API_POST_POSTS,
  },
  forkygram_get_other_profile: {
    method: 'GET',
    url: getAPIBaseUrl() + API_MEMBER_OTHER_INFO,
  },
  forkygram_get_pin_posts: {
    method: 'GET',
    url: getAPIBaseUrl() + API_POST_PIN_POSTS,
  },
  forkygram_get_post_detail: {
    method: 'GET',
    url: getAPIBaseUrl() + API_POST_POSTS_DETAIL,
  },
  forkygram_get_reply: {
    method: 'GET',
    url: getAPIBaseUrl() + API_POST_REPLY,
  },
  forkygram_get_report_comments_categories: {
    method: 'GET',
    url: getAPIBaseUrl() + API_REPORT_COMMENTS,
  },
  forkygram_get_report_posts_categories: {
    method: 'GET',
    url: getAPIBaseUrl() + API_REPORT_POSTS,
  },
  forkygram_get_report_yuser_categories: {
    method: 'GET',
    url: getAPIBaseUrl() + API_REPORT_YUSER,
  },
  forkygram_get_self_posts: {
    method: 'GET',
    url: getAPIBaseUrl() + API_POST_SELF,
  },
  forkygram_patch_collaborator_status: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_POST_COLLABORATOR_STATUS,
  },
  forkygram_patch_post: {
    method: 'PATCH',
    url: getAPIBaseUrl() + API_POST_EDIT_POSTS,
  },
  forkygram_post_report_comments: {
    method: 'POST',
    url: getAPIBaseUrl() + API_REPORT_COMMENTS,
  },
  forkygram_post_block_account: {
    method: 'POST',
    url: getAPIBaseUrl() + API_REPORT_BLOCK_ACCOUNT,
  },
  forkygram_post_comment: {
    method: 'POST',
    url: getAPIBaseUrl() + API_POST_COMMENT,
  },
  forkygram_post_like: {
    method: 'POST',
    url: getAPIBaseUrl() + API_POST_LIKE,
  },
  forkygram_search_message: {
    method: 'GET',
    url: getAPIBaseUrl() + API_MEMBER_SEARCH_MESSAGE,
  },
  forkygram_post_like_comment: {
    method: 'POST',
    url: getAPIBaseUrl() + API_POST_LIKE_COMMENT,
  },
  forkygram_dm_post_message: {
    url: getAPIBaseUrl() + API_FORKYGRAM_MESSAGE,
    method: 'POST',
  },
  forkygram_post_pin_posts: {
    method: 'POST',
    url: getAPIBaseUrl() + API_POST_PIN_POSTS,
  },
  forkygram_post_posts: {
    method: 'POST',
    url: getAPIBaseUrl() + API_POST_POSTS,
  },
  forkygram_post_posts_link: {
    method: 'POST',
    url: getAPIBaseUrl() + API_POST_POSTS_LINK,
  },
  forkygram_post_reply: {
    method: 'POST',
    url: getAPIBaseUrl() + API_POST_REPLY,
  },
  forkygram_post_report_posts: {
    method: 'POST',
    url: getAPIBaseUrl() + API_REPORT_POSTS,
  },
  forkygram_post_report_yuser: {
    method: 'POST',
    url: getAPIBaseUrl() + API_REPORT_YUSER,
  },
  forkygram_post_share: {
    method: 'POST',
    url: getAPIBaseUrl() + API_POST_POSTS_SHARE,
  },
  forkygram_dm_post_room: {
    url: getAPIBaseUrl() + API_FORKYGRAM_MESSAGE_ROOM,
    method: 'POST',
  },
  forkygram_musics: {
    method: 'GET',
    url: getAPIBaseUrl() + API_MUSICS,
  },
  forkygram_dm_delete_room: {
    url: getAPIBaseUrl() + API_FORKYGRAM_MESSAGE_ROOM,
    method: 'DELETE',
  },
  forkygram_get_admin_status: {
    url: getAPIBaseUrl() + API_FORKYGRAM_ADMIN_STATUS,
    method: 'GET',
  },
  forkygram_get_ugc: {
    url: getAPIBaseUrl() + API_POST_UGC,
    method: 'GET',
  },
  forkygram_get_following_timeline: {
    url: getAPIBaseUrl() + API_POST_FOLLOWING,
    method: 'GET',
  },
}
