import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  FirebaseMessageRoomContentData,
  FirebaseMessageRoomItem,
  FirebaseMessageRoomType,
  MessageSendMessageResponse,
} from 'types'
import {useCheckLastDelete} from 'utils'
import GiftShopMessageRoomContent from './GiftShopMessageRoomContent'
import GiftShopMessageRoomInput from './GiftShopMessageRoomInput'
import GiftShopMessageRoomInputSystem from './GiftShopMessageRoomInputSystem'

const StyledContentContainer = styled.div`
  ${({theme}) => ({backgroundColor: theme.white_3})}
  display: flex;
  flex: 1;
  flex-direction: column;
`
const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
export interface GiftShopMessageRoomProps {
  participantId: string
  roomId: string
  roomType: FirebaseMessageRoomType
  content?: FirebaseMessageRoomContentData
  onSendMessage?(
    message: string,
    roomId: string,
    memberId: string,
    contentId?: string,
    invoiceId?: string,
  ): Promise<MessageSendMessageResponse | null>
  isContentClickable?: boolean
}
export default function GiftShopMessageRoom({
  participantId,
  roomId,
  roomType,
  content,
  isContentClickable,
  onSendMessage,
}: GiftShopMessageRoomProps) {
  const stateTempMessages = useState<
    ReadonlyArray<FirebaseMessageRoomItem<'chat'>>
  >([])
  const {initialize, roomLastDelete} = useCheckLastDelete(roomId)

  const handleRenderContent = useMemo(
    () =>
      initialize && (
        <StyledContentContainer>
          <GiftShopMessageRoomContent
            roomId={roomId}
            roomType={roomType}
            stateTempMessages={stateTempMessages}
            roomLastDelete={roomLastDelete}
          />
        </StyledContentContainer>
      ),
    [initialize, roomId, roomType, stateTempMessages, roomLastDelete],
  )

  const handleRenderInput = useMemo(
    () =>
      onSendMessage ? (
        <GiftShopMessageRoomInput
          participantId={participantId}
          roomId={roomId}
          content={content}
          isContentClickable={isContentClickable}
          stateTempMessages={stateTempMessages}
          onSendMessage={onSendMessage}
        />
      ) : (
        <GiftShopMessageRoomInputSystem />
      ),
    [
      content,
      isContentClickable,
      onSendMessage,
      participantId,
      roomId,
      stateTempMessages,
    ],
  )

  return (
    <StyledContainer>
      {handleRenderContent}
      {handleRenderInput}
    </StyledContainer>
  )
}
