import React, {useCallback, useRef, useState} from 'react'
import Lottie from 'lottie-react'
import styled from 'styled-components'
import {LIST_LOAD_LIMIT} from 'consts'
import {translate} from 'i18n'
import {requestData} from 'services'
import {ForkygramPostData} from 'types'
import {getCollaboratorMember, useHistory} from 'utils'
import {useWindowMode} from 'windows'
import {ListLazy} from 'common/components'
import convertUnit from 'lib/unit'
import animationData from 'assets/lottie/activity_indicator.json'
import {ForkygramListGridItem} from '../Item'
import ForkygramTemplateListGridContent from './ForkygramTemplateListGridContent'
import {ForkygramPostListGridContentProps} from './ForkygramTemplateListGridContentProps'

const StyledInitializeContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin: auto;
`

const StyledLottie = styled(Lottie)`
  height: ${convertUnit(100)};
  width: ${convertUnit(100)};
`

export default function ForkygramPostListGridContent({
  self,
  memberId,
  renderEmpty,
}: ForkygramPostListGridContentProps) {
  const mode = useWindowMode()
  const history = useHistory()
  const lastPage = useRef(1)
  const column = mode === 'website' ? 5 : 4
  const stateData = useState<ReadonlyArray<ForkygramPostData>>([])
  const data = stateData[0]

  const handleRenderItem = useCallback(
    (item: ForkygramPostData) => (
      <ForkygramListGridItem
        column={column}
        data={{
          contentType: item.content_type,
          thumbnail: item.thumbnail,
          url: item.url,
        }}
        onClickItem={() => {
          const {collaboratorOwner} = getCollaboratorMember(
            item.collaborator_members,
          )
          const index = data.findIndex((post) => post.id === item.id)
          lastPage.current = Math.floor(index / LIST_LOAD_LIMIT) + 1
          history.push(
            'giftshop_profile_post_detail',
            {
              selectedItemId: item.id,
              self,
              memberId,
              initialPage: lastPage.current,
            },
            collaboratorOwner.username,
          )
        }}
      />
    ),
    [column, data, history, memberId, self],
  )

  const handleLoadData = useCallback(async (page: number, limit: number) => {
    const response = await requestData('forkygram_get_self_posts', {
      useDefaultMessage: true,
      actionType: 'fetch',
      params: {page, limit},
    })

    return typeof response !== 'string' && response.status === 200
      ? response.data.result
      : []
  }, [])

  const handleLoadOtherData = useCallback(
    async (page: number, limit: number) => {
      if (!self && memberId) {
        const response = await requestData('forkygram_get_other_posts', {
          useDefaultMessage: true,
          actionType: 'fetch',
          params: {page, limit, member_id: memberId},
        })

        return typeof response !== 'string' && response.status === 200
          ? response.data.result
          : []
      }
      return []
    },
    [memberId, self],
  )

  return (
    <ForkygramTemplateListGridContent
      header
      headerIcon="image"
      headerTitle={translate('forkygram:post')}
      backgroundColor="white_1"
      color="black">
      {self || (!self && memberId) ? (
        <ListLazy
          renderItem={handleRenderItem}
          loadData={self ? handleLoadData : handleLoadOtherData}
          keyExtractor={(item: ForkygramPostData) => item.id}
          numColumns={column}
          scrollbar={false}
          search={memberId}
          listEmptyElement={renderEmpty}
          stateData={stateData}
        />
      ) : (
        <StyledInitializeContainer>
          <StyledLottie animationData={animationData} />
        </StyledInitializeContainer>
      )}
    </ForkygramTemplateListGridContent>
  )
}
