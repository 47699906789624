import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {AUTH_LOGIN_MAX_ATTEMPT} from 'consts'
import {translate} from 'i18n'
import {requestData} from 'services'
import {Button, Input, Modal, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {DeleteAccountModalProps} from './DeleteAccountModalProps'

const StyledButtonsContainer = styled.div`
  width: fill-available;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${convertUnit(20)};
  gap: ${convertUnit(12)};
`

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  border-radius: ${convertUnit(16)};
  text-align: center;
`

const StyledTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(8)};
`

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${convertUnit(20)};
`

const StyledError = styled(Paragraph)`
  margin-top: ${convertUnit(25)};
`

const StyledButton = styled(Button)`
  width: fill-available;
`

export default function DeleteAccountModal({
  stateModal,
  statePassword,
  onCancel,
  stateStep,
}: DeleteAccountModalProps) {
  const [password, setPassword] = statePassword
  const setStep = stateStep[1]
  const user = useSelector('user')!
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = stateModal
  const [errorMessage, setErrorMessage] = useState('')
  const {has_password} = useSelector('user') || {}

  const handleNextStep = useCallback(() => {
    if (!has_password) {
      setStep(2)
      return
    }
    if (password) {
      setLoading(true)
      requestData('auth_oauth_login', {
        data: {identifier: user.username, password},
        onRequestReceived: () => {
          setLoading(false)
        },
        onRequestSuccess: ({status, data: {detail}}) => {
          if (status === 200 || status === 202) {
            setStep(2)
            setPassword('')
            setErrorMessage('')
          } else if (status === 400 && detail) {
            setErrorMessage(
              translate('auth:validationLoginAttempt', {
                attempt: AUTH_LOGIN_MAX_ATTEMPT - Number(detail.attempt),
              }),
            )
          } else if (status === 423) {
            setModal(false)
            setPassword('')
            setErrorMessage('')
          }
        },
      })
    }
  }, [has_password, password, setStep, user.username, setPassword, setModal])

  const handleClickEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && password !== '') {
        handleNextStep()
      }
    },
    [handleNextStep, password],
  )

  const handleRenderError = useMemo(
    () =>
      errorMessage && (
        <StyledError color="danger_5" fontWeight="medium">
          {errorMessage}
        </StyledError>
      ),
    [errorMessage],
  )

  const handleRenderContent = useMemo(
    () => (
      <>
        <StyledTitle fontSize="l" fontWeight="bold">
          {translate(
            has_password
              ? 'giftshop:passwordConfirmation'
              : 'giftShop:deleteAccountConfirmation',
          )}
        </StyledTitle>
        {has_password ? (
          <>
            <Input
              name="password"
              type="password"
              value={password}
              label={translate('global:password')}
              placeholder={translate('auth:loginInputPasswordPlaceholder')}
              rightIcon="visibility"
              onChangeText={setPassword}
              onKeyPress={handleClickEnter}
            />
            {handleRenderError}
          </>
        ) : (
          <StyledParagraph fontSize="m">
            {translate('giftShop:deleteAccountConfirmationDesc')}
          </StyledParagraph>
        )}
        <StyledButtonsContainer>
          <StyledButton
            label={translate('global:cancel')}
            color={has_password ? 'primary_5' : 'white_1'}
            backgroundColor={has_password ? 'white_3' : 'primary_5'}
            onClick={onCancel}
          />
          <StyledButton
            isLoading={loading}
            color={has_password ? 'white_1' : 'primary_5'}
            backgroundColor={has_password ? 'primary_5' : 'white_3'}
            label={translate('global:continue')}
            onClick={handleNextStep}
            disabled={has_password ? password === '' : false}
          />
        </StyledButtonsContainer>
      </>
    ),
    [
      handleClickEnter,
      handleNextStep,
      handleRenderError,
      has_password,
      loading,
      onCancel,
      password,
      setPassword,
    ],
  )

  return <StyledModal visible={modal}>{handleRenderContent}</StyledModal>
}
