import React, {CSSProperties, useMemo} from 'react'
import styled from 'styled-components'
import {GIFT_SHOP_TRANSACTION_STATUS_COLORS} from 'consts'
import {getCurrencyValue, parseDate, useHistory} from 'utils'
import {useTranslation} from 'i18n'
import {IconFirstBuy, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateTransactionHistoryContentItemprops} from './TemplateTransactionHistoryContentItemProps'

const StyledCardContainer = styled.div`
  background-color: ${({theme}) => theme.white_1};
  box-sizing: border-box;
  border-radius: ${convertUnit(8)};
  padding: ${convertUnit(20)};
  display: flex;
  align-items: stretch;
  flex-direction: row;
  min-height: ${convertUnit(107)};
  border-bottom: ${convertUnit(1)} solid ${({theme}) => theme.white_4};
  cursor: pointer;
`

const StyledFlexItem = styled.div<CSSProperties>`
  display: flex;
  flex-direction: column;
  align-items: ${({alignItems}) => alignItems};
  justify-content: ${({justifyContent}) => justifyContent};
  height: 100%;
  padding: 0 ${convertUnit(25)};
`

const StyledLeftContainer = styled(StyledFlexItem)`
  flex: 1;
  padding: 0;
  margin-bottom: ${convertUnit(5)};
`

const StyledInfo = styled.div<CSSProperties>`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${({justifyContent}) => justifyContent};
`

const StyledIconContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const StyledTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(8)};
  align-items: center;
`

export default function TemplateTransactionHistoryContentItem({
  data: {
    transaction_type: type,
    status,
    amount,
    transaction_date: date,
    transaction_partner,
    invoice_id: invoiceId,
    is_free_first_buy,
  },
  roletype,
}: TemplateTransactionHistoryContentItemprops) {
  const {translate} = useTranslation()
  const history = useHistory()
  const transactionPartner = transaction_partner

  const handleTransactionStatus = useMemo(
    () => (
      <StyledIconContainer>
        <Paragraph
          fontSize="s"
          fontWeight="medium"
          color={GIFT_SHOP_TRANSACTION_STATUS_COLORS[status]}>
          {type === 'Withdraw' && status === 'PROCESSED'
            ? translate('global:processed')
            : translate('giftShop:transactionStatus', {
                context: status,
              })}
        </Paragraph>
      </StyledIconContainer>
    ),
    [status, translate, type],
  )

  const handleTransactionType = useMemo(
    () => (
      <StyledTypeContainer>
        <Paragraph fontWeight="bold" fontSize="m">
          {translate('giftShop:transactionType', {
            context: type.toUpperCase(),
          })}
        </Paragraph>
        {is_free_first_buy ? (
          <IconFirstBuy text={translate('giftShop:firstPromo')} />
        ) : (
          <></>
        )}
      </StyledTypeContainer>
    ),
    [is_free_first_buy, translate, type],
  )

  const handleTransactionPartner = useMemo(
    () => (
      <StyledInfo justifyContent="end">
        {type !== 'Incentive' ? (
          <Paragraph fontSize="xs" fontWeight="bold" color="gray_5">
            {transactionPartner.length > 15
              ? `${transactionPartner.slice(0, 15)}...`
              : transactionPartner}
          </Paragraph>
        ) : (
          <Paragraph fontSize="xs" fontWeight="bold" color="gray_5">
            {translate('global:fotoyu')}
          </Paragraph>
        )}
      </StyledInfo>
    ),
    [transactionPartner, translate, type],
  )

  const handleAmount = useMemo(
    () => (
      <StyledInfo justifyContent="start">
        <Paragraph fontSize="m">{getCurrencyValue(amount)}</Paragraph>
      </StyledInfo>
    ),
    [amount],
  )

  const handleDate = useMemo(
    () => (
      <StyledInfo justifyContent="end">
        <Paragraph fontSize="s" color="gray_4">
          {parseDate(date, 'MMM D, YYYY')}
        </Paragraph>
      </StyledInfo>
    ),
    [date],
  )

  return (
    <StyledCardContainer
      onClick={() => {
        history.push('giftshop_transaction_detail', {
          invoice_id: invoiceId,
          is_host: roletype === 'host',
        })
      }}>
      <StyledLeftContainer alignItems="flex-start" justifyContent="center">
        {handleTransactionStatus}
        {handleTransactionType}
        {handleTransactionPartner}
      </StyledLeftContainer>
      <StyledFlexItem alignItems="flex-end" justifyContent="center">
        {handleDate}
        {handleAmount}
      </StyledFlexItem>
    </StyledCardContainer>
  )
}
