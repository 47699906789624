import React from 'react'
import convertUnit from 'lib/unit'
import {IconCustomProps} from '../Icon'

export default function IconFotoyu({
  width = convertUnit(24),
  height = convertUnit(24),
  fill = 'none',
  ...props
}: IconCustomProps) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 24 24">
      <path
        fill="#B737E2"
        stroke="#A6F655"
        strokeWidth="0.5"
        d="m4.509 14.764-.065.241.241.065.17.045a2.45 2.45 0 0 0 2.998-1.73l2.13-7.954 6.855 1.837.242.064.065-.241.67-2.504.065-.241-.241-.065-7.266-1.947a2.45 2.45 0 0 0-2.998 1.73zm14.934-5.528.064-.241-.241-.065-.37-.099a2.45 2.45 0 0 0-2.998 1.73l-2.118 7.907-6.818-1.827-.241-.065-.065.241-.684 2.552-.065.242.242.064 7.429 1.991a2.45 2.45 0 0 0 2.998-1.73z"
      />
    </svg>
  )
}
