import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {FORKYGRAM_CAPTION_LINE_HEIGHT} from 'consts'
import {useTranslation} from 'i18n'
import {ForkygramCollaboratorMemberData} from 'types'
import {
  getBorder,
  getFontFamily,
  getFontSize,
  getTextShadow,
  useDidUpdate,
} from 'utils'
import {
  Icon,
  IconFotoyu,
  IconVerifiedBadge,
  Image,
  Paragraph,
} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {ForkygramTemplatePostProfileProps} from './ForkygramTemplatePostProfileProps'

interface StyledProfileProps {
  shouldUpdateGap: boolean
}

interface StyledAvatarContainerProps {
  isLive: boolean
}

interface StyledIconProps {
  isCollab?: boolean
}

const StyledProfileContainer = styled.div<StyledProfileProps>`
  ${({shouldUpdateGap}) => ({gap: convertUnit(shouldUpdateGap ? 16 : 8)})};
  display: flex;
  flex: 1;
  align-items: center;
`

const StyledAvatarContainer = styled.div<StyledAvatarContainerProps>`
  ${({theme, isLive}) => ({
    border: getBorder(1, 'solid', isLive ? theme.fotoyu_logo_1 : theme.white_1),
  })}
  position: relative;
  margin-bottom: ${convertUnit(2)};
  border-radius: ${convertUnit(4)};
  cursor: pointer;
  height: ${convertUnit(60)};
  aspect-ratio: 0.75;
  background-color: ${({theme}) => theme.black};
`

const StyledLiveContainer = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${convertUnit(1)};
  padding: 0 ${convertUnit(2)};
  background-color: ${({theme}) => theme.fotoyu_logo_1};
`

const StyledUsernameContainer = styled.div`
  flex: 1;
  align-self: flex-end;
  text-shadow: ${({theme}) => getTextShadow(theme, {opacity: 0.5})};
`

const StyledUsernameWrapper = styled.div`
  position: relative;
  align-items: center;
`

const StyledFollowButton = styled.div`
  position: absolute;
  width: fit-content;
  top: ${convertUnit(4)};
  left: ${convertUnit(36)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(4)};
  padding: ${convertUnit(2)};
  padding-top: 0;
  cursor: pointer;
  z-index: 1;
`

const StyledCollabMember = styled.span`
  display: flex;
  color: ${({theme}) => theme.white_1};
  font-size: ${getFontSize('s')};
  font-family: ${getFontFamily('regular')};
  line-height: ${convertUnit(17)};
  text-shadow: ${({theme}) => getTextShadow(theme, {opacity: 0.5})};
`

const StyledCollabMemberUsername = styled.strong`
  cursor: pointer;
  font-family: ${getFontFamily('bold')};
  padding-left: ${convertUnit(2.5)};
  flex: 1;
`

const StyledCollabWhitespace = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  cursor: pointer;
  flex: 1;
  height: ${convertUnit(15)};
  padding: ${convertUnit(2)} 0;
`

const StyledAvatar = styled(Image)`
  position: relative;
  height: ${convertUnit(60)};
  aspect-ratio: 0.75;
  border-radius: ${convertUnit(3)};
  object-fit: contain;
`

const StyledParagraph = styled(Paragraph)`
  position: relative;
  display: inline;
  overflow-wrap: anywhere;
  word-break: break-word;
  white-space: pre-wrap;
  z-index: 1;
  margin-right: ${convertUnit(4)};
  text-shadow: ${({theme}) => getTextShadow(theme, {opacity: 0.5})};
`

const StyledIconContainer = styled.div`
  display: inline;
`

const StyledIconVerified = styled(IconVerifiedBadge)<StyledIconProps>`
  margin-bottom: ${convertUnit(-4)};
  margin-left: ${({isCollab}) => (isCollab ? convertUnit(4) : 0)};
`

const StyledIconFotoyu = styled(IconFotoyu)<StyledIconProps>`
  margin-bottom: ${convertUnit(-8)};
  margin-left: ${({isCollab}) => (isCollab ? convertUnit(4) : 0)};
`

export default function ForkygramTemplatePostProfile({
  stateFollowButton,
  collabOwnerData,
  collabMemberData,
  memberId,
  onClickAvatar,
  onClickCollabMember,
  onFollowed,
}: ForkygramTemplatePostProfileProps) {
  const {translate} = useTranslation()
  const {id} = useSelector('user') || {}
  const usernameRef = useRef<HTMLDivElement>(null)
  const followButton = stateFollowButton[0]
  const [shouldUpdateGap, setShouldUpdateGap] = useState(false)

  const isSelf = useMemo(() => memberId === id, [id, memberId])

  const isCanFollow = useMemo(
    () => !isSelf && !collabOwnerData.is_followed && followButton,
    [collabOwnerData.is_followed, followButton, isSelf],
  )

  const handleRenderBadge = useCallback(
    (data: ForkygramCollaboratorMemberData, isCollab?: boolean) => (
      <StyledIconContainer>
        {data.is_official ? (
          <StyledIconFotoyu isCollab={isCollab} width={isCollab ? 12 : 16} />
        ) : data.creator_status === 'verified' ? (
          <StyledIconVerified isCollab={isCollab} width={isCollab ? 12 : 16} />
        ) : (
          <></>
        )}
      </StyledIconContainer>
    ),
    [],
  )

  const handleRenderCollabMember = useMemo(
    () =>
      collabMemberData && (
        <StyledCollabMember>
          {translate('global:and')}
          <StyledCollabMemberUsername onClick={onClickCollabMember}>
            @{collabMemberData.username}
            {handleRenderBadge(collabMemberData, true)}
          </StyledCollabMemberUsername>
        </StyledCollabMember>
      ),
    [collabMemberData, handleRenderBadge, onClickCollabMember, translate],
  )

  useDidUpdate(() => {
    if (isCanFollow && usernameRef.current?.offsetHeight) {
      setShouldUpdateGap(
        usernameRef.current.offsetHeight > FORKYGRAM_CAPTION_LINE_HEIGHT,
      )
    }
  }, [usernameRef.current?.clientHeight])

  return (
    <StyledProfileContainer shouldUpdateGap={shouldUpdateGap}>
      <StyledAvatarContainer isLive={collabOwnerData.is_live}>
        {isCanFollow ? (
          <StyledFollowButton onClick={() => onFollowed(memberId)}>
            <Icon type="plus" size={12} color="primary_5" />
          </StyledFollowButton>
        ) : (
          <></>
        )}
        <StyledAvatar
          alt={collabOwnerData.username}
          src={collabOwnerData.photo}
          onClick={onClickAvatar}
        />
        {collabOwnerData.is_live && (
          <StyledLiveContainer>
            <Icon type="fg" size={convertUnit(7)} color="white_1" />
            <Paragraph fontSize="xxs" fontWeight="bold" color="white_1">
              {translate('global:live').toUpperCase()}
            </Paragraph>
          </StyledLiveContainer>
        )}
      </StyledAvatarContainer>
      <StyledUsernameContainer ref={usernameRef}>
        <StyledUsernameWrapper>
          <StyledParagraph
            className="pressable"
            fontWeight="bold"
            color="white_1"
            fontSize="m"
            onClick={onClickAvatar}>
            @{collabOwnerData.username}
          </StyledParagraph>
          {handleRenderBadge(collabOwnerData)}
          {collabMemberData && (
            <StyledCollabWhitespace onClick={onClickCollabMember} />
          )}
        </StyledUsernameWrapper>
        {handleRenderCollabMember}
      </StyledUsernameContainer>
    </StyledProfileContainer>
  )
}
