import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {IMAGE_ASSET, OTP_COOLDOWN, REGEX_LEADING_ZERO} from 'consts'
import {useTheme} from 'themes'
import {AuthPhoneResendMethod, parseShape} from 'types'
import {
  formatCooldownTime,
  formatPhoneNumber,
  getFontFamily,
  showSnackbar,
  useHistory,
  useLocation,
  useTimer,
} from 'utils'
import {requestData} from 'services'
import {Image, Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {TemplateAuthAccessResendOtpModal} from '../../../template'

const StyledHeaderDescription = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
  text-align: center;
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`
const StyledImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${convertUnit(20)} 0;
`

const StyledIllustration = styled(Image)`
  max-width: ${convertUnit(325)};
  width: 100%;
`
const StyledResendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function AuthRecoverAccountLinkPhoneVerif() {
  const {translate} = useTranslation()
  const history = useHistory()
  const whatsappConfirmImage = IMAGE_ASSET('giftshop', 'whatsapp-confirm.png')
  const phoneConfirmImage = IMAGE_ASSET('giftshop', 'mobile-confirm.png')
  const theme = useTheme()
  const {update} = useDispatch()
  const {
    phone_code,
    phone_number,
    method,
    member_id,
    recovery_code,
    identifier,
  } = useLocation('auth_recover_account_link_verif').state
  const [isVisible, setIsVisible] = useState(false)
  const stateSelectedMethod = useState<AuthPhoneResendMethod>(
    (method as AuthPhoneResendMethod) === 'whatsapp' ? 'phone' : 'whatsapp',
  )
  const [selectedMethod, setSelectedMethod] = stateSelectedMethod
  const [image, setImage] = useState<AuthPhoneResendMethod>(
    method as AuthPhoneResendMethod,
  )
  const {phoneOTPTime} = useSelector('lastUserState')
  const today = new Date().getTime() / 1000
  const {countdown, reset} = useTimer({
    duration:
      phoneOTPTime && today - phoneOTPTime / 1000 <= OTP_COOLDOWN
        ? OTP_COOLDOWN - (today - phoneOTPTime / 1000)
        : OTP_COOLDOWN,
    countOnStart: !!(
      phoneOTPTime && today - phoneOTPTime / 1000 <= OTP_COOLDOWN
    ),
  })

  const countDownMinute = Math.floor(countdown / 60)
  const countDownSecond = countdown - 60 * countDownMinute

  const handleResend = useCallback(() => {
    requestData('auth_recover_account_send_link', {
      data: {member_id, recovery_code},
      onRequestSuccess: ({status, data: {detail}}) => {
        if (status === 200) {
          setSelectedMethod(
            selectedMethod === 'whatsapp' ? 'phone' : 'whatsapp',
          )
          reset(OTP_COOLDOWN)
          update('lastUserState', {phoneOTPTime: new Date().getTime()})
          history.replace('auth_recover_account_link_verif', {
            phone_code,
            phone_number,
            identifier,
            method: 'whatsapp',
          })
        } else if (status === 410 && detail) {
          const errorDetail = detail
          const {hour, minute, second} = formatCooldownTime(
            errorDetail.resend_in,
          )
          showSnackbar(
            translate(
              errorDetail.is_limit
                ? 'auth:registerEasySignupResendLinkLimitSnackbar'
                : 'auth:registerEasySignupResendLinkCooldownSnackbar',
              {
                hour,
                minute,
                second,
              },
            )
              .replace(
                hour === ''
                  ? /<hourStamp>.*?<\/hourStamp>/
                  : /<hourStamp>|<\/hourStamp>/g,
                '',
              )
              .replace(
                minute === ''
                  ? /<minuteStamp>.*?<\/minuteStamp>/
                  : /<minuteStamp>|<\/minuteStamp>/g,
                '',
              )
              .replace(/<secondStamp>|<\/secondStamp>/g, ''),
            [
              {
                pattern: /<timestamp>.*?<\/timestamp>/,
                style: {
                  fontFamily: 'Roboto-Bold',
                },
                renderText: (text) =>
                  text.replace(/<timestamp>|<\/timestamp>/g, ''),
              },
            ],
          )
        }
      },
    })
  }, [
    history,
    identifier,
    member_id,
    phone_code,
    phone_number,
    recovery_code,
    reset,
    selectedMethod,
    setSelectedMethod,
    translate,
    update,
  ])

  const handleSelectResendMethod = useCallback(
    (selected: AuthPhoneResendMethod) => {
      setImage(selected)
      setIsVisible(false)
      handleResend
    },
    [handleResend],
  )

  const handleRenderTitle = useMemo(
    () => (
      <>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {phone_number &&
            `+${phone_code} ${
              phone_number
                ? formatPhoneNumber(
                    phone_number.replace(REGEX_LEADING_ZERO, ''),
                  )
                : ''
            }`}
        </StyledParagraph>
        <StyledHeaderDescription fontSize="m" color="gray_5">
          {translate('auth:recoverAccountLinkSent', {context: image})}
        </StyledHeaderDescription>
      </>
    ),
    [image, phone_code, phone_number, translate],
  )

  const parseText: parseShape[] = useMemo(
    () => [
      {
        pattern: /<resend>.*?<\/resend>/,
        style: {
          color: theme.primary_5,
          cursor: 'pointer',
          fontFamily: getFontFamily('bold'),
        },
        renderText: (str) => str.replace(/<resend>|<\/resend>/g, ''),
        onClick: () => {
          // TODO: handle choose resend method
          // setIsVisible(true)
          handleResend()
        },
      },
      {
        pattern: /<cooldown>.*?<\/cooldown>/,
        style: {
          color: theme.gray_3,
          fontFamily: getFontFamily('bold'),
        },
        renderText: (str) => str.replace(/<cooldown>|<\/cooldown>/g, ''),
      },
    ],
    [handleResend, theme.gray_3, theme.primary_5],
  )

  const handleRenderResend = useMemo(
    () => (
      <StyledResendContainer>
        <StyledParagraph color="gray_5">
          {translate('auth:verificationWhatsappDidNotGet')}
          <ParsedText parse={parseText}>
            {countdown === 0
              ? ` <resend>${translate(
                  'auth:verificationWhatsappResend',
                )}</resend>`
              : ` <cooldown>${translate(
                  'auth:verificationWhatsappResendCountdown',
                  {
                    countdown:
                      countdown > 60
                        ? `${countDownMinute}m${countDownSecond}`
                        : `${countDownSecond}`,
                  },
                )}</cooldown>`}
          </ParsedText>
        </StyledParagraph>
      </StyledResendContainer>
    ),
    [countDownMinute, countDownSecond, countdown, parseText, translate],
  )

  const handleRenderMidSection = useMemo(
    () => (
      <>
        <StyledImgContainer>
          <StyledIllustration
            src={
              image === 'whatsapp' ? whatsappConfirmImage : phoneConfirmImage
            }
            alt="Illustration"
          />
        </StyledImgContainer>
        <StyledParagraph color="gray_3">
          <ParsedText
            parse={[
              {
                pattern: /<time>.*?<\/time>/,
                style: {
                  color: theme.gray_4,
                  fontFamily: getFontFamily('bold'),
                },
                renderText: (text) => text.replace(/<time>|<\/time>/g, ''),
              },
            ]}>
            {translate('auth:verificationEmailCaution').concat(
              ` <time>${translate('auth:verificationEmailCautionTime')}</time>`,
            )}
          </ParsedText>
        </StyledParagraph>
        {handleRenderResend}
      </>
    ),
    [
      handleRenderResend,
      image,
      phoneConfirmImage,
      theme.gray_4,
      translate,
      whatsappConfirmImage,
    ],
  )
  return (
    <>
      <TemplateAuthAccessResendOtpModal
        isVisible={isVisible}
        stateSelectedMethod={stateSelectedMethod}
        onConfirm={handleSelectResendMethod}
        onCancel={() => setIsVisible(false)}
        userInfo={{phoneCode: phone_code!, phoneNumber: phone_number!}}
      />
      {handleRenderTitle}
      {handleRenderMidSection}
    </>
  )
}
