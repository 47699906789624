import React, {useCallback, useEffect, useState} from 'react'
import {requestData} from 'services'
import {ForkygramPostData} from 'types'
import {getCollaboratorMember, useHistory} from 'utils'
import {useWindowMode} from 'windows'
import {Slider} from 'common/components'
import convertUnit from 'lib/unit'
import {ForkygramListGridItem} from '../Item'
import ForkygramTemplateListGridContent from './ForkygramTemplateListGridContent'
import {ForkygramPinnedPostListGridContentProps} from './ForkygramTemplateListGridContentProps'

export default function ForkygramPinnedPostListGridContent({
  self,
  memberId,
}: ForkygramPinnedPostListGridContentProps) {
  const mode = useWindowMode()
  const history = useHistory()
  const column = mode === 'website' ? 5 : 4
  const [imageList, setImageList] = useState<ForkygramPostData[]>([])

  const handleRenderItem = useCallback(
    (item: ForkygramPostData) => (
      <ForkygramListGridItem
        key={item.id}
        data={{
          contentType: item.content_type,
          url: item.url,
          thumbnail: item.thumbnail,
        }}
        column={column}
        onClickItem={() => {
          const {collaboratorOwner} = getCollaboratorMember(
            item.collaborator_members,
          )
          history.push(
            'giftshop_profile_post_detail',
            {
              selectedItemId: item.id,
              self,
              memberId,
              isPinnedPost: true,
            },
            collaboratorOwner.username,
          )
        }}
      />
    ),
    [column, history, memberId, self],
  )

  const handleLoadData = useCallback(() => {
    self
      ? requestData('forkygram_get_pin_posts', {
          useDefaultMessage: true,
          actionType: 'fetch',
          onRequestSuccess: ({status, data: {result}}) => {
            if (status === 200 && result.length) {
              setImageList(result)
            }
          },
        })
      : memberId &&
        requestData('forkygram_get_pin_posts', {
          useDefaultMessage: true,
          actionType: 'fetch',
          params: {member_id: memberId},
          onRequestSuccess: ({status, data: {result}}) => {
            if (status === 200 && result.length) {
              setImageList(result)
            }
          },
        })
  }, [memberId, self])

  useEffect(() => handleLoadData(), [handleLoadData])

  return imageList.length ? (
    <ForkygramTemplateListGridContent
      backgroundColor="white_1"
      containerHeight="auto">
      <Slider
        listContentContainerStyle={{
          borderRadius: convertUnit(8),
        }}
        renderItem={handleRenderItem}
        data={imageList}
      />
    </ForkygramTemplateListGridContent>
  ) : (
    <></>
  )
}
