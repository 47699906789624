import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {FORKYGRAM_CAPTION_LINE_HEIGHT, IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {AssetImageFotoTree} from 'types'
import {
  formatRelativeDateDiff,
  getTextShadow,
  useDidMount,
  useHistory,
} from 'utils'
import {Image, Paragraph} from 'common/components'
import {useDispatch} from 'lib/redux'
import convertUnit from 'lib/unit'
import {ForkygramTemplatePostCaptionMobileProps} from './ForkygramTemplatePostCaptionMobileProps'

interface StyledParagraphProps {
  collapse: boolean
  hasTag?: boolean
}

const StyledParagraph = styled(Paragraph)`
  overflow-wrap: anywhere;
  word-break: break-word;
  white-space: pre-wrap;
  text-shadow: ${({theme}) => getTextShadow(theme, {opacity: 0.5})};
`

const StyledParagraphContainer = styled.div<StyledParagraphProps>`
  height: fit-content;
  overflow: hidden;
  max-height: ${({collapse}) =>
    collapse ? convertUnit(FORKYGRAM_CAPTION_LINE_HEIGHT) : 'fit-content'};
`

const StyledDateContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledCaptionContainer = styled.div<StyledParagraphProps>`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  z-index: 5;
  ${({hasTag}) => ({marginTop: convertUnit(hasTag ? -4 : 0)})};
`

const StyledFotoTreeTag = styled.div<StyledParagraphProps>`
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  align-items: flex-start;
  gap: ${convertUnit(4)};
  z-index: 1;
`

const StyledFotoTreePinContainer = styled.div`
  width: fit-content;
`

const StyledFotoTreePin = styled(Image)`
  height: ${convertUnit(16)};
`

const StyledInlineParagraph = styled(StyledParagraph)`
  display: inline;
  margin-right: ${convertUnit(4)};
`

export default function ForkygramTemplatePostCaptionMobile({
  data,
}: ForkygramTemplatePostCaptionMobileProps) {
  const history = useHistory()
  const {update} = useDispatch()
  const [height, setHeight] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const {translate} = useTranslation()
  const {tag} = data

  const handleNavigateFotoTreeDetail = useCallback(() => {
    update('lastTreeState', {videoIntro: true})
    history.push(
      'tree_fototree_detail',
      {
        treeId: tag.tree_id,
        treeAlias: tag.alias,
      },
      tag.alias,
    )
  }, [history, tag.alias, tag.tree_id, update])

  const collapse = useMemo(() => height > FORKYGRAM_CAPTION_LINE_HEIGHT, [
    height,
  ])

  const handleRenderFotoTreeTag = useMemo(
    () =>
      tag.name && (
        <StyledFotoTreeTag
          collapse={collapse}
          onClick={handleNavigateFotoTreeDetail}>
          <StyledFotoTreePinContainer>
            <StyledFotoTreePin
              src={IMAGE_ASSET(
                'tree',
                `icons/pinpoint-${
                  tag.icon?.split('/icons/')[1]
                }` as AssetImageFotoTree,
              )}
              alt=""
            />
          </StyledFotoTreePinContainer>
          <StyledInlineParagraph
            fontWeight="medium"
            color="white_1"
            lineHeight={14}>
            {`${tag.name}${tag.location?.name && ` • ${tag.location.name}`}`}
          </StyledInlineParagraph>
        </StyledFotoTreeTag>
      ),
    [
      collapse,
      handleNavigateFotoTreeDetail,
      tag.icon,
      tag.location?.name,
      tag.name,
    ],
  )

  const handleRenderDate = useMemo(
    () => (
      <StyledParagraph color="gray_1" fontSize="xs">
        {formatRelativeDateDiff(data.created_at)}
      </StyledParagraph>
    ),
    [data.created_at],
  )

  const handleRenderCaption = useMemo(
    () =>
      data.caption !== '' ? (
        <StyledCaptionContainer
          hasTag={!!data.tag.name}
          onClick={() =>
            height > FORKYGRAM_CAPTION_LINE_HEIGHT
              ? setHeight(FORKYGRAM_CAPTION_LINE_HEIGHT)
              : ref.current && setHeight(ref.current?.clientHeight)
          }
          collapse={collapse}>
          <StyledParagraphContainer collapse={collapse} ref={ref}>
            <StyledParagraph color="white_1" lineHeight={14}>
              {data.caption.replace(/\\n/g, '\n')}
            </StyledParagraph>
          </StyledParagraphContainer>
          <StyledDateContainer>
            {(height >= FORKYGRAM_CAPTION_LINE_HEIGHT ||
              (ref.current &&
                ref.current?.clientHeight > FORKYGRAM_CAPTION_LINE_HEIGHT)) && (
              <StyledParagraph color="white_1" fontWeight="bold">
                {'...'}
                {collapse ? translate('global:more') : translate('global:less')}
                {` \u2022 `}
              </StyledParagraph>
            )}
            {handleRenderDate}
          </StyledDateContainer>
        </StyledCaptionContainer>
      ) : (
        handleRenderDate
      ),
    [
      collapse,
      data.caption,
      data.tag.name,
      handleRenderDate,
      height,
      translate,
    ],
  )

  useDidMount(() => {
    ref.current && setHeight(ref.current.clientHeight)
  })

  return (
    <>
      {handleRenderFotoTreeTag}
      {handleRenderCaption}
    </>
  )
}
