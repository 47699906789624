import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {Icon, Image, Paragraph, RadioItem, Tooltip} from 'common/components'
import convertUnit from 'lib/unit'
import {getBorder, getThemeColor, useDidMount, useDidUpdate} from 'utils'
import {useTranslation} from 'i18n'
import {
  TREE_THUMBNAIL_ICON_SIZE_RATIO,
  TREE_THUMBNAIL_RETRY_MAX,
  TREE_THUMBNAIL_RETRY_THRESHOLD,
  TREE_THUMBNAIL_RETRY_TIMEOUT,
} from 'consts'
import {useSelector} from 'lib/redux'
import {ThemeColor} from 'themes'
import {LayoutDimension} from 'types'
import {GiftShopTemplateContentVideoBadge} from '../../../../gift-shop'
import {FototreeDetailContentItemProps} from './FototreeContentItemProps'

interface StyledBadgeContainerProps {
  color: ThemeColor
}

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.gray_9};
  border: ${({theme}) => getBorder(1.67, 'solid', theme.white_1)};
  box-sizing: border-box;
`

const StyledContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  padding: ${convertUnit(3.3)};
  box-sizing: border-box;
`

const StyledRadioItem = styled(RadioItem)`
  position: absolute;
  top: ${convertUnit(8)};
  left: ${convertUnit(8)};
  z-index: 5;
`

const StyledItemContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  height: 100%;
`

const StyledBadgeContainer = styled.div<StyledBadgeContainerProps>`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: ${convertUnit(4)};
  left: ${convertUnit(4)};
  background-color: ${({theme, color}) => getThemeColor(theme, color, 0.6)};
  border-radius: ${convertUnit(20)};
  padding: 0 ${convertUnit(2)};
`

const StyledImageBroken = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
  border: ${({theme}) => getBorder(1.67, 'solid', theme.white_1)};
  background-color: ${({theme}) => theme.gray_1};
`

export default function FototreeDetailContentItem({
  item,
  isSelected,
  onClickItem,
  selectMode,
}: FototreeDetailContentItemProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const {translate} = useTranslation()
  const {id} = useSelector('user') || {}
  const [layout, setLayout] = useState<LayoutDimension>()
  const [thumbnailError, setThumbnailError] = useState(false)
  const [thumbnailErrorRetry, setThumbnailErrorRetry] = useState(0)

  const showCollaboratorBadge = useMemo(
    () =>
      item.collaborator_members.length > 1 &&
      item.collaborator_members.find((val) => val.id === id),
    [id, item.collaborator_members],
  )

  const handleThumbnailError = useCallback(() => {
    const currentTime = new Date().getTime()
    const createdTime = new Date(item.created_at).getTime()
    const timeDiff = currentTime - createdTime < TREE_THUMBNAIL_RETRY_THRESHOLD

    if (timeDiff && thumbnailErrorRetry < TREE_THUMBNAIL_RETRY_MAX) {
      setThumbnailErrorRetry((prev) => prev + 1)
      setThumbnailError(false)
    }
  }, [item.created_at, thumbnailErrorRetry])

  const handleRenderSelectToggle = useMemo(
    () =>
      selectMode ? (
        <StyledRadioItem
          size={20}
          isSelected={isSelected}
          onClick={() => {
            onClickItem(item, isSelected)
          }}
        />
      ) : undefined,
    [isSelected, item, onClickItem, selectMode],
  )

  const handleRenderVideoThumbnail = useMemo(
    () => (
      <>
        <GiftShopTemplateContentVideoBadge />
        {layout && thumbnailError ? (
          <StyledImageBroken>
            <Paragraph color="gray_4">
              {translate('global:generateThumbnail')}
            </Paragraph>
            <Icon
              type="image"
              color="gray_2"
              size={
                Math.min(layout.height, layout.width) *
                TREE_THUMBNAIL_ICON_SIZE_RATIO
              }
            />
          </StyledImageBroken>
        ) : (
          <StyledImage
            alt="leaf thumbnail"
            key={item.post_id + thumbnailErrorRetry}
            src={item.thumbnail}
            onError={() => setThumbnailError(true)}
          />
        )}
      </>
    ),
    [item, layout, thumbnailError, thumbnailErrorRetry, translate],
  )

  const handleRenderItem = useMemo(
    () => (
      <StyledItemContainer onClick={() => onClickItem(item, isSelected)}>
        {item.content_type === 'photo' ? (
          <StyledImage alt="leaf-picture" key={item.post_id} src={item.url} />
        ) : (
          handleRenderVideoThumbnail
        )}
        {item.is_hidden ? (
          <StyledBadgeContainer
            data-tooltip-id="tooltip-invisible"
            color="primary_5">
            <Icon type="invisible" size={convertUnit(12)} color="white_1" />
            <Tooltip
              id="tooltip-invisible"
              content={translate('tree:invisible')}
            />
          </StyledBadgeContainer>
        ) : showCollaboratorBadge ? (
          <StyledBadgeContainer
            data-tooltip-id="tooltip-collab"
            color="success_5">
            <Icon type="collab-leaf" size={convertUnit(12)} color="white_1" />
            <Tooltip
              id="tooltip-collab"
              content={translate('tree:collabPost')}
            />
          </StyledBadgeContainer>
        ) : (
          <></>
        )}
      </StyledItemContainer>
    ),
    [
      handleRenderVideoThumbnail,
      isSelected,
      item,
      onClickItem,
      showCollaboratorBadge,
      translate,
    ],
  )

  useDidMount(() => {
    if (ref.current) {
      const {offsetWidth, offsetHeight} = ref.current
      setLayout({width: offsetWidth, height: offsetHeight})
    }
  })

  useDidUpdate(() => {
    let timeout: ReturnType<typeof setTimeout>

    if (thumbnailError) {
      timeout = setTimeout(() => {
        handleThumbnailError()
      }, TREE_THUMBNAIL_RETRY_TIMEOUT)
    }

    return () => clearTimeout(timeout)
  }, [thumbnailError])

  return (
    <StyledContainer ref={ref}>
      {handleRenderSelectToggle}
      {handleRenderItem}
    </StyledContainer>
  )
}
