import {ForkygramCollaboratorMemberData} from 'types'
import {REDUX_STORE} from 'lib/redux'

function getMemberId(member: ForkygramCollaboratorMemberData) {
  return 'member_id' in member ? member.member_id : member.id
}

function getRandomMember<T extends ForkygramCollaboratorMemberData>(
  members: T[],
): T {
  return members[Math.floor(Math.random() * members.length)]
}

export function getCollaboratorMember<
  T extends ForkygramCollaboratorMemberData
>(
  data: ReadonlyArray<T>,
): {
  collaboratorOwner: T
  collaboratorMember: T | null
  collaboratorSelf: T | null
} {
  const {id} = REDUX_STORE.getState().user || {}

  const collaboratorSelf =
    (data.length > 1 &&
      data.find((x) => !x.is_owner && getMemberId(x) === id)) ||
    null

  const collaboratorMember =
    (data.length > 1 && data.find((x) => !x.is_owner)) || null

  const ownerCandidates = data.filter((x) => x !== collaboratorMember)

  const collaboratorOwner =
    data.length === 1
      ? data[0]
      : data.find((x) => x.is_owner && getMemberId(x) === id) ||
        data.find((x) => x.is_owner) ||
        getRandomMember(ownerCandidates)

  return {
    collaboratorOwner,
    collaboratorMember,
    collaboratorSelf,
  }
}
