import React, {CSSProperties, useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  IMAGE_ASSET,
  SERVICE_CANCELLATION_SEARCH_MEMBER,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {UserInfoBase} from 'types'
import {useDebounce} from 'utils'
import {
  Input,
  Icon,
  List,
  Paragraph,
  Image,
  ActivityIndicator,
  Avatar,
  IconVerifiedBadge,
} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TreeFototreeUploadCollaboratorProps} from './TreeFototreeUploadProps'

const StyledContainer = styled.div`
  position: absolute;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.white_3};
  width: 100%;
  height: 100%;
  padding: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    padding: 0;
  }
`

const StyledInnerContainer = styled.div`
  background-color: ${({theme}) => theme.white_1};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  box-sizing: border-box;
  max-width: ${convertUnit(657)};
  height: ${convertUnit(570)};
  border-radius: ${convertUnit(16)};
  gap: ${convertUnit(20)};
  padding: ${convertUnit(20)};
  margin: 0 auto;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    border-radius: 0;
    gap: ${convertUnit(12)};
    padding: ${convertUnit(12)} ${convertUnit(20)};
    height: 100%;
  }
`

const StyledEmptyContainer = styled.div`
  place-items: center;
  margin: auto;
  padding: ${convertUnit(20)};
`

const StyledEmptyMessage = styled(Paragraph)`
  text-align: center;
`

const StyledEmptyImg = styled(Image)`
  object-fit: contain;
  margin-bottom: ${convertUnit(20)};
  max-width: ${convertUnit(100)};
  max-height: ${convertUnit(130)};
`

const StyledLoadingContainer = styled.div`
  display: flex;
  margin: 0 auto;
  padding: ${convertUnit(10)};
`

const StyledItemContainer = styled.div<CSSProperties>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${convertUnit(12)} 0;
`

const StyledParagraph = styled(Paragraph)`
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: ${convertUnit(12)};
  padding-right: ${convertUnit(4)};
`

export default function TreeFototreeUploadCollaborator({
  onSelected,
}: TreeFototreeUploadCollaboratorProps) {
  const emptyImg = IMAGE_ASSET('giftshop', 'not-found.png')
  const debounce = useDebounce()
  const {translate} = useTranslation()
  const {id} = useSelector('user') || {}
  const [data, setData] = useState<ReadonlyArray<UserInfoBase>>()
  const [loading, setLoading] = useState(false)

  const handleLoadData = useCallback(
    (query: string) => {
      requestData('forkygram_get_member', {
        params: {q: query},
        cancelId: SERVICE_CANCELLATION_SEARCH_MEMBER,
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) {
            setData(result.filter((item) => item.id !== id))
          }
        },
      })
    },
    [id],
  )

  const handleChangeText = useCallback(
    (text: string) => {
      if (!text) {
        setData(undefined)
        return
      }
      if (text.length > 3) {
        setLoading(true)
        debounce(() => handleLoadData(text), 500)
      }
    },
    [debounce, handleLoadData],
  )

  const handleRenderEmpty = useMemo(
    () =>
      loading ? (
        <StyledLoadingContainer>
          <ActivityIndicator iconColor="primary_5" />
        </StyledLoadingContainer>
      ) : data && !data.length ? (
        <StyledEmptyContainer>
          <StyledEmptyImg src={emptyImg} alt={emptyImg} />
          <StyledEmptyMessage fontSize="l" fontWeight="bold">
            {translate('forkygram:searchFriendsNotFound')}
          </StyledEmptyMessage>
          <StyledEmptyMessage color="gray_5">
            {translate('forkygram:searchFriendsNotFoundSubtitle')}
          </StyledEmptyMessage>
        </StyledEmptyContainer>
      ) : (
        <></>
      ),
    [data, emptyImg, loading, translate],
  )

  const handleRenderItem = useCallback(
    (item: UserInfoBase) => (
      <StyledItemContainer onClick={() => onSelected(item)}>
        <div>
          <Avatar alt="Avatar" src={item.photo} />
        </div>
        <StyledParagraph fontSize="m" fontWeight="medium">
          @{item.username}
        </StyledParagraph>
        {item.creator_status === 'verified' && <IconVerifiedBadge />}
      </StyledItemContainer>
    ),
    [onSelected],
  )

  return (
    <StyledContainer>
      <StyledInnerContainer>
        <Input
          placeholder={translate('forkygram:uploadSearchPeoplePlaceholder')}
          rightIcon="delete"
          leftIcon={<Icon type="search" size={16} />}
          onRightIconClick={() => setData(undefined)}
          onChangeText={handleChangeText}
          inputStyle={{paddingLeft: 0, paddingRight: 0}}
        />
        <List
          keyExtractor={(item) => item.id}
          data={data}
          scrollbar={false}
          renderItem={handleRenderItem}
          listEmptyElement={handleRenderEmpty}
        />
      </StyledInnerContainer>
    </StyledContainer>
  )
}
