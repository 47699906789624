import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {IMAGE_ASSET} from 'consts'
import {showSnackbar, useLocation} from 'utils'
import {requestData} from 'services'
import {AuthLoginResponse} from 'types'
import {Button, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch} from 'lib/redux'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
`
const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(10)};
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`
const StyledImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${convertUnit(20)} 0;
`

const StyledIllustration = styled(Image)`
  max-width: ${convertUnit(325)};
  width: 100%;
`
const StyledButton = styled(Button)`
  width: fill-available;
`

export default function AuthRecoverAccountSuccess() {
  const {translate} = useTranslation()
  const {identifier, password, token, method} = useLocation(
    'auth_recover_account_success',
  ).state
  const image = IMAGE_ASSET('giftshop', 'success.png')
  const [loading, setLoading] = useState(false)
  const {update} = useDispatch()

  const handleLogin = useCallback(() => {
    setLoading(true)
    if (token) {
      requestData(
        method === 'apple'
          ? 'auth_easy_signup_login_sso_apple'
          : 'auth_easy_signup_login_sso_google',
        {
          data: {token},
          onRequestReceived: () => {
            setLoading(false)
          },
          onRequestSuccess: ({data, status}) => {
            if (status === 200) {
              const userInfo = data.result as AuthLoginResponse
              update('user', userInfo)
              update('lastUserState', {
                username: undefined,
                photo: undefined,
                refreshToken: undefined,
              })
            }
          },
          onRequestFailed: () => {
            showSnackbar(translate('giftShop:snackbarFailedExecute'))
            setLoading(false)
          },
        },
      )
    } else {
      requestData('auth_login', {
        actionType: 'execute',
        data: {identifier, password},
        onRequestReceived: () => {
          setLoading(false)
        },
        onRequestSuccess: ({status}) => {
          if (status !== 200) {
            showSnackbar(
              translate(
                status === 400 || status === 401 || status === 404
                  ? 'auth:validationLoginMissMatch'
                  : 'global:messageError',
              ),
            )
          }
        },
      })
    }
  }, [identifier, method, password, token, translate, update])

  const handleRenderContent = useMemo(
    () => (
      <StyledContainer>
        <StyledTitleContainer>
          <StyledParagraph fontSize="xl" fontWeight="bold">
            {translate('auth:linkSuccessHeader')}
          </StyledParagraph>
          <StyledParagraph fontSize="m" color="gray_5">
            {translate('auth:recoverAccountSuccess')}
          </StyledParagraph>
        </StyledTitleContainer>
        <StyledImgContainer>
          <StyledIllustration src={image} alt="" />
        </StyledImgContainer>
        <StyledParagraph color="gray_3">
          {translate('auth:recoverAccountSuccessDesc')}
        </StyledParagraph>
        <StyledButton
          label={translate('global:continue')}
          onClick={handleLogin}
          isLoading={loading}
        />
      </StyledContainer>
    ),
    [handleLogin, image, loading, translate],
  )
  return handleRenderContent
}
