import React, {useMemo} from 'react'
import {useTranslation} from 'i18n'
import {
  GiftShopTemplateOptionsContent,
  GiftShopTemplateOptionsItem,
} from 'pages/gift-shop'
import {TemplateProfileSelfOptionModalProps} from './TemplateProfileSelfOptionModalProps'

export default function TemplateProfileSelfOptionModal({
  isPinned,
  visible,
  toggleModal,
  onClickDelete,
  onClickEdit,
  onClickPin,
  onClickShare,
  onClickRemoveCollab,
}: TemplateProfileSelfOptionModalProps) {
  const {translate} = useTranslation()

  const handleRenderChildren = useMemo(
    () => (
      <div>
        {onClickShare && (
          <GiftShopTemplateOptionsItem
            icon="share"
            text={translate('forkygram:postShare')}
            onClickItem={onClickShare}
          />
        )}
        {onClickPin && (
          <GiftShopTemplateOptionsItem
            icon={isPinned ? 'unpin' : 'pin'}
            text={translate(
              isPinned ? 'forkygram:postUnpin' : 'forkygram:postPin',
            )}
            onClickItem={onClickPin}
          />
        )}
        {onClickEdit && (
          <GiftShopTemplateOptionsItem
            icon="pencil"
            text={translate('forkygram:postEdit')}
            onClickItem={onClickEdit}
          />
        )}
        <GiftShopTemplateOptionsItem
          icon="trash"
          text={translate('forkygram:postDelete')}
          onClickItem={onClickDelete}
        />
        {onClickRemoveCollab && (
          <GiftShopTemplateOptionsItem
            icon="circular-cross"
            color="danger_5"
            text={translate('tree:removeCollaboration')}
            onClickItem={onClickRemoveCollab}
          />
        )}
      </div>
    ),
    [
      isPinned,
      onClickDelete,
      onClickEdit,
      onClickPin,
      onClickRemoveCollab,
      onClickShare,
      translate,
    ],
  )

  return (
    <GiftShopTemplateOptionsContent visible={visible} toggleModal={toggleModal}>
      {handleRenderChildren}
    </GiftShopTemplateOptionsContent>
  )
}
