import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_WHOLESALE_MAX_DISCOUNT,
  GIFT_SHOP_WHOLESALE_MAX_TIER,
  REGEX_NUMBER,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {Icon, Input, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopSettingWholesaleItemProps} from './GiftShopSettingWholesaleItemProps'

interface StyledItemContainerProps {
  isLast?: boolean
}

interface StyledIconContainerProps {
  isVisible?: boolean
}

const StyledContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(40)};
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    gap: ${convertUnit(16)};
    padding: 0;
  }
`

const StyledItemContainer = styled.div<StyledItemContainerProps>`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({isLast}) => (isLast ? '0' : `${convertUnit(20)}`)};
`

const StyledLeftContainer = styled(StyledItemContainer)`
  width: 35%;
`

const StyledRightContainer = styled(StyledItemContainer)`
  width: 65%;
`

const StyledSeparatorContainer = styled(StyledItemContainer)`
  margin: 0;
`

const StyledStandAloneItemContainer = styled.div`
  height: ${convertUnit(44)};
  display: flex;
  align-items: center;
`

const StyledSeparator = styled.div`
  width: ${convertUnit(2)};
  height: 100%;
  background-color: ${({theme}) => theme.white_4};
  align-self: stretch;
`

const StyledIconContainer = styled.div<StyledIconContainerProps>`
  cursor: pointer;
  visibility: ${({isVisible = true}) => (isVisible ? 'visible' : 'hidden')};
`

const StyledInputDiscountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${convertUnit(4)};
`

export default function GiftShopSettingWholesaleItem({
  data,
  dataLength,
  idx,
  isLastTier,
  stateData,
  onAddTier,
  onDeleteTier,
}: GiftShopSettingWholesaleItemProps) {
  const {translate} = useTranslation()
  const {min_contents, discount} = data
  const [tierData, setTierData] = stateData
  const [maxInput, setMaxInput] = useState(
    isLastTier ? '' : (tierData[idx + 1].min_contents - 1).toString(),
  )
  const [discInput, setDiscInput] = useState(discount.toString())
  const disabledInput = useMemo(() => dataLength - 1 !== idx, [dataLength, idx])

  const handleRenderSeparator = useMemo(
    () => (
      <StyledSeparatorContainer>
        <StyledSeparator />
      </StyledSeparatorContainer>
    ),
    [],
  )

  const handleRenderAmountTitle = useMemo(
    () => (
      <StyledLeftContainer>
        <Paragraph fontSize="m" fontWeight="bold">
          {translate('global:amount')}
        </Paragraph>
      </StyledLeftContainer>
    ),
    [translate],
  )

  const handleRenderDiscountTitle = useMemo(
    () => (
      <StyledRightContainer>
        <Paragraph fontSize="m" fontWeight="bold">
          {translate('giftShop:allDiscounted')}
        </Paragraph>
      </StyledRightContainer>
    ),
    [translate],
  )

  const handleRenderHeader = useMemo(
    () =>
      idx === 0 && (
        <StyledContentContainer>
          {handleRenderAmountTitle}
          {handleRenderSeparator}
          {handleRenderDiscountTitle}
        </StyledContentContainer>
      ),
    [
      handleRenderAmountTitle,
      handleRenderDiscountTitle,
      handleRenderSeparator,
      idx,
    ],
  )

  const handleRenderMin = useMemo(
    () => (
      <>
        <Paragraph fontSize="m" fontWeight="medium">
          {min_contents}
        </Paragraph>
        <Paragraph fontSize="m" fontWeight="medium">
          -
        </Paragraph>
      </>
    ),
    [min_contents],
  )

  const handleRenderMax = useMemo(
    () =>
      idx === GIFT_SHOP_WHOLESALE_MAX_TIER - 1 ? (
        <StyledStandAloneItemContainer>
          <Paragraph fontSize="m" fontWeight="medium">
            ∞
          </Paragraph>
        </StyledStandAloneItemContainer>
      ) : (
        <Input
          value={maxInput}
          disabled={disabledInput}
          containerStyle={{width: convertUnit(60), boxSizing: 'border-box'}}
          placeholder={'∞'}
          inputStyle={{textAlign: 'center'}}
          onChangeText={(text) => {
            if (text.length > 1 && text.startsWith('0')) {
              setMaxInput('0')
            } else {
              setMaxInput(text)
            }
          }}
          allowedCharacters={REGEX_NUMBER}
        />
      ),
    [disabledInput, idx, maxInput],
  )

  const handleRenderMinMax = useMemo(
    () => (
      <StyledLeftContainer isLast={isLastTier}>
        {handleRenderMin}
        {handleRenderMax}
      </StyledLeftContainer>
    ),
    [handleRenderMax, handleRenderMin, isLastTier],
  )

  const handleRenderAddTierIcon = useMemo(
    () =>
      isLastTier &&
      maxInput.length > 0 && (
        <StyledIconContainer>
          <Icon
            type="plus"
            size={convertUnit(20)}
            color="primary_5"
            onClick={() => onAddTier(maxInput, discInput)}
          />
        </StyledIconContainer>
      ),
    [discInput, isLastTier, maxInput, onAddTier],
  )

  const handleRenderDeleteIcon = useMemo(
    () =>
      isLastTier && (
        <StyledIconContainer isVisible={idx > 0}>
          <Icon
            type="trash"
            size={convertUnit(20)}
            color="gray_5"
            onClick={onDeleteTier}
          />
        </StyledIconContainer>
      ),
    [idx, isLastTier, onDeleteTier],
  )

  const handleRenderDiscount = useMemo(
    () => (
      <StyledRightContainer isLast={isLastTier}>
        <StyledInputDiscountContainer>
          <Input
            value={discInput}
            disabled={disabledInput}
            containerStyle={{
              width: convertUnit(60),
              boxSizing: 'border-box',
            }}
            inputStyle={{textAlign: 'center'}}
            max={GIFT_SHOP_WHOLESALE_MAX_DISCOUNT}
            onChangeText={(text) => {
              if (text.length > 1 && text.startsWith('0')) {
                setDiscInput('0')
                setTierData((prev) =>
                  prev.map((item, index) =>
                    idx === index ? {...item, discount: 0} : item,
                  ),
                )
              } else {
                const disc = parseInt(text, 10)
                setDiscInput(text)
                setTierData((prev) =>
                  prev.map((item, index) =>
                    idx === index ? {...item, discount: disc} : item,
                  ),
                )
              }
            }}
            allowedCharacters={REGEX_NUMBER}
          />
          <Paragraph fontSize="m" fontWeight="medium">
            %
          </Paragraph>
        </StyledInputDiscountContainer>
        {handleRenderAddTierIcon}
        {handleRenderDeleteIcon}
      </StyledRightContainer>
    ),
    [
      isLastTier,
      idx,
      discInput,
      disabledInput,
      handleRenderAddTierIcon,
      handleRenderDeleteIcon,
      setTierData,
    ],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledContentContainer>
        {handleRenderMinMax}
        {handleRenderSeparator}
        {handleRenderDiscount}
      </StyledContentContainer>
    ),
    [handleRenderDiscount, handleRenderMinMax, handleRenderSeparator],
  )

  return (
    <>
      {handleRenderHeader}
      {handleRenderContent}
    </>
  )
}
