import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, CheckboxItem} from 'common/components'
import convertUnit from 'lib/unit'
import {ConfirmationModalProps} from './ConfirmationModalProps'
import {Modal} from '../Modal'
import {Paragraph} from '../Paragraph'

const StyledModal = styled(Modal)`
  box-sizing: border-box;
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
`

const StyledModalButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${convertUnit(20)};
  gap: ${convertUnit(20)};
`

const StyledTitle = styled(Paragraph)`
  text-align: center;
`

const StyledText = styled(Paragraph)`
  text-align: center;
  margin-top: ${convertUnit(8)};
`

const StyledButton = styled(Button)`
  flex: 1;
`

const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${convertUnit(12)};
`

const StyledCheckbox = styled(CheckboxItem)`
  margin-right: ${convertUnit(8)};
`

const StyledCheckboxDesc = styled(Paragraph)`
  cursor: pointer;
`

export default function ConfirmationModal({
  text,
  title,
  subText,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  toggleModal,
  checkboxText,
  checboxDefaultStatus = false,
  ...props
}: ConfirmationModalProps) {
  const {translate} = useTranslation()
  const [isSelected, setIsSelected] = useState(checboxDefaultStatus)

  const handleConfirm = useCallback(() => {
    toggleModal()
    onConfirm(isSelected)
  }, [isSelected, onConfirm, toggleModal])

  return (
    <StyledModal {...props}>
      {title && (
        <StyledTitle fontWeight="bold" fontSize="xl" lineHeight={42}>
          {title}
        </StyledTitle>
      )}
      <StyledText fontSize="l" lineHeight={31}>
        {text}
      </StyledText>
      {subText ? <StyledText lineHeight={21}>{subText}</StyledText> : <></>}
      {checkboxText ? (
        <StyledCheckboxContainer>
          <StyledCheckbox
            isSelected={isSelected}
            size={16}
            onClick={() => setIsSelected((prev) => !prev)}
          />
          <StyledCheckboxDesc
            fontWeight="medium"
            color="gray_5"
            onClick={() => setIsSelected((prev) => !prev)}>
            {checkboxText}
          </StyledCheckboxDesc>
        </StyledCheckboxContainer>
      ) : (
        <></>
      )}
      <StyledModalButtonContainer>
        <StyledButton
          label={cancelText || translate('global:no')}
          backgroundHoverColor="white_5"
          backgroundColor="white_3"
          color="primary_5"
          onClick={() => {
            onCancel && onCancel()
            toggleModal()
          }}
        />
        <StyledButton
          label={confirmText || translate('global:yes')}
          onClick={handleConfirm}
        />
      </StyledModalButtonContainer>
    </StyledModal>
  )
}
