import React, {useMemo} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {IconVerifiedBadge, Paragraph} from 'common/components'
import {GiftShopProfileInformationContentItemProps} from './GiftShopEditProfileContentItemProps'

interface StyledLinkProps {
  disabled: boolean
}

interface StyledParagraphProps {
  mode: WindowModeType
  badge?: boolean
}

const StyledContainer = styled.div<{isFullWidth: boolean}>`
  display: flex;
  flex-direction: column;
  width: ${({isFullWidth}) => (isFullWidth ? '100%' : '49%')};
  flex-shrink: 0;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: 100%;
  }
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledParagraph = styled(Paragraph)<StyledParagraphProps>`
  word-break: break-word;
  white-space: pre-wrap;
  margin-bottom: ${convertUnit(5)};
  padding-right: ${({badge, mode}) =>
    mode === 'mobile' ? 0 : badge ? convertUnit(5) : convertUnit(25)};
`

const StyledLink = styled(Paragraph)<StyledLinkProps>`
  cursor: ${({disabled = false}) => (disabled ? 'initial' : 'pointer')};
  margin-bottom: ${convertUnit(5)};
`

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-left: ${convertUnit(4)};
  width: ${convertUnit(24)};
  height: ${convertUnit(24)};
`

const StyledUnconfirmedLabel = styled(Paragraph)`
  margin-bottom: ${convertUnit(5)};
`

export default function GiftShopProfileInformationContentItem({
  label,
  value,
  actionText,
  emptyText,
  disabled = false,
  badge = false,
  isFullWidth = false,
  showUnconfirmedLabel = false,
  onAction,
}: GiftShopProfileInformationContentItemProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const {creatorStatus} = useSelector('yuserActivationState')

  const handleRenderBadge = useMemo(
    () => creatorStatus === 'verified' && badge && <StyledIconVerifiedBadge />,
    [badge, creatorStatus],
  )

  const handleRenderUnconfirmedLabel = useMemo(
    () => (
      <StyledUnconfirmedLabel fontWeight="bold" color="danger_5">
        {translate('giftShop:editProfileNotConfirmed')}
      </StyledUnconfirmedLabel>
    ),
    [translate],
  )

  return (
    <StyledContainer isFullWidth={isFullWidth}>
      <StyledParagraph mode={mode} fontSize="m" color="gray_5">
        {label}
      </StyledParagraph>
      <StyledRowContainer>
        <StyledParagraph
          badge={badge}
          mode={mode}
          fontSize="l"
          fontWeight="medium"
          lineHeight={22}
          color={value ? 'black' : 'gray_3'}>
          {value || emptyText || translate('global:notRegistered')}
        </StyledParagraph>
        {handleRenderBadge}
      </StyledRowContainer>
      {showUnconfirmedLabel && handleRenderUnconfirmedLabel}
      {actionText && (
        <StyledLink
          disabled={disabled}
          fontWeight="medium"
          color={disabled ? 'gray_3' : 'primary_5'}
          onClick={disabled ? undefined : onAction}>
          {actionText}
        </StyledLink>
      )}
    </StyledContainer>
  )
}
