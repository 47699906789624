import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTheme} from 'themes'
import {FirebaseMessageRoomItem, FirebaseMessageRoomItemPayload} from 'types'
import {getBorder, parseDate} from 'utils'
import {Icon, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {
  GiftShopMessageRoomContentItemChat,
  GiftShopMessageRoomContentItemNotificationPurchase,
  GiftShopMessageRoomContentItemNotificationAwaitingPayment,
  GiftShopMessageRoomContentItemNotificationFollow,
  GiftShopMessageRoomContentItemNotificationLike,
  GiftShopMessageRoomContentItemNotificationRefundContent,
  GiftShopMessageRoomContentItemNotificationRefundContentCreator,
  GiftShopMessageRoomContentItemNotificationMakeItFreeContent,
  GiftShopMessageRoomContentItemReminderResend,
  GiftShopMessageRoomContentItemReminderRobopet,
  GiftShopMessageRoomContentItemReminderWaitingHires,
  GiftShopMessageRoomContentItemUnsupported,
  GiftShopMessageRoomContentItemNotificationPurchaseCreator,
  GiftShopMessageRoomContentItemReminderWishlist,
  GiftShopMessageRoomContentItemNotificationWithdraw,
  GiftShopMessageRoomContentItemReminderCreatorActivation,
  GiftShopMessageRoomContentItemReminderCart,
  GiftShopMessageRoomContentItemReminderAbusiveContent,
  GiftShopMessageRoomContentItemNotificationHostAdminDemotion,
  GiftShopMessageRoomContentItemNotificationHostCreatorDemotion,
  GiftShopMessageRoomContentItemNotificationHostAdminPromotion,
  GiftShopMessageRoomContentItemNotificationHostCreatorPromotion,
  GiftShopMessageRoomContentItemNotificationStorageAddition,
  GiftShopMessageRoomContentItemNotificationCreatorStorageAddition,
  GiftShopMessageRoomContentItemNotificationReceiveHires,
  GiftShopMessageRoomContentItemNotificationResendpology,
  GiftShopMessageRoomContentItemNotificationResendpologyCreator,
  GiftShopMessageRoomContentItemNotificationPurchaseCompletedReview,
  GiftShopMessageRoomContentItemNotificationCreatorReviewed,
  GiftShopMessageRoomContentItemNotificationAcceptInvitationAdmin,
  GiftShopMessageRoomContentItemNotificationAcceptInvitationAdminHost,
  GiftShopMessageRoomContentItemNotificationAcceptInvitationCreator,
  GiftShopMessageRoomContentItemNotificationAcceptInvitationCreatorHost,
  GiftShopMessageRoomContentItemNotificationRejectInvitationAdmin,
  GiftShopMessageRoomContentItemNotificationRejectInvitationAdminHost,
  GiftShopMessageRoomContentItemNotificationRejectInvitationCreator,
  GiftShopMessageRoomContentItemNotificationRejectInvitationCreatorHost,
  GiftShopMessageRoomContentItemNotificationPromotionHostAdminCancel,
  GiftShopMessageRoomContentItemNotificationPromotionHostCreatorCancel,
  GiftShopMessageRoomContentItemNotificationComment,
  GiftShopMessageRoomContentItemNotificationCommentMention,
  GiftShopMessageRoomContentItemNotificationReplyComment,
  GiftShopMessageRoomContentItemNotificationResendApproval,
  GiftShopMessageRoomContentItemNotificationRemoveFarmer,
  GiftShopMessageRoomContentItemNotificationInviteFarmer,
  GiftShopMessageRoomContentItemNotificationLikeComment,
  GiftShopMessageRoomContentItemNotificationLikeCommentGroup,
  GiftShopMessageRoomContentItemNotificationLikeGroup,
  GiftShopMessageRoomContentItemNotificationFollowGroup,
  GiftShopMessageRoomContentItemNotificationTreePlanted,
  GiftShopMessageRoomContentItemRequestPaidContent,
  GiftShopMessageRoomContentItemNotificationCollaborator,
} from './content-item'
import GiftShopMessageRoomContentItemResendRevision from './content-item/GiftShopMessageRoomContentItemResendRevision'
import GiftShopMessageRoomContentItemNotificationWithdrawCancelled from './content-item/GiftShopMessageRoomContentItemNotificationWithdrawCancelled'

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(5)};
  align-items: center;
  justify-content: flex-end;
`
const StyledDescriptionStatus = styled(Icon)`
  margin-left: ${convertUnit(5)};
`
const StyledContainer = styled.div`
  display: flex;
  padding: ${convertUnit(8)} ${convertUnit(20)};
`
const StyledBubbleContainer = styled.div`
  width: 100%;
`
const StyledBubbleChatContainer = styled.div`
  padding: ${convertUnit(12)} ${convertUnit(16)};
  border: ${({theme}) => getBorder(1, 'solid', theme.white_3)};
  border-radius: ${convertUnit(16)};
`
export interface GiftShopMessageRoomContentItemProps {
  data: FirebaseMessageRoomItem
}

export default function GiftShopMessageRoomContentItem({
  data: {created_at, type, from_local, seen, sender, payload},
}: GiftShopMessageRoomContentItemProps) {
  const theme = useTheme()
  const {id, username} = useSelector('user')!

  const isSelf = useMemo(() => id === sender, [id, sender])

  const handleRenderMessage = useMemo(() => {
    switch (type) {
      case 'chat':
        return (
          <GiftShopMessageRoomContentItemChat
            payload={payload as FirebaseMessageRoomItemPayload['chat']}
          />
        )
      case 'notification_accept_invitation_admin':
        return (
          <GiftShopMessageRoomContentItemNotificationAcceptInvitationAdmin
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_accept_invitation_admin']
            }
          />
        )
      case 'notification_accept_invitation_admin_host':
        return (
          <GiftShopMessageRoomContentItemNotificationAcceptInvitationAdminHost
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_accept_invitation_admin_host']
            }
          />
        )
      case 'notification_accept_invitation_creator':
        return (
          <GiftShopMessageRoomContentItemNotificationAcceptInvitationCreator
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_accept_invitation_creator']
            }
          />
        )
      case 'notification_accept_invitation_creator_host':
        return (
          <GiftShopMessageRoomContentItemNotificationAcceptInvitationCreatorHost
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_accept_invitation_creator_host']
            }
          />
        )
      case 'notification_awaiting_payment':
        return (
          <GiftShopMessageRoomContentItemNotificationAwaitingPayment
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_awaiting_payment']
            }
          />
        )
      case 'notification_creator_reviewed':
        return (
          <GiftShopMessageRoomContentItemNotificationCreatorReviewed
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_creator_reviewed']
            }
          />
        )
      case 'notification_promotion_host_admin_cancel':
        return (
          <GiftShopMessageRoomContentItemNotificationPromotionHostAdminCancel
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_promotion_host_admin_cancel']
            }
          />
        )
      case 'notification_promotion_host_creator_cancel':
        return (
          <GiftShopMessageRoomContentItemNotificationPromotionHostCreatorCancel
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_promotion_host_creator_cancel']
            }
          />
        )
      case 'notification_purchase':
        return (
          <GiftShopMessageRoomContentItemNotificationPurchase
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_purchase']
            }
          />
        )
      case 'notification_purchase_completed_review':
        return (
          <GiftShopMessageRoomContentItemNotificationPurchaseCompletedReview
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_purchase_completed_review']
            }
            isSelf={
              (payload as FirebaseMessageRoomItemPayload['notification_purchase_completed_review'])
                .creator_username === username
            }
          />
        )
      case 'notification_refund_content':
        return (
          <GiftShopMessageRoomContentItemNotificationRefundContent
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_refund_content']
            }
          />
        )
      case 'notification_refund_content_creator':
        return (
          <GiftShopMessageRoomContentItemNotificationRefundContentCreator
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_refund_content_creator']
            }
          />
        )
      case 'notification_make_it_free_content':
        return (
          <GiftShopMessageRoomContentItemNotificationMakeItFreeContent
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_make_it_free_content']
            }
          />
        )
      case 'notification_non_public_fototree_planted':
        return (
          <GiftShopMessageRoomContentItemNotificationTreePlanted
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_non_public_fototree_planted']
            }
          />
        )
      case 'notification_purchase_creator':
        return (
          <GiftShopMessageRoomContentItemNotificationPurchaseCreator
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_purchase_creator']
            }
          />
        )
      case 'notification_receive_hires':
        return (
          <GiftShopMessageRoomContentItemNotificationReceiveHires
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_receive_hires']
            }
          />
        )
      case 'notification_reject_invitation_admin':
        return (
          <GiftShopMessageRoomContentItemNotificationRejectInvitationAdmin
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_reject_invitation_admin']
            }
          />
        )
      case 'notification_reject_invitation_admin_host':
        return (
          <GiftShopMessageRoomContentItemNotificationRejectInvitationAdminHost
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_reject_invitation_admin_host']
            }
          />
        )
      case 'notification_reject_invitation_creator':
        return (
          <GiftShopMessageRoomContentItemNotificationRejectInvitationCreator
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_reject_invitation_creator']
            }
          />
        )
      case 'notification_reject_invitation_creator_host':
        return (
          <GiftShopMessageRoomContentItemNotificationRejectInvitationCreatorHost
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_reject_invitation_creator_host']
            }
          />
        )
      case 'notification_resend_approval':
        return (
          <GiftShopMessageRoomContentItemNotificationResendApproval
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_resend_approval']
            }
          />
        )
      case 'notification_resendpology':
        return (
          <GiftShopMessageRoomContentItemNotificationResendpology
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_resendpology']
            }
          />
        )
      case 'notification_resendpology_creator':
        return (
          <GiftShopMessageRoomContentItemNotificationResendpologyCreator
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_resendpology_creator']
            }
          />
        )
      case 'notification_storage_addition':
        return (
          <GiftShopMessageRoomContentItemNotificationStorageAddition
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_storage_addition']
            }
          />
        )
      case 'notification_tree_approved':
        return (
          <GiftShopMessageRoomContentItemNotificationTreePlanted
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_tree_approved']
            }
            type="approved"
          />
        )
      case 'notification_tree_removed':
        return (
          <GiftShopMessageRoomContentItemNotificationTreePlanted
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_tree_removed']
            }
            type="removed"
          />
        )
      case 'notification_creator_storage_addition':
        return (
          <GiftShopMessageRoomContentItemNotificationCreatorStorageAddition
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_creator_storage_addition']
            }
          />
        )
      case 'notification_withdraw':
        return (
          <GiftShopMessageRoomContentItemNotificationWithdraw
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_withdraw']
            }
          />
        )
      case 'notification_withdraw_cancelled':
        return (
          <GiftShopMessageRoomContentItemNotificationWithdrawCancelled
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_withdraw_cancelled']
            }
          />
        )

      case 'notification_follow':
        return (
          <GiftShopMessageRoomContentItemNotificationFollow
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_follow']
            }
          />
        )
      case 'notification_follow_group':
        return (
          <GiftShopMessageRoomContentItemNotificationFollowGroup
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_follow_group']
            }
          />
        )
      case 'notification_like':
        return (
          <GiftShopMessageRoomContentItemNotificationLike
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_like']
            }
          />
        )
      case 'notification_like_comment':
        return (
          <GiftShopMessageRoomContentItemNotificationLikeComment
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_like_comment']
            }
          />
        )
      case 'notification_like_comment_group':
        return (
          <GiftShopMessageRoomContentItemNotificationLikeCommentGroup
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_like_comment_group']
            }
          />
        )
      case 'notification_like_group':
        return (
          <GiftShopMessageRoomContentItemNotificationLikeGroup
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_like_group']
            }
          />
        )
      case 'notification_collaborator':
        return (
          <GiftShopMessageRoomContentItemNotificationCollaborator
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_collaborator']
            }
          />
        )
      case 'notification_collaborator_request':
        return (
          <GiftShopMessageRoomContentItemNotificationCollaborator
            isInvitation
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_collaborator_request']
            }
          />
        )
      case 'notification_comment':
        return (
          <GiftShopMessageRoomContentItemNotificationComment
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_comment']
            }
          />
        )
      case 'notification_comment_mention':
        return (
          <GiftShopMessageRoomContentItemNotificationCommentMention
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_comment_mention']
            }
          />
        )
      case 'notification_reply_comment':
        return (
          <GiftShopMessageRoomContentItemNotificationReplyComment
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_reply_comment']
            }
          />
        )
      case 'notification_demotion_host_admin':
        return (
          <GiftShopMessageRoomContentItemNotificationHostAdminDemotion
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_demotion_host_admin']
            }
          />
        )
      case 'notification_demotion_host_creator':
        return (
          <GiftShopMessageRoomContentItemNotificationHostCreatorDemotion
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_demotion_host_creator']
            }
          />
        )
      case 'notification_promotion_host_admin':
        return (
          <GiftShopMessageRoomContentItemNotificationHostAdminPromotion
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_promotion_host_admin']
            }
          />
        )
      case 'notification_promotion_host_creator':
        return (
          <GiftShopMessageRoomContentItemNotificationHostCreatorPromotion
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_promotion_host_creator']
            }
          />
        )
      case 'notification_remove_farmer':
        return (
          <GiftShopMessageRoomContentItemNotificationRemoveFarmer
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_remove_farmer']
            }
          />
        )
      case 'notification_invite_farmer':
        return (
          <GiftShopMessageRoomContentItemNotificationInviteFarmer
            payload={
              payload as FirebaseMessageRoomItemPayload['notification_invite_farmer']
            }
          />
        )
      case 'reminder_waiting_hires_content':
        return (
          <GiftShopMessageRoomContentItemReminderWaitingHires
            payload={
              payload as FirebaseMessageRoomItemPayload['reminder_waiting_hires_content']
            }
          />
        )
      case 'reminder_wishlist':
        return (
          <GiftShopMessageRoomContentItemReminderWishlist
            payload={
              payload as FirebaseMessageRoomItemPayload['reminder_wishlist']
            }
          />
        )
      case 'reminder_creator_activation':
        return <GiftShopMessageRoomContentItemReminderCreatorActivation />
      case 'reminder_cart':
        return (
          <GiftShopMessageRoomContentItemReminderCart
            payload={payload as FirebaseMessageRoomItemPayload['reminder_cart']}
          />
        )
      case 'reminder_abusive_content':
        return (
          <GiftShopMessageRoomContentItemReminderAbusiveContent
            payload={
              payload as FirebaseMessageRoomItemPayload['reminder_abusive_content']
            }
          />
        )
      case 'reminder_resend_content':
        return (
          <GiftShopMessageRoomContentItemReminderResend
            payload={
              payload as FirebaseMessageRoomItemPayload['reminder_resend_content']
            }
          />
        )
      case 'reminder_robopet':
        return <GiftShopMessageRoomContentItemReminderRobopet />
      case 'resend_revision':
        return (
          <GiftShopMessageRoomContentItemResendRevision
            isSelf={isSelf}
            payload={
              payload as FirebaseMessageRoomItemPayload['resend_revision']
            }
          />
        )
      case 'request_paid_content':
        return (
          <GiftShopMessageRoomContentItemRequestPaidContent
            isSelf={isSelf}
            payload={
              payload as FirebaseMessageRoomItemPayload['request_paid_content']
            }
          />
        )
      default:
        return <GiftShopMessageRoomContentItemUnsupported />
    }
  }, [isSelf, payload, type, username])

  const handleRenderDescription = useMemo(
    () => (
      <StyledDescriptionContainer>
        <Paragraph color="gray_5">{parseDate(created_at, 'HH:mm')}</Paragraph>
        {isSelf && (
          <StyledDescriptionStatus
            type={from_local ? 'sent' : 'read'}
            size={16}
            color={seen ? 'primary_5' : 'gray_5'}
          />
        )}
      </StyledDescriptionContainer>
    ),
    [created_at, from_local, seen, isSelf],
  )

  return (
    <StyledContainer>
      <StyledBubbleContainer
        style={{
          display: 'flex',
          justifyContent: isSelf ? 'flex-end' : 'flex-start',
          paddingRight: isSelf ? 0 : 75,
          paddingLeft: isSelf ? 75 : 0,
        }}>
        <StyledBubbleChatContainer
          style={{
            backgroundColor: isSelf ? theme.white_1 : theme.white_5,
          }}>
          {handleRenderMessage}
          {handleRenderDescription}
        </StyledBubbleChatContainer>
      </StyledBubbleContainer>
    </StyledContainer>
  )
}
