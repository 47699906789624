import React, {useCallback, useState} from 'react'
import AutoSizer, {Size} from 'react-virtualized-auto-sizer'
import {ForkygramPostData, ObjectState} from 'types'
import {FORKYGRAM_FEED_QUERY_LIMIT} from 'consts'
import {requestData} from 'services'
import {FlatlistLazy} from 'common/components'
import {GiftShopFeedEmpty} from '../Empty'
import {GiftShopFeedContent} from '../Content'

interface GiftShopFeedTabAllProps {
  stateEdit: ObjectState<ForkygramPostData | undefined>
  onVisibilityIndexChange(index?: number): void
  visibilityIndex: number
  stateVideoPause: ObjectState<boolean>
  stateMute: ObjectState<boolean>
  setVideoPlaying?: (state: React.SetStateAction<boolean>) => void
}
export default function GiftShopFeedTabAll({
  stateEdit,
  onVisibilityIndexChange,
  visibilityIndex,
  stateMute,
  stateVideoPause,
  setVideoPlaying,
}: GiftShopFeedTabAllProps) {
  const setEdit = stateEdit[1]
  const stateData = useState<ReadonlyArray<ForkygramPostData>>([])
  const setData = stateData[1]
  const stateSearch = useState(0)

  const handleRefresh = useCallback(() => {
    const date = new Date().getTime()
    stateSearch[1](date)
  }, [stateSearch])

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('forkygram_get_feeds', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {page, limit},
      })
      if (typeof response !== 'string' && response.status === 200) {
        /** after change tab, there's an issue if the current active tab's very first content is video,
         * the video is paused while BGM keeps playing.
         * this checks if the very first content is video, it will be played and pauses BGM. */
        if (page === 1 && response.data.result[0].content_type === 'video') {
          setVideoPlaying && setVideoPlaying(true)
        }
        return response.data.result
      }
      return []
    },
    [setVideoPlaying],
  )

  const handleKeyExtractor = useCallback(
    ({id, loop_number}: ForkygramPostData) => `${id}[${loop_number}]`,
    [],
  )

  const handleBlock = useCallback(
    (memberId: string) => {
      setData((prev) =>
        prev.filter(
          (post) =>
            !post.collaborator_members.some((m) => m.member_id === memberId),
        ),
      )
      handleRefresh()
    },
    [handleRefresh, setData],
  )

  const handleFollow = useCallback(
    (memberId: string) => {
      setData((prev) =>
        prev.map((x) =>
          x.collaborator_members.some((m) => m.member_id === memberId)
            ? {
                ...x,
                collaborator_members: x.collaborator_members.map((m) =>
                  m.member_id === memberId ? {...m, is_followed: true} : m,
                ),
              }
            : x,
        ),
      )
    },
    [setData],
  )

  const handleRemoveCollaborator = useCallback(
    (item: ForkygramPostData, memberId: string) => {
      setData((prev) =>
        prev.map((x) =>
          x.id === item.id
            ? {
                ...x,
                collaborator_members: x.collaborator_members.filter(
                  (m) => m.member_id !== memberId,
                ),
              }
            : x,
        ),
      )
    },
    [setData],
  )

  const handleRenderItem = useCallback(
    (item: ForkygramPostData, index: number) => (
      <GiftShopFeedContent
        index={index}
        visibilityIndex={visibilityIndex}
        data={item}
        stateVideoPause={stateVideoPause}
        stateMute={stateMute}
        setVideoPlaying={setVideoPlaying}
        onBlocked={handleBlock}
        onReported={(postId) =>
          setData((prev) => prev.filter((post) => post.id !== postId))
        }
        onDeleted={(postId) =>
          setData((prev) => prev.filter((post) => post.id !== postId))
        }
        onEdited={() => setEdit(item)}
        onFollowed={handleFollow}
        onCollabRemoved={(memberId) => handleRemoveCollaborator(item, memberId)}
      />
    ),
    [
      handleBlock,
      handleFollow,
      handleRemoveCollaborator,
      setData,
      setEdit,
      setVideoPlaying,
      stateMute,
      stateVideoPause,
      visibilityIndex,
    ],
  )

  const handleRenderList = useCallback(
    ({height, width}: Size) => (
      <FlatlistLazy
        height={height}
        width={width}
        cache="feed"
        loadData={handleLoadData}
        limitPerPage={FORKYGRAM_FEED_QUERY_LIMIT}
        renderItem={handleRenderItem}
        keyExtractor={handleKeyExtractor}
        onVisibilityIndexChange={onVisibilityIndexChange}
        search={stateSearch[0]}
        stateData={stateData}
        scrollbar={false}
        scrollSnapType="y mandatory"
        listEmptyElement={<GiftShopFeedEmpty />}
      />
    ),
    [
      handleLoadData,
      handleRenderItem,
      handleKeyExtractor,
      onVisibilityIndexChange,
      stateSearch,
      stateData,
    ],
  )
  return <AutoSizer>{handleRenderList}</AutoSizer>
}
