import React, {useMemo, useState, useRef, useEffect, useCallback} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET, WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {TemplateAuthAccessModal} from 'pages'
import {AssetImageFotoTree, TreeFototreeMemberData} from 'types'
import {
  formatRelativeDateDiff,
  fototreeLocationName,
  getCollaboratorMember,
  getTextShadow,
  getThemeColor,
  useHistory,
} from 'utils'
import {useWindowLayout} from 'windows'
import {Icon, Image, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {
  ForkygramTemplatePostButtons,
  ForkygramTemplatePostProfile,
  ForkygramTemplatePostProgressBar,
} from '../../../../forkygram'
import {FototreeMemoryContentItemProps} from './FototreeContentItemProps'
import FototreeMemoryContentItemVideo from './FototreeMemoryContentItemVideo'

interface StyledContentProps {
  height: number
}

interface StyledParagraphProps {
  collapse: boolean
}

const StyledContentImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  box-sizing: border-box;
`

const StyledMobileCaptionContainer = styled.div`
  position: absolute;
  left: ${convertUnit(12)};
  bottom: ${convertUnit(12)};
  display: flex;
  flex-direction: column;
  width: calc(100% - (${convertUnit(70)}));
  max-width: ${WINDOW_MODE_MOBILE_WIDTH}px;
`

const StyledCaptionContainer = styled.div`
  display: flex;
  flex: 1;
  width: fill-available;
  flex-direction: column;
`

const StyledCaptionContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
`

const StyledParagraphContainer = styled.div<StyledParagraphProps>`
  height: fit-content;
  max-height: ${({collapse}) => (collapse ? convertUnit(30) : 'fit-content')};
  overflow: hidden;
`

const StyledParagraph = styled(Paragraph)`
  white-space: pre-wrap;
  text-shadow: ${({theme}) => getTextShadow(theme)};
`

const StyledDateContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledCollapsibleParagraph = styled(Paragraph)<StyledParagraphProps>`
  ${({collapse}) =>
    collapse && {
      overflow: 'hidden',
    }};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: auto;
  word-break: break-word;
  white-space: pre-wrap;
  text-shadow: ${({theme}) => getTextShadow(theme)};
`

const StyledContentContainer = styled.div<StyledContentProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({height}) => convertUnit(height)};
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  max-width: ${convertUnit(600)};
  margin: auto;
`

const StyledInvisibleContainer = styled.div<StyledParagraphProps>`
  display: flex;
  column-gap: ${convertUnit(4)};
  background-color: ${({theme, collapse}) =>
    getThemeColor(theme, 'black', collapse ? 0.4 : 0.5)};
  width: fit-content;
`

const StyledInlineParagraph = styled(StyledParagraph)`
  display: inline;
  word-break: break-word;
  margin-right: ${convertUnit(4)};
`

const StyledFotoTreeTag = styled.div`
  display: flex;
  height: fit-content;
  width: fit-content;
  gap: ${convertUnit(4)};
  margin-top: ${convertUnit(4)};
`

const StyledFotoTreePinContainer = styled.div`
  width: fit-content;
`

const StyledFotoTreePin = styled(Image)`
  height: ${convertUnit(16)};
`

export default function FototreeMemoryContentItem({
  data,
  dataTag,
  active,
  focused,
  stateMute,
  statePause,
  onClickMore,
  onFollow,
  stateFollowButton,
}: FototreeMemoryContentItemProps) {
  const {translate} = useTranslation()
  const {access_token, id: userId} = useSelector('user') || {}
  const [mute, setMute] = stateMute
  const history = useHistory()
  const {height} = useWindowLayout()
  const ref = useRef<HTMLDivElement>(null)
  const stateProgress = useState(0)
  const [collapse, setCollapse] = useState(true)
  const [captionClientHeight, setClientHeight] = useState(0)
  const [captionScrollHeight, setScrollHeight] = useState(0)
  const [loginModal, setLoginModal] = useState(false)

  const isOverflow = useMemo(() => captionScrollHeight > captionClientHeight, [
    captionClientHeight,
    captionScrollHeight,
  ])

  const {collaboratorOwner, collaboratorMember} = getCollaboratorMember(
    data.collaborator_members,
  )

  const locationName = useMemo(
    () => fototreeLocationName(dataTag?.location?.name),
    [dataTag?.location?.name],
  )

  const handleClickMore = useCallback(() => {
    if (!access_token) {
      setLoginModal(true)
      return
    }
    onClickMore()
  }, [access_token, onClickMore])

  const handleFollow = useCallback(() => {
    if (!access_token) {
      setLoginModal(true)
      return
    }
    onFollow(collaboratorOwner.id)
  }, [access_token, collaboratorOwner.id, onFollow])

  const handleNavigateProfile = useCallback(
    (member: TreeFototreeMemberData) => {
      if (!access_token) {
        setLoginModal(true)
        return
      }

      if (member.id === userId) {
        history.push('giftshop_profile', {self: true}, member.username)
        return
      }

      history.pushQuery({
        path: 'giftshop_search_by_creator_creation_content',
        state: {
          photo: member.photo,
          allowNonVerified: true,
        },
        queryObject: {
          creator_id: member.creator_id,
          username: member.username,
        },
      })
    },
    [access_token, userId, history],
  )

  const handleRenderContent = useMemo(
    () =>
      data.content_type === 'photo' ? (
        <StyledContentImage src={data.url} alt="" height="100%" width="100%" />
      ) : (
        <FototreeMemoryContentItemVideo
          active={active}
          focused={focused}
          mute={mute}
          src={data.url}
          statePause={statePause}
          stateProgress={stateProgress}
        />
      ),
    [
      active,
      data.content_type,
      data.url,
      focused,
      mute,
      statePause,
      stateProgress,
    ],
  )

  const handleRenderProgressBar = useMemo(
    () => <ForkygramTemplatePostProgressBar progress={stateProgress[0]} />,
    [stateProgress],
  )

  const handleRenderFotoTreeTag = useMemo(
    () =>
      dataTag?.name &&
      dataTag?.icon && (
        <StyledFotoTreeTag>
          <StyledFotoTreePinContainer>
            <StyledFotoTreePin
              src={IMAGE_ASSET(
                'tree',
                `icons/pinpoint-${
                  dataTag.icon.split('/icons/')[1]
                }` as AssetImageFotoTree,
              )}
              alt={dataTag.name}
            />
          </StyledFotoTreePinContainer>
          <StyledInlineParagraph
            fontWeight="medium"
            color="white_1"
            lineHeight={14}>
            {`${dataTag.name}${locationName ? ` • ${locationName}` : ''}`}
          </StyledInlineParagraph>
        </StyledFotoTreeTag>
      ),
    [dataTag?.icon, dataTag?.name, locationName],
  )

  const handleRenderDate = useMemo(
    () => (
      <StyledParagraph color="gray_1" fontSize="xs">
        {formatRelativeDateDiff(data.created_at)}
      </StyledParagraph>
    ),
    [data.created_at],
  )

  const handleRenderMobileCaption = useMemo(
    () => (
      <StyledMobileCaptionContainer>
        <ForkygramTemplatePostProfile
          stateFollowButton={stateFollowButton}
          collabOwnerData={collaboratorOwner}
          collabMemberData={collaboratorMember}
          memberId={collaboratorOwner.id}
          onClickAvatar={() => handleNavigateProfile(collaboratorOwner)}
          onClickCollabMember={
            collaboratorMember
              ? () => handleNavigateProfile(collaboratorMember)
              : undefined
          }
          onFollowed={handleFollow}
        />
        {handleRenderFotoTreeTag}
        <StyledCaptionContainer>
          {data.is_hidden && (
            <StyledInvisibleContainer collapse={collapse}>
              <Icon
                type="invisible"
                size={convertUnit(12)}
                color="white_1"
                hasOpacity
                hasShadow
              />
              <Paragraph color="white_1" fontWeight="medium">
                {translate('tree:invisible')}
              </Paragraph>
            </StyledInvisibleContainer>
          )}
          {data.caption ? (
            <StyledCaptionContentContainer
              onClick={() => setCollapse((prev) => !prev)}>
              <StyledParagraphContainer collapse={collapse} ref={ref}>
                <StyledCollapsibleParagraph
                  lineHeight={14}
                  color="white_1"
                  collapse={collapse}>
                  {data.caption.replace(/\\n/g, '\n')}
                </StyledCollapsibleParagraph>
              </StyledParagraphContainer>
              <StyledDateContainer>
                {isOverflow && (
                  <StyledParagraph
                    lineHeight={14}
                    color="white_1"
                    fontWeight="bold">
                    {'...'}
                    {collapse
                      ? translate('global:more')
                      : translate('global:less')}
                    {` \u2022 `}
                  </StyledParagraph>
                )}
                {handleRenderDate}
              </StyledDateContainer>
            </StyledCaptionContentContainer>
          ) : (
            handleRenderDate
          )}
        </StyledCaptionContainer>
      </StyledMobileCaptionContainer>
    ),
    [
      collaboratorMember,
      collaboratorOwner,
      collapse,
      data.caption,
      data.is_hidden,
      handleFollow,
      handleNavigateProfile,
      handleRenderDate,
      handleRenderFotoTreeTag,
      isOverflow,
      stateFollowButton,
      translate,
    ],
  )

  // @todo: simplify props for member data
  const handleRenderRightButtons = useMemo(
    () => (
      <ForkygramTemplatePostButtons
        postId={data.post_id}
        ownerId={collaboratorOwner.id}
        photo={collaboratorOwner.photo}
        username={collaboratorOwner.username}
        member_id={collaboratorOwner.id}
        creator_status={collaboratorOwner.creator_status}
        isFeed
        data={data}
        mute={mute}
        handleShowModal={() => setLoginModal(true)}
        onPressMute={() => setMute((prev) => !prev)}
        onClickMore={handleClickMore}
      />
    ),
    [collaboratorOwner, data, handleClickMore, mute, setMute],
  )

  const handleRenderModalLogin = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal, setLoginModal],
  )

  useEffect(() => {
    ref.current?.clientHeight && setClientHeight(ref.current.clientHeight)
    ref.current?.scrollHeight && setScrollHeight(ref.current.scrollHeight)
  }, [])

  return (
    <StyledContentContainer height={height}>
      {handleRenderModalLogin}
      {handleRenderContent}
      {handleRenderMobileCaption}
      {handleRenderRightButtons}
      {handleRenderProgressBar}
    </StyledContentContainer>
  )
}
