import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import ReactHlsPlayer from '@gumlet/react-hls-player'
import {
  GIFT_SHOP_ROOT_BOTTOM_NAVBAR_HEIGHT,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {TemplateAuthAccessModal, ValidateKYCModal} from 'pages'
import {getCollaboratorMember, showModalLimitedAccess, useHistory} from 'utils'
import {useWindowLayout, useWindowMode} from 'windows'
import {Icon, Image} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {ForkygramTemplatePostButtons} from '../Buttons'
import {ForkygramTemplatePostCaptionMobile} from '../CaptionMobile'
import {ForkygramTemplatePostContentProps} from './ForkygramTemplatePostContentProps'
import {ForkygramTemplatePostProfile} from '../Profile'
import {ForkygramTemplatePostProgressBar} from '../ProgressBar'
import {ForkygramTemplatePostAudioPlayPause} from '../Audio'

interface StyledRelativeProps {
  width: number
}

interface StyledContentProps {
  height: number
}

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledContainer = styled(StyledRowContainer)`
  height: 100vh;
  width: 100%;
  scroll-snap-align: center;

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    height: 100%;
  }
`

const StyledRelative = styled.div<StyledRelativeProps>`
  position: relative;
  height: fit-content;
  overflow-x: hidden;
  width: ${({width}) => convertUnit(width)};
  background-color: ${({theme}) => theme.black};
`

const StyledContentContainer = styled.div<StyledContentProps>`
  position: relative;
  display: flex;
  justify-content: center;
  height: ${({height}) => convertUnit(height)};
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  max-width: ${convertUnit(600)};
  background-color: ${({theme}) => theme.black};
  margin: auto;
`

const StyledContentImage = styled(Image)<StyledContentProps>`
  max-width: 100%;
  object-fit: contain;
  height: 100%;
  max-height: ${({height}) => convertUnit(height)};
`

const StyledProfileContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: ${convertUnit(12)};
  bottom: ${convertUnit(18)};
  display: flex;
  flex-direction: column;
  width: calc(100% - (${convertUnit(70)}));
  max-width: calc(100% - (${convertUnit(82)}));
`

const StyledIconContainer = styled.div`
  position: absolute;
  top: ${convertUnit(16)};
  left: ${convertUnit(16)};
  cursor: pointer;
  z-index: 2;
`

const StyledVideo = styled(ReactHlsPlayer)<StyledContentProps>`
  max-width: 100%;
  object-fit: scale-down;
  max-height: ${({height}) => convertUnit(height)};
`

const StyledOverlay = styled.div<StyledContentProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: ${({height}) => convertUnit(height)};
  content: ' ';
`

export default function ForkygramTemplatePostContent({
  data,
  isFeed = true,
  active,
  focused,
  stateMute,
  stateVideoPause,
  setVideoPlaying,
  toggleTimeout = 500,
  onClickMore,
  onFollowed,
  stateFollowButton,
}: ForkygramTemplatePostContentProps) {
  const {width, height} = useWindowLayout()
  const mode = useWindowMode()
  const videoRef = useRef<HTMLVideoElement>(null)
  const history = useHistory()
  const [progress, setProgress] = useState(0)
  const [showButton, setShowButton] = useState(false)
  const stateModalLogin = useState(false)
  const [videoPause, setVideoPause] = stateVideoPause
  const [loginModal, setLoginModal] = stateModalLogin
  const [mute, setMute] = stateMute

  const {status: childStatus} = useSelector('parentalConsentState')
  const {access_token, id, username} = useSelector('user') || {}
  const timestamp = useRef(new Date().getTime())
  const [valKycModal, setValKycModal] = useState(false)

  const {collaboratorOwner, collaboratorMember} = getCollaboratorMember(
    data.collaborator_members,
  )

  const isSelf = useMemo(() => collaboratorOwner.member_id === id, [
    collaboratorOwner,
    id,
  ])

  const handleNavigateProfile = useCallback(() => {
    if (!isSelf && childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    history.push(
      'giftshop_profile',
      {
        self: isSelf,
        postData: {
          member_id: collaboratorOwner.member_id,
          username: collaboratorOwner.username,
          photo: collaboratorOwner.photo,
        },
      },
      collaboratorOwner.username,
    )
  }, [childStatus, collaboratorOwner, history, isSelf])

  const handleNavigateProfileCollaborator = useCallback(() => {
    if (!collaboratorMember) return
    if (
      username !== collaboratorMember.username &&
      childStatus === 'UNDERAGE'
    ) {
      showModalLimitedAccess()
      return
    }
    history.push(
      'giftshop_profile',
      {
        self: collaboratorMember.username === username,
        postData: {
          member_id: collaboratorMember.member_id,
          username: collaboratorMember.username,
          photo: collaboratorMember.photo,
        },
      },
      collaboratorMember.username,
    )
  }, [childStatus, collaboratorMember, history, username])

  const handleHideButton = useCallback(() => {
    const now = new Date().getTime()
    const dif = now - timestamp.current

    if (dif >= toggleTimeout) {
      setShowButton(false)
    }
  }, [toggleTimeout])

  const handleToggleVideo = useCallback(() => {
    timestamp.current = new Date().getTime()

    setVideoPause((previous) => !previous)
    setShowButton(true)

    setTimeout(handleHideButton, toggleTimeout)
  }, [toggleTimeout, setVideoPause, handleHideButton])

  const handleVideoTimeUpdate = useCallback<
    React.ReactEventHandler<HTMLVideoElement>
  >((event) => {
    const {currentTime, duration} = event.currentTarget
    const extra = (currentTime / duration) * 1

    setProgress(Math.min(100, (currentTime + extra) / duration) * 100)
  }, [])

  const handleBlur = useCallback(() => {
    videoRef.current && videoRef.current.pause()
  }, [])

  const handleFocus = useCallback(() => {
    videoRef.current && videoRef.current.play()
  }, [])

  const handleFocusEvent = useCallback(() => {
    if (data.content_type === 'video' && videoRef.current && active) {
      window.addEventListener('blur', handleBlur)
      window.addEventListener('focus', handleFocus)

      return () => {
        window.removeEventListener('blur', handleBlur)
        window.removeEventListener('focus', handleFocus)
      }
    }

    return undefined
  }, [active, data.content_type, handleBlur, handleFocus])

  const handleClickMore = useCallback(() => {
    if (!access_token) {
      setLoginModal(true)
      return
    }
    onClickMore()
  }, [access_token, onClickMore, setLoginModal])

  const handleFollow = useCallback(
    (memberId: string) => {
      if (!access_token) {
        setLoginModal(true)
        return
      }
      onFollowed(memberId)
    },
    [access_token, onFollowed, setLoginModal],
  )

  const handleRenderRightButtons = useMemo(
    () => (
      <ForkygramTemplatePostButtons
        postId={data.id}
        ownerId={collaboratorOwner.member_id}
        member_id={collaboratorOwner.member_id}
        photo={collaboratorOwner.photo}
        username={collaboratorOwner.username}
        creator_status={collaboratorOwner.creator_status}
        data={data}
        isFeed={isFeed}
        mute={mute}
        handleShowModal={() => setLoginModal(true)}
        onPressMute={() => setMute((prev) => !prev)}
        onClickMore={handleClickMore}
        allowMusic
      />
    ),
    [
      collaboratorOwner,
      data,
      handleClickMore,
      isFeed,
      mute,
      setLoginModal,
      setMute,
    ],
  )

  const handleRenderProfileCaption = useMemo(
    () => (
      <StyledProfileContainer>
        <ForkygramTemplatePostProfile
          stateFollowButton={stateFollowButton}
          collabOwnerData={collaboratorOwner}
          collabMemberData={collaboratorMember}
          memberId={collaboratorOwner.member_id}
          onClickAvatar={handleNavigateProfile}
          onClickCollabMember={handleNavigateProfileCollaborator}
          onFollowed={handleFollow}
        />
        <ForkygramTemplatePostCaptionMobile data={data} />
      </StyledProfileContainer>
    ),
    [
      collaboratorMember,
      collaboratorOwner,
      data,
      handleFollow,
      handleNavigateProfile,
      handleNavigateProfileCollaborator,
      stateFollowButton,
    ],
  )

  const handleRenderValidateKycModal = useMemo(
    () => (
      <ValidateKYCModal
        toggleModal={() => setValKycModal((prev) => !prev)}
        visible={valKycModal}
      />
    ),
    [valKycModal],
  )

  const handleRenderModalLogin = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal, setLoginModal],
  )

  const handleRenderBackIcon = useMemo(
    () =>
      !isFeed && (
        <StyledIconContainer>
          <Icon
            type="back"
            size={convertUnit(24)}
            color="white_1"
            onClick={() => history.goBack()}
          />
        </StyledIconContainer>
      ),
    [history, isFeed],
  )

  const handleRenderOverlay = useMemo(
    () => (
      <StyledOverlay
        height={mode === 'website' ? height - 5 : height - 69}
        onClick={() => {
          data.content_type === 'video' && handleToggleVideo()
        }}
      />
    ),
    [data.content_type, handleToggleVideo, height, mode],
  )

  const handleRenderContent = useMemo(
    () =>
      data.content_type === 'photo' ? (
        <StyledContentImage
          src={data.url}
          alt=""
          height={mode === 'website' ? height - 5 : height - 69}
        />
      ) : (
        <>
          <StyledVideo
            loop
            id={data.id}
            src={data.url}
            height={mode === 'website' ? height - 5 : height - 69}
            playerRef={videoRef}
            muted={mute}
            onTimeUpdate={handleVideoTimeUpdate}
          />
          <ForkygramTemplatePostAudioPlayPause
            showButton={showButton}
            pause={videoPause}
          />
        </>
      ),
    [
      data.content_type,
      data.url,
      data.id,
      mode,
      height,
      mute,
      handleVideoTimeUpdate,
      showButton,
      videoPause,
    ],
  )

  const handleRenderProgressBar = useMemo(
    () => <ForkygramTemplatePostProgressBar progress={progress} />,
    [progress],
  )

  useEffect(handleFocusEvent, [handleFocusEvent])

  useEffect(() => {
    if (!videoRef.current) {
      if (setVideoPlaying) setVideoPlaying(false)
    } else if (videoRef.current) {
      if (active) {
        if (data.content_type === 'video') {
          setTimeout(() => {
            if (setVideoPlaying) setVideoPlaying(true)
          }, 100)
        }
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }, [active, focused, setVideoPlaying, data.content_type])

  useEffect(() => {
    if (videoRef.current && !focused && setVideoPlaying) {
      videoRef.current.currentTime = 0
    }
  }, [focused, data.content_type, setVideoPlaying])

  return (
    <StyledContainer>
      <StyledRelative width={width}>
        <StyledContentContainer
          height={
            mode === 'website'
              ? height
              : height - GIFT_SHOP_ROOT_BOTTOM_NAVBAR_HEIGHT
          }>
          {handleRenderValidateKycModal}
          {handleRenderBackIcon}
          {handleRenderContent}
          {handleRenderOverlay}
          {handleRenderProfileCaption}
          {handleRenderRightButtons}
          {handleRenderModalLogin}
          {handleRenderProgressBar}
        </StyledContentContainer>
      </StyledRelative>
    </StyledContainer>
  )
}
