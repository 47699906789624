import {lazy} from 'react'

const AuthRecoverAccountCreatePasswordScreen = lazy(
  () => import('./AuthRecoverAccountCreatePasswordScreen'),
)
const AuthRecoverAccountLinkVerifScreen = lazy(
  () => import('./AuthRecoverAccountLinkVerifScreen'),
)
const AuthRecoverAccountScreen = lazy(
  () => import('./AuthRecoverAccountScreen'),
)
const AuthRecoverAccountSuccessScreen = lazy(
  () => import('./AuthRecoverAccountSuccessScreen'),
)

export * from './AuthRecoverAccountScreenParam'
export {
  AuthRecoverAccountCreatePasswordScreen,
  AuthRecoverAccountLinkVerifScreen,
  AuthRecoverAccountScreen,
  AuthRecoverAccountSuccessScreen,
}
