import React, {useCallback, useMemo, useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {useTheme} from 'themes'
import {
  FirebaseMessageRoomItemPayload,
  ForkygramCollaboratorRequestStatus,
  ForkygramCollaboratorStatus,
  parseShape,
} from 'types'
import {getFontFamily, showSnackbar, useDidMount, useHistory} from 'utils'
import {
  Button,
  ButtonOutline,
  ConfirmationModal,
  Image,
  Paragraph,
  ParsedText,
} from 'common/components'
import convertUnit from 'lib/unit'

interface StyledResponseProps {
  status?: ForkygramCollaboratorStatus
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledMessageContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledImageContainer = styled.div`
  margin-left: ${convertUnit(16)};
  cursor: pointer;
`

const StyledImage = styled(Image)`
  width: ${convertUnit(56)};
  height: ${convertUnit(56)};
  border-radius: ${convertUnit(4)};
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
  margin-top: ${convertUnit(8)};
`

const StyledResponse = styled.span<StyledResponseProps>`
  color: ${({theme, status}) =>
    status === 'CONFIRMED' ? theme.success_5 : theme.danger_5};
`

export interface GiftShopMessageRoomContentItemNotificationCollaboratorProps {
  payload: FirebaseMessageRoomItemPayload[
    | 'notification_collaborator'
    | 'notification_collaborator_request']
  isInvitation?: boolean
}

export default function GiftShopMessageRoomContentItemNotificationCollaborator({
  payload: {post_id, post_image_url, requester_member_id, requester_username},
  isInvitation,
}: GiftShopMessageRoomContentItemNotificationCollaboratorProps) {
  const history = useHistory()
  const theme = useTheme()
  const {translate} = useTranslation()
  const [modal, setModal] = useState(false)
  const [
    selectedStatus,
    setSelectedStatus,
  ] = useState<ForkygramCollaboratorRequestStatus>()
  const [
    collabStatus,
    setCollabStatus,
  ] = useState<ForkygramCollaboratorStatus>()

  const handleLoadStatus = useCallback(() => {
    if (!isInvitation) return
    requestData('forkygram_get_collaborator_status', {
      params: {post_id},
      useDefaultMessage: true,
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          setCollabStatus(result.status)
        }
      },
    })
  }, [isInvitation, post_id])

  const handleInvitation = useCallback(() => {
    if (!selectedStatus) return
    requestData('forkygram_patch_collaborator_status', {
      data: {
        post_id,
        status: selectedStatus,
      },
      useDefaultMessage: true,
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          showSnackbar(
            translate(
              selectedStatus === 'CONFIRMED'
                ? 'messages:collabInvitationAccepted'
                : 'messages:collabInvitationRejected',
            ),
          )
          setCollabStatus(selectedStatus)
        }
      },
    })
  }, [post_id, selectedStatus, translate])

  const handleNavigateProfile = useCallback(() => {
    history.push(
      'giftshop_profile',
      {
        self: false,
        postData: {
          member_id: requester_member_id,
          username: requester_username,
        },
      },
      requester_username,
    )
  }, [history, requester_member_id, requester_username])

  const patterns: parseShape[] = useMemo(
    () => [
      {
        pattern: /<username>.*?<\/username>/,
        style: {fontFamily: getFontFamily('bold'), cursor: 'pointer'},
        renderText: (val) => val.replace(/<username>(.*?)<\/username>/g, '@$1'),
        onClick: handleNavigateProfile,
      },
    ],
    [handleNavigateProfile],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledImageContainer
        onClick={() => {
          history.pushQuery({
            path: 'giftshop_profile_post_detail',
            state: {
              data: [],
              self: false,
              selectedItemId: post_id,
              loadSpecificData: true,
            },
            queryObject: {id: post_id},
            recursivePath: requester_username,
          })
        }}>
        <StyledImage
          src={post_image_url}
          alt={`${requester_username}'s post`}
        />
      </StyledImageContainer>
    ),
    [history, post_id, post_image_url, requester_username],
  )

  const handleRenderMessage = useMemo(
    () => (
      <StyledMessageContainer>
        <Paragraph fontSize="m">
          <ParsedText parse={patterns}>
            {translate(
              isInvitation
                ? 'messages:notificationCollaborationInvitation'
                : 'messages:notificationCollaboration',
              {
                username: requester_username,
              },
            )}
          </ParsedText>
        </Paragraph>
        {handleRenderContent}
      </StyledMessageContainer>
    ),
    [
      handleRenderContent,
      isInvitation,
      patterns,
      requester_username,
      translate,
    ],
  )

  const handleRenderExtra = useMemo(
    () =>
      collabStatus === undefined ? (
        <Skeleton
          count={2}
          height={40}
          borderRadius={20}
          baseColor={theme.gray_1}
          highlightColor={theme.white_4}
          style={{marginTop: convertUnit(8)}}
        />
      ) : collabStatus === 'WAITING_CONFIRMATION' ? (
        <StyledButtonContainer>
          <Button
            label={translate('global:accept')}
            onClick={() => {
              setModal(true)
              setSelectedStatus('CONFIRMED')
            }}
          />
          <ButtonOutline
            color="gray_5"
            label={translate('global:reject')}
            onClick={() => {
              setModal(true)
              setSelectedStatus('REJECTED')
            }}
          />
        </StyledButtonContainer>
      ) : (
        <Paragraph>
          {translate('global:yourResponse')}:{' '}
          <StyledResponse status={collabStatus}>
            {translate(
              collabStatus === 'CONFIRMED'
                ? 'global:accepted'
                : 'global:rejected',
            )}
          </StyledResponse>
        </Paragraph>
      ),
    [collabStatus, theme.gray_1, theme.white_4, translate],
  )

  const handleRenderModal = useMemo(
    () => (
      <ConfirmationModal
        visible={modal}
        title={translate(
          selectedStatus === 'CONFIRMED'
            ? 'messages:collabInvitationTitle'
            : 'messages:collabInvitationRejectTitle',
        )}
        text={
          selectedStatus === 'CONFIRMED'
            ? translate('messages:collabInvitationInfo')
            : ''
        }
        confirmText={translate(
          selectedStatus === 'CONFIRMED' ? 'global:accept' : 'global:reject',
        )}
        cancelText={translate('global:cancel')}
        onConfirm={handleInvitation}
        toggleModal={() => setModal(false)}
      />
    ),
    [handleInvitation, modal, selectedStatus, translate],
  )

  useDidMount(handleLoadStatus)

  return (
    <StyledContainer>
      {handleRenderModal}
      {handleRenderMessage}
      {isInvitation && handleRenderExtra}
    </StyledContainer>
  )
}
