import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {FirebaseMessageRoomItemPayload, ForkygramPostData} from 'types'
import {getFontFamily, useHistory} from 'utils'
import {Paragraph, Image, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'

export interface GiftShopMessageRoomContentItemNotificationLikeProps {
  payload: FirebaseMessageRoomItemPayload['notification_like']
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledImageContainer = styled.div`
  margin-left: ${convertUnit(16)};
  cursor: pointer;
`

const StyledImage = styled(Image)`
  width: ${convertUnit(56)};
  height: ${convertUnit(56)};
`

export default function GiftShopMessageRoomContentItemNotificationLike({
  payload: {username, item, like_member_id},
}: GiftShopMessageRoomContentItemNotificationLikeProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const stateData = useState<ReadonlyArray<ForkygramPostData>>([])
  const data = stateData[0]

  const handleNavigateProfile = useCallback(() => {
    history.push(
      'giftshop_profile',
      {
        self: false,
        postData: {
          member_id: like_member_id,
          username,
        },
      },
      username,
    )
  }, [history, like_member_id, username])

  const handleRenderMessage = useMemo(
    () => (
      <Paragraph fontSize="m">
        <ParsedText
          parse={[
            {
              pattern: /<bold>.*?<\/bold>/,
              style: {
                fontFamily: getFontFamily('bold'),
                cursor: 'pointer',
              },
              renderText: (text) => text.replace(/<bold>|<\/bold>/g, ''),
              onClick: handleNavigateProfile,
            },
          ]}>
          {translate('messages:notificationLike', {
            username,
          })}
        </ParsedText>
      </Paragraph>
    ),
    [handleNavigateProfile, translate, username],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledImageContainer
        onClick={() => {
          history.pushQuery({
            path: 'giftshop_profile_post_detail',
            state: {
              data,
              self: true,
              selectedItemId: item.id,
              memberId: item.member_id,
              loadSpecificData: true,
            },
            queryObject: {id: item.id},
            recursivePath: username,
          })
        }}>
        <StyledImage src={item.url} alt="image" />
      </StyledImageContainer>
    ),
    [data, history, item, username],
  )

  return (
    <StyledContainer>
      {handleRenderMessage}
      {handleRenderContent}
    </StyledContainer>
  )
}
