import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateOptionsContentProps} from './GiftShopTemplateOptionsProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(12)};
  padding: ${convertUnit(20)};
  text-align: center;
  justify-content: center;
  box-sizing: border-box;
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopTemplateOptionsContent({
  children,
  visible,
  toggleModal,
}: GiftShopTemplateOptionsContentProps) {
  const {translate} = useTranslation()
  return (
    <StyledModal visible={visible}>
      <Paragraph fontSize="l" fontWeight="bold">
        {translate('global:options')}
      </Paragraph>
      {children}
      <StyledButton
        label={translate('global:close')}
        color="primary_5"
        backgroundColor="white_3"
        backgroundHoverColor="white_5"
        onClick={toggleModal}
      />
    </StyledModal>
  )
}
