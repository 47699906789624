import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {UseFormMethods} from 'react-hook-form'
import {useParams} from 'react-router'
import {useTranslation} from 'i18n'
import {GiftShopRootScreen} from 'pages'
import {ForkygramUploadData, UserInfoBase} from 'types'
import {useLocation, useForkygramUpload, showPrompt, useHistory} from 'utils'
import {ModalLoading} from 'common/components'
import {
  TreeFototreeUploadCollaborator,
  TreeFototreeUploadForm,
} from '../containers'

export default function TreeFototreeUploadScreen() {
  const history = useHistory()
  const {translate} = useTranslation()
  const {alias}: {alias: string} = useParams()
  const {treeId, tagId, file} = useLocation('tree_fototree_upload').state
  const form = useRef<UseFormMethods<ForkygramUploadData>>()
  const stateSource = useState<string>()
  const stateLoading = useState(false)
  const stateCollaboratorData = useState<UserInfoBase>()
  const stateCollaboratorOverlay = useState(false)
  const loading = stateLoading[0]
  const src = stateSource[0]
  const setCollaborator = stateCollaboratorData[1]
  const [collaboratorOverlay, setCollaboratorOverlay] = stateCollaboratorOverlay

  const {publish} = useForkygramUpload({
    stateLoading,
    stateSource,
    form,
    data: {file, treeId, treeAlias: alias},
  })

  const handleBackButtonClicked = useCallback(() => {
    if (collaboratorOverlay) {
      setCollaboratorOverlay(false)
      return
    }
    history.goBack()
  }, [collaboratorOverlay, history, setCollaboratorOverlay])

  const handleAddCollaborator = useCallback(
    (member: UserInfoBase) => {
      setCollaboratorOverlay(false)
      setCollaborator(member)
      form.current?.setValue('collaborator_member_ids', [member.id])
    },
    [setCollaborator, setCollaboratorOverlay],
  )

  const handleRenderContent = useMemo(
    () => (
      <TreeFototreeUploadForm
        stateCollaboratorData={stateCollaboratorData}
        src={src}
        tag={{id: tagId, name: '', tree_id: treeId, alias: ''}}
        onAddCollaborator={() => setCollaboratorOverlay(true)}
        onFormUpdate={(item) => {
          form.current = item
        }}
        onSubmitForm={publish}
      />
    ),
    [
      stateCollaboratorData,
      src,
      tagId,
      treeId,
      publish,
      setCollaboratorOverlay,
    ],
  )

  const handleRenderSearchCollaborator = useMemo(
    () =>
      collaboratorOverlay && (
        <TreeFototreeUploadCollaborator onSelected={handleAddCollaborator} />
      ),
    [collaboratorOverlay, handleAddCollaborator],
  )

  useEffect(() => {
    showPrompt(src !== undefined, translate('giftShop:uploadModalMessage'))
  }, [src, translate])

  return (
    <GiftShopRootScreen
      showBadgeIcon={false}
      headerTitle={
        collaboratorOverlay
          ? translate('tree:addCollaborator')
          : translate('tree:leafAMemoryHeader')
      }
      onBackButtonClicked={handleBackButtonClicked}>
      <ModalLoading visible={loading} />
      {handleRenderContent}
      {handleRenderSearchCollaborator}
    </GiftShopRootScreen>
  )
}
