import React, {useCallback, useMemo} from 'react'
import {GiftShopContentData} from 'types'
import {ListNotLazy} from 'common/components'
import GiftShopTemplatePagination from './GiftShopTemplatePagination'
import {GiftShopTemplateListPaginationProps} from './GiftShopTemplateListPaginationProps'

export default function GiftShopTemplateListPagination({
  renderItem,
  page,
  defaultPage,
  dataCount = 0,
  setPage,
  movePage,
  loadData,
  stateData,
  ...rest
}: GiftShopTemplateListPaginationProps) {
  const handleKeyExtractor = useCallback(
    (item: GiftShopContentData) => item.content_id + item.quality,
    [],
  )

  const handleRenderMasonry = useMemo(
    () => (
      <ListNotLazy
        {...rest}
        stateData={stateData}
        keyExtractor={handleKeyExtractor}
        loadData={loadData}
        page={page}
        defaultPage={page}
        renderItem={renderItem}
      />
    ),
    [rest, stateData, handleKeyExtractor, loadData, page, renderItem],
  )

  return (
    <GiftShopTemplatePagination
      {...rest}
      movePage={movePage}
      loadData={loadData}
      page={page}
      setPage={setPage}
      dataCount={dataCount}
      defaultPage={defaultPage}
      renderMain={() => handleRenderMasonry}
    />
  )
}
