import React, {useCallback, useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  IMAGE_ASSET,
  TREE_MAX_STORY_CHARACTER,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {ForkygramUploadData, WindowModeType} from 'types'
import {getBorder, getFontFamily, getFontSize, useDidMount} from 'utils'
import {useWindowMode} from 'windows'
import {
  Avatar,
  Button,
  Icon,
  IconVerifiedBadge,
  Image,
  InputArea,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {TreeFototreeUploadFormProps} from './TreeFototreeUploadProps'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex: 1;
  ${({mode}) => ({
    flexDirection: mode === 'website' ? 'row' : 'column',
    padding: mode === 'website' ? `${convertUnit(40)}` : `${convertUnit(20)}`,
    gap: mode === 'website' ? `${convertUnit(40)}` : `${convertUnit(20)}`,
  })}
  overflow-y: auto;

  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${convertUnit(20)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_3};
    border-radius: ${convertUnit(16)};
  }
`

const StyledRightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${convertUnit(20)};
`

const StyledContentContainer = styled.div<StyledContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${({mode}) => (mode === 'website' ? convertUnit(527) : '100%')};
  max-height: ${({mode}) => (mode === 'website' ? convertUnit(527) : '100%')};
  background-color: ${({theme}) => theme.gray_1};
`

const StyledImage = styled(Image)`
  object-fit: scale-down;
  width: 100%;
  height: 100%;
  max-width: ${convertUnit(527)};
  max-height: ${convertUnit(527)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-height: ${convertUnit(389)};
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    max-height: ${convertUnit(335)};
  }
`

const StyledVideo = styled.video`
  object-fit: scale-down;
  width: 100%;
  height: 100%;
  max-width: ${convertUnit(527)};
  max-height: ${convertUnit(527)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-height: ${convertUnit(389)};
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    max-height: ${convertUnit(335)};
  }
`

const StyledInputArea = styled(InputArea)`
  resize: none;
  scrollbar-width: auto;
  scrollbar-color: ${({theme}) => `${theme.primary_5} ${theme.white_3}`};

  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${convertUnit(20)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_3};
    border-radius: ${convertUnit(16)};
  }
`

const StyledList = styled.ul`
  padding-left: ${convertUnit(20)};
  margin-top: ${convertUnit(2)};
`

const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('xs')};
  line-height: ${convertUnit(17)};
  padding-left: ${convertUnit(4)};
`

const StyledParagraph = styled(Paragraph)`
  white-space: nowrap;
  text-overflow: ellipsis;
`

const StyledCollabUsernameContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-left: ${convertUnit(12)};
  gap: ${convertUnit(4)};
`

const StyledCollabContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${convertUnit(12)};
  padding-right: unset;
  border: ${({theme}) => getBorder(1.5, 'solid', theme.primary_5)};
  border-radius: ${convertUnit(8)};
`

export default function TreeFotoreeUploadForm({
  stateCollaboratorData,
  src,
  tag,
  onAddCollaborator,
  onFormUpdate,
  onSubmitForm,
}: TreeFototreeUploadFormProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const defaultImg = IMAGE_ASSET('giftshop', 'broken-image-explore.png')
  const form = useForm<ForkygramUploadData>({
    defaultValues: {
      caption: '',
      tag,
      height: 0,
      size: 0,
      width: 0,
    },
  })
  const {register, setValue, watch} = form
  const values = watch()
  const [collaborator, setCollaborator] = stateCollaboratorData

  const handleRemoveCollaborator = useCallback(() => {
    setCollaborator(undefined)
    setValue('collaborator_member_ids', undefined)
  }, [setCollaborator, setValue])

  const handleRenderInputArea = useMemo(
    () => (
      <StyledInputArea
        placeholder={translate('tree:tellYourStory')}
        value={values.caption}
        autoComplete={false}
        inputContainerStyle={{height: convertUnit(125)}}
        labelDescription={translate('global:charCount', {
          count: values.caption.length,
          max: TREE_MAX_STORY_CHARACTER,
        })}
        onChangeText={(text) => setValue('caption', text)}
        maxLength={TREE_MAX_STORY_CHARACTER}
      />
    ),
    [setValue, translate, values.caption],
  )

  const handleRenderAddCollaborator = useMemo(
    () => (
      <div>
        {collaborator ? (
          <StyledCollabContainer>
            <div>
              <Avatar alt="Avatar" src={collaborator.photo} />
            </div>
            <StyledCollabUsernameContainer>
              <StyledParagraph fontSize="m" fontWeight="medium">
                @{collaborator.username}
              </StyledParagraph>
              {collaborator.creator_status === 'verified' && (
                <IconVerifiedBadge />
              )}
            </StyledCollabUsernameContainer>
            <Icon
              type="close"
              color="gray_5"
              size={convertUnit(16)}
              style={{
                marginLeft: 'auto',
                cursor: 'pointer',
                padding: convertUnit(12),
              }}
              onClick={handleRemoveCollaborator}
            />
          </StyledCollabContainer>
        ) : (
          <Button
            color="primary_5"
            backgroundColor="white_3"
            backgroundHoverColor="white_5"
            label={translate('tree:addCollaborator')}
            leftIcon={
              <Icon
                type="collab-leaf"
                color="primary_5"
                size={convertUnit(16)}
                marginRight={convertUnit(8)}
              />
            }
            onClick={onAddCollaborator}
          />
        )}
        <StyledList>
          {[...Array(2)].map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('tree:addCollaborator', {context: `${index + 1}`})}
            </StyledListItem>
          ))}
        </StyledList>
      </div>
    ),
    [collaborator, handleRemoveCollaborator, onAddCollaborator, translate],
  )

  const handleRenderContent = useMemo(
    () =>
      src && (
        <StyledContainer mode={mode}>
          <StyledContentContainer mode={mode}>
            {src.includes('video') ? (
              <StyledVideo autoPlay={false}>
                <source src={src} type="video/mp4" />
                <source src={src} type="video/mov" />
                <source src={src} type="video/heiv" />
              </StyledVideo>
            ) : (
              <StyledImage src={src || defaultImg} alt="" />
            )}
          </StyledContentContainer>
          <StyledRightContainer>
            {handleRenderInputArea}
            {handleRenderAddCollaborator}
            <Button
              onClick={() => onSubmitForm()}
              label={translate('tree:postMemory')}
            />
          </StyledRightContainer>
        </StyledContainer>
      ),
    [
      defaultImg,
      handleRenderAddCollaborator,
      handleRenderInputArea,
      mode,
      onSubmitForm,
      src,
      translate,
    ],
  )

  useDidMount(() => {
    register('extension')
    register('mime')
    register('file')
    register('caption')
    register('collaborator_member_ids')
    register('height')
    register('size')
    register('width')
    register('tag')
    register('duration')
  })

  useEffect(() => {
    onFormUpdate && onFormUpdate(form)
  }, [form, onFormUpdate])

  return <>{handleRenderContent}</>
}
