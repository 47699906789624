import {useCallback, useEffect, useMemo} from 'react'
import firebase from 'lib/firebase'
import {
  FirebaseMessageList,
  FirebaseMessageListItem,
  FirebaseMessageListParam,
  FirebaseMessageRoom,
  FirebaseMessageRoomItem,
  FirebaseMessageRoomItemBase,
  FirebaseMessageRoomItemPayload,
  FirebaseMessageRoomMember,
  FirebaseMessageRoomMemberItem,
  FirebaseMessageRoomMemberParam,
  FirebaseMessageRoomParam,
  FirebaseMessageSystem,
  FirebaseMessageSystemItem,
  FirebaseMessageUser,
  FirebaseMessageUserItem,
  MessageRoomState,
} from 'types'
import {
  calcTimeDiff,
  convertFileSizeFromByte,
  getCurrencyValue,
  parseComment,
  parseDate,
  parseUsername,
  useDidMount,
  useMapRef,
} from 'utils'
import {MESSAGE_ENV_KEY} from 'config/messages'
import {useTranslation} from 'i18n'
import {useSelector} from 'lib/redux'

export function useFirebaseMessageList({
  userId,
  filterDeletedAt = true,
}: {
  userId: string
  filterDeletedAt?: boolean
}) {
  const {map, updateMap, setMapValue} = useMapRef<FirebaseMessageList>({
    data: [],
    initialize: false,
  })

  const handleFirebase = useCallback(
    () =>
      userId
        ? firebase
            .firestore()
            .collection(process.env.FIREBASE_COLLECTION_MESSAGE_LIST!)
            .doc(userId)
            .collection('rooms')
            .orderBy('last_update' as keyof FirebaseMessageListItem, 'desc')
            .onSnapshot((snapshot) => {
              const data = snapshot.docs
                .map((doc) => doc.data() as FirebaseMessageListItem)
                .filter(
                  ({id, room_id, room_type, last_update}) =>
                    !!id && !!room_id && !!room_type && !!last_update,
                )
                .filter(({deleted_at, last_update}) =>
                  filterDeletedAt && deleted_at
                    ? last_update > deleted_at
                    : true,
                )
              setMapValue({data, initialize: true})
              updateMap()
            })
        : undefined,
    [filterDeletedAt, setMapValue, updateMap, userId],
  )

  useDidMount(handleFirebase)

  return useMemo<FirebaseMessageList>(() => map.current, [map])
}

export function useCheckLastDelete(roomId: string) {
  const user = useSelector('user')
  const {data, initialize} = useFirebaseMessageList({
    userId: user?.id || '',
    filterDeletedAt: false,
  })

  const roomLastDelete = useMemo(
    () => data.find((message) => message.room_id === roomId)?.last_deleted_at,
    [data, roomId],
  )

  return useMemo(() => ({roomLastDelete, initialize}), [
    roomLastDelete,
    initialize,
  ])
}

export function useFirebaseMessageRoomMember({
  roomType,
  roomId,
}: FirebaseMessageRoomMemberParam) {
  const {map, updateMap, setMapValue} = useMapRef<FirebaseMessageRoomMember>({
    members: [],
  })
  const handleFirebase = useCallback(
    () =>
      firebase
        .firestore()
        .collection(MESSAGE_ENV_KEY[roomType])
        .doc(roomId)
        .collection('members')
        .onSnapshot((snapshot) => {
          const documents = snapshot.docs
            .map((doc) => doc.data() as FirebaseMessageRoomMemberItem)
            .filter(({id, role}) => !!id && !!role)

          setMapValue({members: documents})
          updateMap()
        }),
    [roomId, roomType, setMapValue, updateMap],
  )
  useEffect(handleFirebase, [handleFirebase])

  return useMemo<FirebaseMessageRoomMember>(() => map.current, [map])
}

export function useFirebaseSystem({id}: FirebaseMessageListParam) {
  const {map, setMapValue, updateMap} = useMapRef<FirebaseMessageSystem>({
    initialize: false,
  })
  const handleFirebase = useCallback(
    () =>
      firebase
        .firestore()
        .collection(process.env.FIREBASE_COLLECTION_SYSTEM!)
        .doc(id)
        .onSnapshot((snapshot) => {
          const data = snapshot.data() as FirebaseMessageSystemItem
          if (!!data.id && !!data.photo && !!data.system) {
            setMapValue({initialize: true, data})
          } else {
            setMapValue({initialize: true})
          }
          updateMap()
        }),
    [id, setMapValue, updateMap],
  )
  useEffect(handleFirebase, [handleFirebase])
  return useMemo<FirebaseMessageSystem>(() => map.current, [map])
}

export function useFirebaseUser({id}: FirebaseMessageListParam) {
  const {map, setMapValue, updateMap} = useMapRef<FirebaseMessageUser>({
    initialize: true,
  })
  const handleFirebase = useCallback(() => {
    firebase
      .firestore()
      .collection(process.env.FIREBASE_COLLECTION_USER!)
      .doc(id)
      .onSnapshot((snapshot) => {
        const data = snapshot.data() as FirebaseMessageUserItem
        if (!!data.photo && !!data.username) {
          setMapValue({data, initialize: true})
        } else {
          setMapValue({initialize: true})
        }
        updateMap()
      })
  }, [id, setMapValue, updateMap])
  useEffect(handleFirebase, [handleFirebase])
  return useMemo<FirebaseMessageUser>(() => map.current, [map])
}

export function useFirebaseMessageRoom({
  roomId,
  roomType,
  limit,
  sort = 'asc',
}: FirebaseMessageRoomParam) {
  const {map, setMapValue, updateMap} = useMapRef<FirebaseMessageRoom>({
    messages: [],
    senders: new Set(),
    initialize: false,
  })

  const handleFirebase = useCallback(() => {
    const query = firebase
      .firestore()
      .collection(MESSAGE_ENV_KEY[roomType])
      .doc(roomId)
      .collection('messages')
      .orderBy('created_at' as keyof FirebaseMessageRoomItemBase, sort)

    return limit
      ? query.limit(limit).onSnapshot((snapshot) => {
          const documents = snapshot.docs
            .map((doc) => doc.data() as FirebaseMessageRoomItem)
            .filter(
              ({id, created_at, type, sender, payload}) =>
                !!id && !!created_at && !!type && !!sender && !!payload,
            )

          setMapValue({
            messages: documents,
            initialize: true,
            senders: new Set(documents.map((message) => message.sender)),
          })
          updateMap()
        })
      : query.onSnapshot((snapshot) => {
          const documents = snapshot.docs
            .map((doc) => doc.data() as FirebaseMessageRoomItem)
            .filter(
              ({id, created_at, type, sender, payload}) =>
                !!id && !!created_at && !!type && !!sender && !!payload,
            )

          setMapValue({
            messages: documents,
            initialize: true,
            senders: new Set(documents.map((message) => message.sender)),
          })
          updateMap()
        })
  }, [roomType, roomId, sort, limit, setMapValue, updateMap])

  useEffect(handleFirebase, [handleFirebase])

  return useMemo<FirebaseMessageRoom>(() => map.current, [map])
}

export function useMessageContentDescription({
  message,
}: {
  message: FirebaseMessageRoomItem
}) {
  const {translate} = useTranslation()
  const {id: myId, username: myUsername} = useSelector('user')!
  const {type, payload, sender} = message

  const senderSelf = useMemo(() => sender === myId, [sender, myId])

  const description = useMemo(() => {
    if (type === 'chat') {
      return translate('messages:listRoomMessages', {
        context: senderSelf ? 'self' : undefined,
        message: (payload as FirebaseMessageRoomItemPayload['chat']).message,
      })
    }

    if (type === 'notification_accept_invitation_admin') {
      const {
        host_tag,
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_accept_invitation_admin']
      return (
        translate('messages:hiUsername', {username}) +
        translate('messages:notificationAdminInvitationAccepted', {host_tag})
      )
    }

    if (type === 'notification_accept_invitation_admin_host') {
      const {
        host_member_username,
        host_tag,
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_accept_invitation_admin_host']
      return (
        translate('messages:hiUsername', {username}) +
        translate('messages:notificationHostAdminInvitationAccepted', {
          host_member_username,
          host_tag,
        })
      )
    }

    if (type === 'notification_accept_invitation_creator') {
      const {
        host_tag,
        username,
        creator_type,
      } = payload as FirebaseMessageRoomItemPayload['notification_accept_invitation_creator']
      return (
        translate('messages:hiUsername', {username}) +
        translate(
          'messages:notificationCreatorOfficialCreatorInvitationAccepted',
          {
            host_tag,
            creator_type: translate('tree:hostCreatorType', {
              context: creator_type,
            }).toLowerCase(),
          },
        )
      )
    }

    if (type === 'notification_accept_invitation_creator_host') {
      const {
        host_member_username,
        host_tag,
        username,
        creator_type,
      } = payload as FirebaseMessageRoomItemPayload['notification_accept_invitation_creator_host']
      return (
        translate('messages:hiUsername', {username}) +
        translate(
          'messages:notificationHostOfficialCreatorInvitationAccepted',
          {
            host_member_username,
            host_tag,
            creator_type: translate('tree:hostCreatorType', {
              context: creator_type,
            }).toLowerCase(),
          },
        )
      )
    }

    if (type === 'notification_awaiting_payment') {
      const {
        username,
        total_payment,
        invoice_id,
        payment_type,
        payment_method,
        billing_number,
        transaction_expiration,
      } = payload as FirebaseMessageRoomItemPayload['notification_awaiting_payment']

      return translate('messages:notificationAwaitingPayment', {
        context: payment_type,
        username,
        total_payment: getCurrencyValue(total_payment),
        invoice_id,
        payment_method,
        billing_number,
        transaction_expiration: transaction_expiration
          ? parseDate(transaction_expiration, 'D MMMM YYYY, HH:mm:ss')
          : undefined,
      })
    }

    if (type === 'notification_creator_reviewed') {
      const {
        username,
        invoice_id,
      } = payload as FirebaseMessageRoomItemPayload['notification_creator_reviewed']
      return (
        translate('messages:notificationCreatorReviewedOpening', {
          username,
        }) +
        translate('messages:notificationCreatorReviewedInfoFirst', {invoice_id})
      )
    }

    if (type === 'notification_purchase_completed_review') {
      const {
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_purchase_completed_review']
      return (
        translate('messages:notificationPurchaseReviewOpening', {username}) +
        translate('messages:notificationPurchaseReviewInfo')
      )
    }

    if (type === 'notification_follow') {
      const {
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_follow']

      return translate('messages:notificationFollow', {
        username,
      })
    }

    if (type === 'notification_follow_group') {
      const {
        count,
      } = payload as FirebaseMessageRoomItemPayload['notification_follow_group']
      return translate('messages:notificationFollow', {
        username: `${count} ${translate('global:yuser')}`,
      })
    }

    if (type === 'notification_like') {
      const {
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_like']

      return translate('messages:notificationLike', {
        username,
      }).replace(/<bold>|<\/bold>/g, '')
    }

    if (type === 'notification_like_comment') {
      const {
        comment,
        member_params,
        liker_member_id,
      } = payload as FirebaseMessageRoomItemPayload['notification_like_comment']
      return translate('messages:notificationLikeComment', {
        username:
          member_params && liker_member_id
            ? parseUsername(member_params, liker_member_id)
            : '',
        comment:
          member_params && comment ? parseComment(member_params, comment) : '',
      })
    }

    if (type === 'notification_like_comment_group') {
      const {
        count,
      } = payload as FirebaseMessageRoomItemPayload['notification_like_comment_group']
      return translate('messages:notificationLikeComment', {
        username: `${count} ${translate('global:yuser')}`,
      })
    }

    if (type === 'notification_like_group') {
      const {
        count,
      } = payload as FirebaseMessageRoomItemPayload['notification_like_group']
      return translate('messages:notificationLike', {
        username: `${count} ${translate('global:yuser')}`,
      }).replace(/<bold>|<\/bold>/g, '')
    }

    if (type === 'notification_promotion_host_admin_cancel') {
      const {
        host_tag,
      } = payload as FirebaseMessageRoomItemPayload['notification_promotion_host_admin_cancel']
      return translate('messages:notificationPromotionHostCancel', {host_tag})
    }

    if (type === 'notification_promotion_host_creator_cancel') {
      const {
        host_tag,
      } = payload as FirebaseMessageRoomItemPayload['notification_promotion_host_creator_cancel']
      return translate('messages:notificationPromotionHostCancel', {host_tag})
    }

    if (type === 'notification_collaborator') {
      const {
        requester_username,
      } = payload as FirebaseMessageRoomItemPayload['notification_collaborator']
      return translate('messages:notificationCollaboration', {
        username: requester_username,
      }).replace(/<username>|<\/username>/g, '')
    }

    if (type === 'notification_collaborator_request') {
      const {
        requester_username,
      } = payload as FirebaseMessageRoomItemPayload['notification_collaborator_request']
      return translate('messages:notificationCollaborationInvitation', {
        username: requester_username,
      }).replace(/<username>|<\/username>/g, '')
    }

    if (type === 'notification_comment') {
      const {
        member_params,
        comment,
        commented_member_id,
      } = payload as FirebaseMessageRoomItemPayload['notification_comment']

      return translate('messages:notificationComment', {
        username: parseUsername(member_params, commented_member_id),
        comment,
      })
    }

    if (type === 'notification_comment_mention') {
      const {
        member_params,
        comment,
        commented_member_id,
      } = payload as FirebaseMessageRoomItemPayload['notification_comment_mention']

      return translate('messages:notificationCommentMention', {
        username: parseUsername(member_params, commented_member_id),
        comment: parseComment(member_params, comment),
      })
    }

    if (type === 'notification_reply_comment') {
      const {
        member_params,
        comment,
        commented_member_id,
      } = payload as FirebaseMessageRoomItemPayload['notification_reply_comment']

      return translate('messages:notificationReplyComment', {
        username: parseUsername(member_params, commented_member_id),
        comment: parseComment(member_params, comment),
      })
    }

    if (type === 'notification_withdraw') {
      const {
        withdraw_amount,
        withdraw_id,
        withdraw_date,
      } = payload as FirebaseMessageRoomItemPayload['notification_withdraw']

      return translate('messages:notificationWithdraw', {
        username: myUsername,
        withdraw_id,
        withdraw_amount,
        withdraw_date: parseDate(withdraw_date, 'D MMMM YYYY, HH:mm:ss'),
      })
    }

    if (type === 'notification_withdraw_cancelled') {
      const {
        withdraw_amount,
        withdraw_id,
        withdraw_date,
      } = payload as FirebaseMessageRoomItemPayload['notification_withdraw_cancelled']

      return translate('messages:notificationWithdrawCancelled', {
        username: myUsername,
        withdraw_id,
        withdraw_amount,
        withdraw_date: parseDate(withdraw_date, 'D MMMM YYYY, HH:mm:ss'),
      }).replace(/<bold>|<\/bold>/g, '')
    }

    if (type === 'notification_purchase') {
      return translate('messages:notificationPurchase', {
        username: myUsername,
      })
    }

    if (type === 'notification_purchase_creator') {
      const {
        collector_nickname,
      } = payload as FirebaseMessageRoomItemPayload['notification_purchase_creator']
      return translate('messages:notificationPurchaseCreator', {
        username: myUsername,
        collector_nickname,
      })
    }

    if (type === 'notification_refund_content') {
      const {
        creator_username,
      } = payload as FirebaseMessageRoomItemPayload['notification_refund_content']

      return translate('messages:notificationRefund', {
        creator_username,
      })
    }

    if (type === 'notification_refund_content_creator') {
      return (
        translate('messages:notificationRefundCreatorTitle') +
        translate('messages:notificationRefundCreatorSubtitle')
      )
    }

    if (type === 'notification_make_it_free_content') {
      const {
        creator_username,
      } = payload as FirebaseMessageRoomItemPayload['notification_make_it_free_content']

      return translate('messages:notificationMakeItFree', {
        creator_username,
      })
    }

    if (type === 'notification_non_public_fototree_planted') {
      const {
        tree_name,
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_non_public_fototree_planted']
      return translate('messages:notificationTreeNonPublic', {
        username,
        tree_name,
      })
        .replace(/<username>|<\/username>|<tree_name>|<\/tree_name>/g, '')
        .replace(/<username>|<\/username>|<tree_name>|<\/tree_name>/g, '')
    }

    if (type === 'notification_resend_approval') {
      const {
        username,
        creator_nickname,
      } = payload as FirebaseMessageRoomItemPayload['notification_resend_approval']

      return (
        translate('messages:hiUsername', {username}) +
        translate('messages:notificationResendApprovalFirst', {
          creator_nickname,
        })
      )
    }

    if (type === 'notification_resendpology') {
      const {
        username,
        creator_username,
        purchased_at,
      } = payload as FirebaseMessageRoomItemPayload['notification_resendpology']
      return (
        translate('messages:hiUsername', {username}) +
        translate('messages:notificationResendpologyFirst', {
          creator_username,
          purchased_at: parseDate(purchased_at, 'DD-MMM-YYYY, HH:mm:ss'),
        })
      )
    }

    if (type === 'notification_resendpology_creator') {
      const {
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_resendpology_creator']
      return (
        translate('messages:hiUsername', {username}) +
        translate('messages:notificationResendpologyCreatorFirst')
      )
    }

    if (type === 'notification_storage_addition') {
      const {
        collector_nickname,
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_storage_addition']
      return translate('messages:notificationStorageAddition', {
        username,
        collector_nickname,
      })
    }

    if (type === 'notification_tree_approved') {
      const {
        tree_name,
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_tree_approved']
      return translate('messages:notificationTreeApproved', {
        username,
        tree_name,
      })
        .replace(/<username>|<\/username>|<tree_name>|<\/tree_name>/g, '')
        .replace(/<username>|<\/username>|<tree_name>|<\/tree_name>/g, '')
    }

    if (type === 'notification_tree_removed') {
      const {
        tree_name,
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_tree_removed']
      return translate('messages:notificationTreeRemoved', {
        username,
        tree_name,
      })
        .replace(/<username>|<\/username>|<tree_name>|<\/tree_name>/g, '')
        .replace(/<username>|<\/username>|<tree_name>|<\/tree_name>/g, '')
    }

    if (type === 'notification_creator_storage_addition') {
      const {
        username,
        storage_addition_amount,
      } = payload as FirebaseMessageRoomItemPayload['notification_creator_storage_addition']

      return translate('messages:notificationStorageRequestSuccess', {
        username,
        storage_addition_amount: convertFileSizeFromByte(
          storage_addition_amount,
          'GB',
        ).toFixed(0),
      })
    }

    if (type === 'notification_demotion_host_admin') {
      const {
        host_tag,
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_demotion_host_admin']
      return translate('messages:hostAdminDemotion', {
        username,
        host_tag,
      })
    }

    if (type === 'notification_demotion_host_creator') {
      const {
        host_tag,
        username,
        creator_type,
      } = payload as FirebaseMessageRoomItemPayload['notification_demotion_host_creator']
      return translate('messages:hostCreatorDemotion', {
        username,
        host_tag,
        creator_type: translate('tree:hostCreatorType', {
          context: creator_type,
        }).toLowerCase(),
      })
    }

    if (type === 'notification_promotion_host_admin') {
      const {
        host_tag,
        host_leader_username,
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_promotion_host_admin']
      return (
        translate('messages:hiUsername', {username}) +
        translate('messages:notificationAdminInvitation', {
          host_leader_username,
          host_tag,
        })
      )
    }

    if (type === 'notification_promotion_host_creator') {
      const {
        host_tag,
        host_leader_username,
        username,
        creator_type,
      } = payload as FirebaseMessageRoomItemPayload['notification_promotion_host_creator']
      return (
        translate('messages:hiUsername', {username}) +
        translate('messages:notificationCreatorOfficialCreatorInvitation', {
          host_leader_username,
          host_tag,
          creator_type: translate('tree:hostCreatorType', {
            context: creator_type,
          }).toLowerCase(),
        })
      )
    }

    if (type === 'notification_receive_hires') {
      const {
        creator_username,
      } = payload as FirebaseMessageRoomItemPayload['notification_receive_hires']
      return (
        translate('messages:notificationReceiveHiresTitle', {
          collector_username: myUsername,
        }) +
        translate('messages:notificationReceiveHiresDescription', {
          creator_username,
        })
      )
    }

    if (type === 'notification_reject_invitation_admin') {
      const {
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_reject_invitation_admin']
      return (
        translate('messages:hiUsername', {username}) +
        translate('messages:notificationAdminInvitationRejected')
      )
    }

    if (type === 'notification_reject_invitation_admin_host') {
      const {
        host_member_username,
        host_tag,
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_reject_invitation_admin_host']
      return (
        translate('messages:hiUsername', {username}) +
        translate('messages:notificationHostAdminInvitationRejected', {
          host_member_username,
          host_tag,
        })
      )
    }

    if (type === 'notification_reject_invitation_creator') {
      const {
        username,
      } = payload as FirebaseMessageRoomItemPayload['notification_reject_invitation_creator']
      return (
        translate('messages:hiUsername', {username}) +
        translate(
          'messages:notificationCreatorOfficialCreatorInvitationRejected',
        )
      )
    }

    if (type === 'notification_reject_invitation_creator_host') {
      const {
        host_member_username,
        host_tag,
        username,
        creator_type,
      } = payload as FirebaseMessageRoomItemPayload['notification_reject_invitation_creator_host']
      return (
        translate('messages:hiUsername', {username}) +
        translate(
          'messages:notificationHostOfficialCreatorInvitationRejected',
          {
            host_member_username,
            host_tag,
            creator_type: translate('tree:hostCreatorType', {
              context: creator_type,
            }).toLowerCase(),
          },
        )
      )
    }

    if (type === 'notification_remove_farmer') {
      const {
        tree_name,
      } = payload as FirebaseMessageRoomItemPayload['notification_remove_farmer']
      return (
        translate('messages:hiUsername', {username: myUsername}) +
        translate('messages:notificationRemoveFarmer', {tree_name})
      )
    }

    if (type === 'notification_invite_farmer') {
      const {
        owner_username,
        tree_name,
      } = payload as FirebaseMessageRoomItemPayload['notification_invite_farmer']
      return (
        translate('messages:hiUsername', {username: myUsername}) +
        translate('messages:notificationInviteFarmer', {
          owner_username,
          tree_name,
        })
      )
    }

    if (type === 'reminder_resend_content') {
      const {
        collector_nickname,
        username,
      } = payload as FirebaseMessageRoomItemPayload['reminder_resend_content']
      return translate('messages:reminderResendContent', {
        username,
        collector_nickname,
      })
    }

    if (type === 'reminder_waiting_hires_content') {
      const {
        username,
        creator_username,
      } = payload as FirebaseMessageRoomItemPayload['reminder_waiting_hires_content']
      return translate('messages:reminderWaitingHires', {
        username,
        creator_username,
      })
    }

    if (type === 'reminder_wishlist') {
      return translate('messages:reminderWishlist', {
        username: myUsername,
      })
    }

    if (type === 'reminder_creator_activation') {
      return translate('messages:reminderCreatorActivation', {
        username: myUsername,
      })
    }

    if (type === 'reminder_cart') {
      return translate('messages:reminderCart', {
        username: myUsername,
      })
    }

    if (type === 'reminder_abusive_content') {
      return translate('messages:reminderAbusiveContent', {
        username: myUsername,
      })
    }

    if (type === 'reminder_robopet') {
      return translate('messages:reminderRobopet', {
        username: myUsername,
      })
    }

    if (type === 'resend_revision') {
      const {
        message: resendMessage,
      } = payload as FirebaseMessageRoomItemPayload['resend_revision']
      return resendMessage
    }

    if (type === 'request_paid_content') {
      const {
        message: requestMessage,
      } = payload as FirebaseMessageRoomItemPayload['request_paid_content']
      return requestMessage
    }

    return translate('messages:unsupported')
  }, [type, translate, senderSelf, payload, myUsername])

  return description
}

export function useMessageContent({
  message,
}: {
  message: FirebaseMessageRoomItem
}) {
  const {translate} = useTranslation()
  const {created_at} = message

  const description = useMessageContentDescription({message})

  const time = useMemo(() => {
    const date = new Date(created_at)

    if (calcTimeDiff(date, new Date().toISOString()).hours <= 24) {
      return parseDate(created_at, 'HH:mm')
    }
    if (
      calcTimeDiff(date, new Date().toISOString()).hours > 24 &&
      calcTimeDiff(date, new Date().toISOString()).hours <= 48
    ) {
      return translate('global:yesterday')
    }
    return parseDate(created_at, 'DD/MM/YY')
  }, [created_at, translate])
  return useMemo(() => ({time, description}), [time, description])
}

export function useNewMessageIndicator() {
  const user = useSelector('user')
  const messageData = useFirebaseMessageList({userId: user?.id!}).data
  const messageRoomState = useSelector('messageRoomState')

  const messageRoomStateValue = useMemo(() => Object.values(messageRoomState), [
    messageRoomState,
  ])

  const hasNewMessage = useMemo(
    () =>
      messageData.some((message) => {
        const condition = (elem: MessageRoomState) =>
          elem.room_id === message.room_id
        const intersectMessageRoom = messageRoomStateValue.find(condition)
        const lastLeave =
          intersectMessageRoom && intersectMessageRoom.last_leave

        const lastLeaveTime = lastLeave ? new Date(lastLeave).getTime() : 0
        const lastEnterTime = message.last_enter
          ? new Date(message.last_enter).getTime()
          : 0
        const lastVisitTime = Math.max(lastEnterTime, lastLeaveTime)
        const lastUpdateTime = new Date(message.last_update).getTime()

        return lastVisitTime < lastUpdateTime
      }),
    [messageData, messageRoomStateValue],
  )

  return hasNewMessage
}
