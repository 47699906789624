import React, {useMemo} from 'react'
import {List} from '../List'
import {ListIndicator} from '../ListIndicator'
import {ListNotLazyBaseProps} from './ListNotLazyBaseProps'

export default function ListNotLazyBase<ItemT>({
  status,
  extraContent,
  listEmptyElement,
  listFailedElement,
  listFailedElementStyle,
  listEndPageElement,
  listFooterElement,
  listInitializeElement,
  listLoadingElement,
  listLoadingElementStyle,
  onRetry,
  ...props
}: ListNotLazyBaseProps<ItemT>) {
  const handleRenderFooter = useMemo(
    () => (
      <>
        {extraContent}
        <ListIndicator
          status={status}
          listEmptyElement={listEmptyElement}
          listFailedElement={listFailedElement}
          listFailedElementStyle={listFailedElementStyle}
          listEndPageElement={listEndPageElement}
          listInitializeElement={listInitializeElement}
          listLoadingElement={listLoadingElement}
          listLoadingElementStyle={listLoadingElementStyle}
          onRetry={onRetry}
        />
        {listFooterElement}
      </>
    ),
    [
      extraContent,
      status,
      listEmptyElement,
      listFailedElement,
      listFailedElementStyle,
      listEndPageElement,
      listInitializeElement,
      listLoadingElement,
      listLoadingElementStyle,
      onRetry,
      listFooterElement,
    ],
  )

  return <List {...props} listFooterElement={handleRenderFooter} />
}
