import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {IMAGE_ASSET, OTP_COOLDOWN} from 'consts'
import {useTheme} from 'themes'
import {
  formatCooldownTime,
  getFontFamily,
  showSnackbar,
  useHistory,
  useLocation,
} from 'utils'
import {Image, Paragraph, ParsedText, TextTimer} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {requestData} from 'services'

const StyledHeaderDescription = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
  text-align: center;
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`
const StyledImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${convertUnit(20)} 0;
`

const StyledIllustration = styled(Image)`
  max-width: ${convertUnit(325)};
  width: 100%;
`
const StyledResendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function AuthRecoverAccountLinkEmailVerif() {
  const {translate} = useTranslation()
  const history = useHistory()
  const {update} = useDispatch()
  const emailConfirmImage = IMAGE_ASSET('giftshop', 'email-confirm.png')
  const theme = useTheme()
  const {email, identifier, recovery_code, member_id} = useLocation(
    'auth_recover_account_link_verif',
  ).state
  const {emailTime} = useSelector('lastUserState')
  const today = new Date().getTime() / 1000
  const [resetOnPress, setResetOnPress] = useState(true)
  const [resetCountdown, setResetCountdown] = useState(false)

  const handleResend = useCallback(() => {
    requestData('auth_recover_account_send_link', {
      data: {member_id, recovery_code},
      onRequestSuccess: ({status, data: {detail}}) => {
        if (status === 200) {
          update('lastUserState', {emailTime: new Date().getTime()})
          setResetOnPress(true)
          setResetCountdown(true)
          history.replace('auth_recover_account_link_verif', {
            email,
            identifier,
            method: 'email',
          })
        } else if (status === 410 && detail) {
          const errorDetail = detail
          const {hour, minute, second} = formatCooldownTime(
            errorDetail.resend_in,
          )
          showSnackbar(
            translate(
              errorDetail.is_limit
                ? 'auth:registerEasySignupResendLinkLimitSnackbar'
                : 'auth:registerEasySignupResendLinkCooldownSnackbar',
              {
                hour,
                minute,
                second,
              },
            )
              .replace(
                hour === ''
                  ? /<hourStamp>.*?<\/hourStamp>/
                  : /<hourStamp>|<\/hourStamp>/g,
                '',
              )
              .replace(
                minute === ''
                  ? /<minuteStamp>.*?<\/minuteStamp>/
                  : /<minuteStamp>|<\/minuteStamp>/g,
                '',
              )
              .replace(/<secondStamp>|<\/secondStamp>/g, ''),
            [
              {
                pattern: /<timestamp>.*?<\/timestamp>/,
                style: {
                  fontFamily: 'Roboto-Bold',
                },
                renderText: (text) =>
                  text.replace(/<timestamp>|<\/timestamp>/g, ''),
              },
            ],
          )
        }
      },
    })
  }, [email, history, identifier, member_id, recovery_code, translate, update])

  const handleRenderTitle = useMemo(
    () => (
      <>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {email}
        </StyledParagraph>
        <StyledHeaderDescription fontSize="m" color="gray_5">
          {translate('auth:recoverAccountLinkSent')}
        </StyledHeaderDescription>
      </>
    ),
    [email, translate],
  )

  const handleRenderResend = useMemo(
    () => (
      <StyledResendContainer>
        <TextTimer
          textAvailable={translate('auth:verificationEmailResend')}
          textCooldown="email"
          lastSaved={emailTime}
          cooldown={OTP_COOLDOWN}
          onPress={handleResend}
          shouldReset={resetCountdown}
          resetWhenPress={resetOnPress}
          countOnStart={
            !!(emailTime && today - emailTime / 1000 <= OTP_COOLDOWN)
          }
          leadingText={translate('auth:verificationEmailDidNotGet')}
        />
      </StyledResendContainer>
    ),
    [emailTime, handleResend, resetCountdown, resetOnPress, today, translate],
  )

  const handleRenderMidSection = useMemo(
    () => (
      <>
        <StyledImgContainer>
          <StyledIllustration src={emailConfirmImage} alt="Illustration" />
        </StyledImgContainer>
        <StyledParagraph color="gray_3">
          <ParsedText
            parse={[
              {
                pattern: /<time>.*?<\/time>/,
                style: {
                  color: theme.gray_4,
                  fontFamily: getFontFamily('bold'),
                },
                renderText: (text) => text.replace(/<time>|<\/time>/g, ''),
              },
            ]}>
            {translate('auth:verificationEmailCaution').concat(
              ` <time>${translate('auth:verificationEmailCautionTime')}</time>`,
            )}
          </ParsedText>
        </StyledParagraph>
        {handleRenderResend}
      </>
    ),
    [emailConfirmImage, handleRenderResend, theme.gray_4, translate],
  )
  return (
    <>
      {handleRenderTitle}
      {handleRenderMidSection}
    </>
  )
}
