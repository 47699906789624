export const FORKYGRAM_UPLOAD_TYPE = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/heif',
  'video/mov',
  'video/mp4',
  'video/quicktime',
]
export const FORKYGRAM_SPECIAL_IMAGE_UPLOAD_TYPE = ['image/heic', 'image/heif']
export const FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE = 'image/jpeg'
export const FORKYGRAM_SPECIAL_VIDEO_UPLOAD_TYPE = ['video/quicktime']
export const FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE = 'video/mp4'

export const FORKYGRAM_COMMENT_LIMIT_DAY = 200
export const FORKYGRAM_COMMENT_MAX_LENGTH = 300
export const FORKYGRAM_UPLOAD_CAPTION_MAX_CHARACTER = 300
export const FORKYGRAM_UPLOAD_MAX_IMAGE_SIZE = 5242880
export const FORKYGRAM_UPLOAD_MAX_RESOLUTION = 25000000
export const FORKYGRAM_UPLOAD_MAX_VIDEO_SIZE = 31457280
export const FORKYGRAM_UPLOAD_MAX_VIDEO_DURATION = 30
export const FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH = 1080
export const FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION = 600
export const FORKYGRAM_CAPTION_LINE_HEIGHT = 30
export const FORKYGRAM_FEED_QUERY_LIMIT = 10
export const FORKYGRAM_FAVORITES_QUERY_LIMIT = 2
export const FORKYGRAM_FOOTPRINTS_QUERY_LIMIT = 20
export const FORKYGRAM_FOOTPRINTS_MAX_ITEM = 9

export const FORKYGRAM_SEARCH_FRIENDS_QUERY_LIMIT = 30

export const FORKYGRAM_SLIDER_MARGIN = 20
