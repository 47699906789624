import React, {useMemo, useCallback, useRef, useState, useEffect} from 'react'
import styled from 'styled-components'
import {FORKYGRAM_SLIDER_MARGIN, GIFT_SHOP_ROOT_MENU_WIDTH} from 'consts'
import {useWindowLayout, useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {SliderProps} from './SliderProps'
import {Icon} from '../Icon'

const StyledListContainer = styled.div`
  display: block;
  width: -webkit-fill-available;
`

const StyledInnerContainer = styled.div`
  display: grid;
  position: relative;
  width: inherit;
  height: 100%;
`

const StyledContentContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
`

const StyledIconContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  position: absolute;
  &:hover {
    cursor: pointer;
  }
`

const StyledLeftIconContainer = styled(StyledIconContainer)`
  left: ${convertUnit(-20)};
`
const StyledRightIconContainer = styled(StyledIconContainer)`
  right: ${convertUnit(-20)};
`

export default function Slider<T>({
  data = [],
  listContainerStyle,
  listContentContainerStyle,
  listFooterElement,
  renderItem,
}: SliderProps<T>) {
  const {width} = useWindowLayout()
  const mode = useWindowMode()

  const [startZone, setStartZone] = useState(true)
  const [endZone, setEndZone] = useState(false)

  const column = mode === 'website' ? 5 : 4
  const slideAmount = useMemo(
    () =>
      mode === 'website'
        ? (width - GIFT_SHOP_ROOT_MENU_WIDTH - FORKYGRAM_SLIDER_MARGIN) / column
        : (width - FORKYGRAM_SLIDER_MARGIN) / column,
    [column, mode, width],
  )
  const handleRenderItem = useMemo(
    () => data.map((item, index) => renderItem(item, index)),
    [data, renderItem],
  )

  const ref = useRef<HTMLDivElement>(null)
  const firstVisibleElemIndex = useRef(0)
  const lastVisibleElemIndex = useRef(
    mode === 'website'
      ? Math.floor(
          (width - GIFT_SHOP_ROOT_MENU_WIDTH - FORKYGRAM_SLIDER_MARGIN) /
            slideAmount,
        ) - 1
      : Math.floor((width - FORKYGRAM_SLIDER_MARGIN) / slideAmount) - 1,
  )
  const targetEndPosition = useRef(ref.current?.scrollLeft || 0)

  const handleLeftIconClick = useCallback(() => {
    const prevFirstIndex = firstVisibleElemIndex.current
    const prevLastIndex = lastVisibleElemIndex.current

    if (prevFirstIndex > 0) {
      targetEndPosition.current -= slideAmount
      ref.current?.scrollTo({
        left: targetEndPosition.current,
        behavior: 'smooth',
      })
      firstVisibleElemIndex.current = prevFirstIndex - 1
      lastVisibleElemIndex.current = prevLastIndex - 1

      lastVisibleElemIndex.current >= data.length - 1
        ? setEndZone(true)
        : setEndZone(false)

      firstVisibleElemIndex.current <= 0
        ? setStartZone(true)
        : setStartZone(false)
    }
  }, [data.length, slideAmount])

  const handleRightIconClick = useCallback(() => {
    const prevFirstIndex = firstVisibleElemIndex.current
    const prevLastIndex = lastVisibleElemIndex.current
    if (!endZone && prevLastIndex <= data.length - 1) {
      targetEndPosition.current += slideAmount
      ref.current?.scrollTo({
        left: targetEndPosition.current,
        behavior: 'smooth',
      })
      firstVisibleElemIndex.current = prevFirstIndex + 1
      lastVisibleElemIndex.current = prevLastIndex + 1

      lastVisibleElemIndex.current > data.length - 1
        ? setEndZone(true)
        : setEndZone(false)

      firstVisibleElemIndex.current <= 0
        ? setStartZone(true)
        : setStartZone(false)
    }
  }, [data.length, endZone, slideAmount])

  useEffect(() => {
    data &&
      ref.current &&
      setEndZone(ref.current?.scrollWidth <= ref.current?.clientWidth)
  }, [data])

  return (
    <StyledListContainer style={listContainerStyle}>
      <StyledInnerContainer>
        <StyledLeftIconContainer onClick={() => handleLeftIconClick()}>
          <Icon
            size={14.5}
            type="chevron-left"
            color={startZone ? 'gray_3' : 'primary_5'}
          />
        </StyledLeftIconContainer>
        <StyledContentContainer style={listContentContainerStyle} ref={ref}>
          {data.length && handleRenderItem}
          {listFooterElement}
        </StyledContentContainer>
        <StyledRightIconContainer onClick={() => handleRightIconClick()}>
          <Icon
            size={14.5}
            type="chevron-right"
            color={endZone ? 'gray_3' : 'primary_5'}
          />
        </StyledRightIconContainer>
      </StyledInnerContainer>
    </StyledListContainer>
  )
}
