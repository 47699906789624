import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {AuthFormSimpleRegisterData} from 'types'
import {requestData} from 'services'
import {showSnackbar, useHistory} from 'utils'
import {Button, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch} from 'lib/redux'
import {GiftShopTemplateInputPassword} from '../../../gift-shop'

const StyledContainer = styled.div`
  padding: ${convertUnit(20)};
`
const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${convertUnit(20)};
`
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`
const StyledHeaderDescription = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
  text-align: center;
`
const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
  width: fill-available;
`

export default function AuthRecoverAccountCreatePassword() {
  const history = useHistory()
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const stateDisabled = useState(true)
  const disabled = stateDisabled[0]
  const form = useForm<AuthFormSimpleRegisterData>({
    defaultValues: {
      password: '',
      confirm: '',
    },
  })
  const {watch} = form
  const {password} = watch()
  const statePasswordError = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmitPassword = useCallback(() => {
    setLoading(true)
    requestData('auth_patch_passwordsso', {
      data: {password},
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          showSnackbar(translate('auth:successCreatePassword'))
          update('user', {has_password: true})
          history.replace('giftshop_explore', {})
        } else {
          showSnackbar(translate('global:messageError', {context: status}))
        }
      },
    })
  }, [history, password, translate, update])

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        label={translate('global:continue')}
        disabled={disabled}
        isLoading={loading}
        onClick={handleSubmitPassword}
      />
    ),
    [disabled, handleSubmitPassword, loading, translate],
  )
  const handleRenderTitle = useMemo(
    () => (
      <StyledTitleContainer>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {translate('giftShop:createPassword')}
        </StyledParagraph>
        <StyledHeaderDescription fontSize="m" color="gray_5">
          {translate('auth:recoverAccountCreatePasswordDesc')}
        </StyledHeaderDescription>
      </StyledTitleContainer>
    ),
    [translate],
  )
  const handleRenderContent = useMemo(
    () => (
      <StyledContainer>
        {handleRenderTitle}
        <GiftShopTemplateInputPassword
          stateDisabled={stateDisabled}
          form={form}
          statePasswordError={statePasswordError}
        />
        {handleRenderButton}
      </StyledContainer>
    ),
    [
      form,
      handleRenderButton,
      handleRenderTitle,
      stateDisabled,
      statePasswordError,
    ],
  )
  return handleRenderContent
}
