import {
  AuthLoginResponse,
  FirestoreExploreRefreshPayload,
  ServiceHandler,
  ServiceHandlerOption,
} from 'types'
import {reduxUpdateDispatcher, REDUX_STORE} from 'lib/redux'
import firebase from 'lib/firebase'
import {ServiceParamAuth} from '../ServiceParamAuth'
import {requestData} from '../../Service'
import {getChildrenStatus} from '../../../utils'

function handleGetKycStatus() {
  requestData('giftshop_get_account', {
    useDefaultMessage: true,
    actionType: 'fetch',
    onRequestSuccess: ({status, data: {result}}) => {
      if (status === 200) {
        const kycStatus = result.status
        reduxUpdateDispatcher('yuserActivationState', {
          kycStatus,
          originalKycPhoto: result.original_kyc_photo,
          latestKycPhoto: result.latest_kyc_photo,
          retakeStatus: result.retake_status,
        })
        if (kycStatus === 'unregistered') {
          return window.location.replace('/roboyu/activation')
        }
      }
    },
  })
}

function handleCheckLastUpdate(id: string) {
  const getLastExplore = localStorage.getItem('last_explore_time')
  firebase
    .firestore()
    .collection(process.env.FIRESTORE_COLLECTION_CONTENT!)
    .doc(id)
    .get({
      source: 'server',
    })
    .then((response) => {
      const responseData = response.data() as FirestoreExploreRefreshPayload
      if (responseData) {
        if (Number(getLastExplore) < responseData.explore_last_update) {
          localStorage.setItem(
            'last_explore_time',
            String(responseData.explore_last_update),
          )
          window.location.replace('/fotoyu')
        }
      }
    })
}

function handleAuthLogin({
  response,
}: ServiceHandlerOption<ServiceParamAuth, 'auth_login'>) {
  const {status, data} = response
  const currLang = REDUX_STORE.getState().languageState.lang

  if (status === 200) {
    reduxUpdateDispatcher('user', {...data.result, lang: currLang})
    reduxUpdateDispatcher('lastUserState', {
      username: undefined,
      photo: undefined,
      refreshToken: undefined,
    })
    if (currLang !== data.result.lang) {
      requestData('giftshop_profile_edit_language', {
        data: {lang: currLang},
      })
    }
    handleGetKycStatus()
    handleCheckLastUpdate(data.result.id)
    getChildrenStatus()
  }
}

function handleAuthLoginSSO({
  response,
}: ServiceHandlerOption<ServiceParamAuth, 'auth_login_sso_google'>) {
  const {status, data} = response
  const currLang = REDUX_STORE.getState().languageState.lang

  if (status === 200) {
    const userInfo = data.result as AuthLoginResponse
    reduxUpdateDispatcher('user', {...userInfo, lang: currLang})
    reduxUpdateDispatcher('lastUserState', {
      username: undefined,
      photo: undefined,
      refreshToken: undefined,
    })
    reduxUpdateDispatcher('giftShopTooltipState', {
      lastPopTime: new Date().getTime(),
    })
    if (currLang !== userInfo.lang) {
      requestData('giftshop_profile_edit_language', {
        data: {lang: currLang},
      })
    }
    handleGetKycStatus()
    handleCheckLastUpdate(userInfo.id)
    getChildrenStatus()
  }
}

function handleAuthRegisterSSO({
  response,
}: ServiceHandlerOption<ServiceParamAuth, 'auth_register_sso_google'>) {
  const {status, data} = response
  const currLang = REDUX_STORE.getState().languageState.lang

  if (status === 200) {
    const userInfo = data.result as AuthLoginResponse
    reduxUpdateDispatcher('user', {...userInfo, lang: currLang})
    if (currLang !== userInfo.lang) {
      requestData('giftshop_profile_edit_language', {
        data: {lang: currLang},
      })
    }
  }
}

function handleAuthSavedLogin({
  response,
}: ServiceHandlerOption<ServiceParamAuth, 'auth_refresh_token'>) {
  const {
    status,
    data: {result},
  } = response
  if (status === 200) {
    const currLang = REDUX_STORE.getState().languageState.lang
    reduxUpdateDispatcher('user', {...result, lang: currLang})
    reduxUpdateDispatcher('lastUserState', {
      username: undefined,
      photo: undefined,
      refreshToken: undefined,
    })
    if (currLang !== result.lang) {
      requestData('giftshop_profile_edit_language', {
        data: {lang: currLang},
      })
    }
    handleGetKycStatus()
    getChildrenStatus()
  }
}

export const SERVICE_HANDLER_AUTH: ServiceHandler<ServiceParamAuth> = {
  auth_login: handleAuthLogin,
  auth_login_sso_apple: handleAuthLoginSSO,
  auth_login_sso_google: handleAuthLoginSSO,
  auth_register_sso_apple: handleAuthRegisterSSO,
  auth_register_sso_google: handleAuthRegisterSSO,
  auth_refresh_token: handleAuthSavedLogin,
  auth_easy_signup_login_sso_google: handleAuthLoginSSO,
  auth_easy_signup_login_sso_apple: handleAuthLoginSSO,
}
