import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_ROOT_BOTTOM_NAVBAR_HEIGHT,
  GIFT_SHOP_ROOT_MENU_WIDTH,
  IMAGE_ASSET,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {getThemeColor, showSnackbar} from 'utils'
import {
  Button,
  ConfirmationModal,
  Icon,
  Image,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {TemplateProfileInfo} from '../ProfileInfo'
import {TemplateProfileTab, TemplateProfileTabPost} from '../Tab'
import {TemplateProfileContentProps} from './TemplateProfileContentProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  background-color: ${({theme}) => theme.white_1};
`

const StyledBlockedContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${convertUnit(20)};
  padding: 0 ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: fit-content;
`

const StyledFloatingBtnContainer = styled.div`
  position: fixed;
  bottom: ${convertUnit(20)};
  left: ${convertUnit(20 + GIFT_SHOP_ROOT_MENU_WIDTH)};
  z-index: 5;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    bottom: ${convertUnit(20 + GIFT_SHOP_ROOT_BOTTOM_NAVBAR_HEIGHT)};
    left: ${convertUnit(20)};
  }
`

const StyledFullTabBtnContainer = styled.div`
  width: ${convertUnit(42)};
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${({theme}) => getThemeColor(theme, 'white_1', 0.8)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const StyledImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-height: ${convertUnit(200)};
  align-items: center;
  justify-content: center;
`

const StyledImage = styled(Image)`
  object-fit: contain;
  max-height: ${convertUnit(200)};
  max-width: 100%;
  margin-bottom: ${convertUnit(20)};
`

export default function TemplateProfileContent({
  data,
  self,
  creatorStatus,
  kycStatus,
  stateSelectTab,
  stateContentType,
  stateCreationData,
  stateSelect,
  stateSelectMode,
  stateCreationType,
  stateFilterTags,
  stateBlock,
  isBeingBlocked,
  stateGroupData,
  stateSelectGroup,
  stateResendButtonType,
  stateHideInfo,
  showOnlyTabContents = false,
}: TemplateProfileContentProps) {
  const {translate} = useTranslation()
  const {access_token, id = ''} = useSelector('user') || {}
  const [block, setBlock] = stateBlock
  const [hideInfo, setHideInfo] = stateHideInfo
  const [unblockModal, setUnblockModal] = useState(false)

  const handleUnblock = useCallback(() => {
    if (access_token && data) {
      requestData('forkygram_delete_unblock_account', {
        params: {blocked_id: data.id},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setUnblockModal(false)
            setBlock(false)
            showSnackbar(translate('forkygram:unblockSuccessMessage'))
          }
        },
      })
    }
  }, [access_token, data, setBlock, translate])

  const handleRenderUnblockYuserModal = useMemo(
    () => (
      <ConfirmationModal
        visible={unblockModal}
        text={translate('forkygram:unblockConfirmation')}
        title={translate('forkygram:unblock')}
        cancelText={translate('global:cancel')}
        onConfirm={handleUnblock}
        toggleModal={() => setUnblockModal(false)}
      />
    ),
    [handleUnblock, translate, unblockModal],
  )

  const handleRenderBlockedAccount = useMemo(
    () => (
      <StyledBlockedContainer>
        <Paragraph fontSize="m" color="gray_5">
          {translate('forkygram:blockedDescription')}
        </Paragraph>
        <StyledButton
          label={translate('forkygram:unblock')}
          onClick={() => setUnblockModal(true)}
        />
      </StyledBlockedContainer>
    ),
    [translate],
  )

  const handleRenderBeingBlocked = useMemo(
    () => (
      <StyledBlockedContainer>
        <StyledImageContainer>
          <StyledImage src={IMAGE_ASSET('giftshop', 'empty.png')} alt="empty" />
        </StyledImageContainer>{' '}
        <Paragraph fontSize="l" fontWeight="bold">
          {translate('forkygram:beingBlocked')}
        </Paragraph>
      </StyledBlockedContainer>
    ),
    [translate],
  )

  const handleRenderOtherProfileContents = useMemo(
    () =>
      data && (
        <TemplateProfileTabPost
          self={self}
          memberId={data.id}
          profileData={data}
        />
      ),
    [data, self],
  )

  const handleRenderOtherProfile = useMemo(
    () =>
      block
        ? handleRenderBlockedAccount
        : isBeingBlocked
        ? handleRenderBeingBlocked
        : handleRenderOtherProfileContents,
    [
      block,
      handleRenderBeingBlocked,
      handleRenderBlockedAccount,
      handleRenderOtherProfileContents,
      isBeingBlocked,
    ],
  )

  const handleRenderProfileTab = useMemo(
    () =>
      self ? (
        <TemplateProfileTab
          self={self}
          memberId={id}
          kycStatus={kycStatus}
          creatorStatus={creatorStatus}
          stateContentType={stateContentType}
          stateSelectTab={stateSelectTab}
          stateCreationData={stateCreationData}
          stateSelect={stateSelect}
          stateSelectMode={stateSelectMode}
          stateCreationType={stateCreationType}
          stateFilterTags={stateFilterTags}
          stateGroupData={stateGroupData}
          stateSelectGroup={stateSelectGroup}
          stateResendButtonType={stateResendButtonType}
          showOnlyTabContents={showOnlyTabContents}
        />
      ) : (
        handleRenderOtherProfile
      ),
    [
      self,
      id,
      kycStatus,
      creatorStatus,
      stateContentType,
      stateSelectTab,
      stateCreationData,
      stateSelect,
      stateSelectMode,
      stateCreationType,
      stateFilterTags,
      stateGroupData,
      stateSelectGroup,
      stateResendButtonType,
      showOnlyTabContents,
      handleRenderOtherProfile,
    ],
  )

  const handleRenderToggleInfoVisibilityBtn = useMemo(
    () =>
      !showOnlyTabContents && (
        <StyledFloatingBtnContainer>
          <StyledFullTabBtnContainer
            onClick={() => setHideInfo((prev) => !prev)}>
            <Icon type="arrow-expand" color="primary_5" size={20} />
          </StyledFullTabBtnContainer>
        </StyledFloatingBtnContainer>
      ),
    [setHideInfo, showOnlyTabContents],
  )

  return (
    <StyledContainer>
      {!(showOnlyTabContents || hideInfo) && (
        <TemplateProfileInfo
          self={self}
          memberId={data?.id}
          username={data?.username}
          photo={data?.photo}
          background_photo={data?.background_photo}
          isUnderage={data?.is_underage}
          kycStatus={kycStatus}
          creatorStatus={creatorStatus}
          stateSelectTab={stateSelectTab}
          isOfficial={data?.is_official}
          isLive={data?.is_live}
          stateBlock={stateBlock}
          isBeingBlocked={isBeingBlocked}
        />
      )}
      {handleRenderUnblockYuserModal}
      {handleRenderToggleInfoVisibilityBtn}
      {handleRenderProfileTab}
    </StyledContainer>
  )
}
