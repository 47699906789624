import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {UseFormMethods} from 'react-hook-form'
import {AuthFormSimpleRegisterData, ObjectState} from 'types'
import {useTranslation} from 'i18n'
import {
  AUTH_PASSWORD_MAX_LENGTH,
  AUTH_PASSWORD_MIN_LENGTH,
  REGEX_LETTERS_NUMBERS_SPECIALCHAR_ONLY,
  REGEX_NO_DASH_UNDERSCORE_PERIOD,
  REGEX_PASSWORD_MIX,
} from 'consts'
import {ThemeColor} from 'themes'
import {Icon, Input, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledInputContainer = styled.div`
  margin: ${convertUnit(16)} 0;
`
const StyledErrorMessage = styled(Paragraph)`
  margin-top: ${convertUnit(5)};
`
const StyledIndicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${convertUnit(8)};
`

interface GiftShopTemplateInputPasswordProps {
  stateDisabled: ObjectState<boolean>
  form: UseFormMethods<AuthFormSimpleRegisterData>
  statePasswordError: ObjectState<string>
}
export default function GiftShopTemplateInputPassword({
  stateDisabled,
  form,
  statePasswordError,
}: GiftShopTemplateInputPasswordProps) {
  const {translate} = useTranslation()
  const {watch} = form
  const {password, confirm} = watch()
  const [passwordMatch, setPasswordMatch] = useState<boolean>()
  const [minLength, setMinLength] = useState<boolean>()
  const [passwordRegex, setPasswordRegex] = useState<boolean>()
  const [passwordError, setPasswordError] = statePasswordError
  const setDisabled = stateDisabled[1]

  const isPasswordDisabled = useMemo(
    () =>
      password === '' ||
      confirm === '' ||
      !minLength ||
      !passwordRegex ||
      !passwordMatch,
    [confirm, minLength, password, passwordMatch, passwordRegex],
  )

  const minLengthColor = useMemo<ThemeColor>(
    () =>
      minLength === undefined ? 'gray_3' : minLength ? 'success_5' : 'danger_5',
    [minLength],
  )

  const passwordRegexColor = useMemo<ThemeColor>(
    () =>
      passwordRegex === undefined
        ? 'gray_3'
        : passwordRegex
        ? 'success_5'
        : 'danger_5',
    [passwordRegex],
  )

  const handleRenderError = useMemo(
    () =>
      passwordError ? (
        <Paragraph fontWeight="medium" color="danger_5">
          {passwordError}
        </Paragraph>
      ) : (
        <></>
      ),
    [passwordError],
  )

  const handleRenderPasswordInput = useMemo(
    () => (
      <>
        <Input
          label={translate('global:password')}
          form={form}
          name="password"
          type="password"
          onChangeText={(text) => {
            setPasswordError('')
            setMinLength(text.length >= AUTH_PASSWORD_MIN_LENGTH)
            setPasswordRegex(
              REGEX_NO_DASH_UNDERSCORE_PERIOD.test(text) &&
                REGEX_LETTERS_NUMBERS_SPECIALCHAR_ONLY.test(text) &&
                REGEX_PASSWORD_MIX.test(text),
            )
          }}
          rightIcon="visibility"
          autoCapitalize="none"
          placeholder={translate('auth:registerPasswordInputPlaceholder')}
          maxLength={AUTH_PASSWORD_MAX_LENGTH}
        />
        <StyledInputContainer>
          <Input
            label={translate('global:passwordConfirm')}
            form={form}
            onChangeText={() => setPasswordError('')}
            name="confirm"
            type="password"
            autoCapitalize="none"
            placeholder={translate(
              'auth:createPasswordSSOConfirmationPlaceholder',
            )}
            rightIcon="visibility"
            maxLength={AUTH_PASSWORD_MAX_LENGTH}
          />
          {!passwordMatch && (
            <StyledErrorMessage fontWeight="medium" color="danger_5">
              {translate('auth:confirmationPassword')}
            </StyledErrorMessage>
          )}
          {handleRenderError}
        </StyledInputContainer>
      </>
    ),
    [form, handleRenderError, passwordMatch, setPasswordError, translate],
  )

  const handleRenderValidation = useMemo(
    () => (
      <>
        <StyledIndicator>
          <Icon type="check" color={minLengthColor} />
          <Paragraph fontWeight="medium" color={minLengthColor}>
            {translate('auth:generalValidationMinLength', {
              length: AUTH_PASSWORD_MIN_LENGTH,
            })}
          </Paragraph>
        </StyledIndicator>
        <StyledIndicator style={{marginBottom: 0}}>
          <Icon type="check" color={passwordRegexColor} />
          <Paragraph fontWeight="medium" color={passwordRegexColor}>
            {translate('auth:generalValidationAlphanumeric')}
          </Paragraph>
        </StyledIndicator>
      </>
    ),
    [minLengthColor, passwordRegexColor, translate],
  )

  useEffect(() => {
    setPasswordMatch(confirm === '' || confirm === password)
  }, [confirm, password])

  useEffect(() => {
    setDisabled(isPasswordDisabled || !(confirm === '' || confirm === password))
  })

  return (
    <>
      {handleRenderPasswordInput}
      {handleRenderValidation}
    </>
  )
}
