import React from 'react'
import {useListNotLazyAction} from 'utils'
import {ListNotLazyProps} from './ListNotLazyProps'
import {ListNotLazyBase} from '../ListNotLazyBase'

export default function ListNotLazy<ItemT, S>({
  data,
  limitPerPage,
  search,
  refresh,
  loadData,
  keyExtractor,
  primaryExtractor,
  stateData,
  cache,
  page,
  defaultPage,
  limitContent,
  ...props
}: ListNotLazyProps<ItemT, S>) {
  const {
    items,
    status,
    handleLayout,
    handleRetry,
    handleContentSizeChange,
  } = useListNotLazyAction<ItemT, S>({
    data,
    cache,
    stateData,
    refresh,
    search,
    limitPerPage,
    assertPage: page,
    defaultPage,
    loadData,
    keyExtractor,
    primaryExtractor,
    limitContent,
  })

  return (
    <ListNotLazyBase
      {...props}
      data={items}
      status={status}
      keyExtractor={keyExtractor}
      onLayout={handleLayout}
      onRetry={handleRetry}
      onContentSizeChange={handleContentSizeChange}
    />
  )
}
