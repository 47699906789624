import {TranslationKeyMessages} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_MESSAGES_EN: TranslationResource<TranslationKeyMessages> = {
  translation: {
    'messages:badgeBot': 'Bot',
    'messages:badgeCreator': 'Creator',
    'messages:buttonMyCollection': 'My Collection',
    'messages:buttonResendHires': 'Resend Hi-Res',
    'messages:buttonViewBalance': 'View Balance',
    'messages:collabInvitationInfo':
      'By accepting this invitation, you will be added as an author of this post.',
    'messages:collabInvitationTitle': 'Accept the Collaboration?',
    'messages:collabInvitationAccepted':
      'You have accepted the collaboration invitation.',
    'messages:collabInvitationRejected':
      'You have rejected the collaboration invitation.',
    'messages:collabInvitationRejectTitle': 'Reject the Collaboration?',
    'messages:creatorRatingModalInfo':
      'Your rating matters for creator improvement. Thank you!',
    'messages:creatorRatingModalTitle': 'Rating Creator',
    'messages:creatorRatingSubmitFailed':
      'Please check your internet connection and try again.',
    'messages:creatorRatingSubmitSuccess': 'Successfully submitted a review.',
    'messages:deleteMessage': 'Delete Chat',
    'messages:deleteMessageConfirmation':
      'Are you sure you want to delete this chat? This action can not be undone.',
    'messages:emptyDesc':
      'Get messages from another users here, or start a new one.',
    'messages:emptyMessageList': 'No Message Yet',
    'messages:emptyTitle': 'Your Messages',
    'messages:hiUsername': 'Hi {{username}}, ',
    'messages:hostAdminDemotion':
      'Hi {{username}},\n\nYou are no longer a host admin at {{host_tag}}.',
    'messages:hostAdminPromotion':
      'Hi {{username}},\n\n{{host_leader_username}} just promoted you to become a host admin of {{host_tag}}.',
    'messages:hostCreatorDemotion':
      'Hi {{username}},\n\nYou are no longer an {{creator_type}} creator at {{host_tag}}.',
    'messages:hostCreatorPromotion':
      'Hi {{username}},\n\n{{host_leader_username}} just promoted you to become internal creator from {{host_tag}}.',
    'messages:inputPlaceholder': 'Message...',
    'messages:listRoomMessages': '{{message}}',
    'messages:listRoomMessages_self': 'You: {{message}}',
    'messages:newMessage': 'New Message',
    'messages:notificationAdminInvitation':
      '{{host_leader_username}} just sent an invitation to promote you as a host admin for {{host_tag}}.',
    'messages:notificationAdminInvitationAccepted':
      'Congratulations! You just become a host admin for {{host_tag}}.',
    'messages:notificationAdminInvitationRejected':
      'Thank you for your answer.',
    'messages:notificationAwaitingPayment':
      'Hi {{username}},\n\nYour order of {{total_payment}} with payment number {{invoice_id}} has not been paid. Please make a payment to the {{payment_method}} with the billing number {{billing_number}} before {{transaction_expiration}}.\n\nIgnore this notification if you have already paid.',
    'messages:notificationAwaitingPayment_virtual_account':
      'Hi {{username}},\n\nYour order of {{total_payment}} with payment number {{invoice_id}} has not been paid. Please make a payment to the {{payment_method}} with the virtual account number {{billing_number}} before {{transaction_expiration}}.\n\nIgnore this notification if you have already paid.',
    'messages:notificationAwaitingPayment_ewallet':
      'Hi {{username}},\n\nYour order of {{total_payment}} with payment number {{invoice_id}} has not been paid. Please make a payment to the {{payment_method}}.\n\nIgnore this notification if you have already paid.',
    'messages:notificationCollaboration':
      '<username>{{username}}</username> added you as a collaborator.',
    'messages:notificationCollaborationInvitation':
      '<username>{{username}}</username> invited you to be a collaborator on their post.',
    'messages:notificationComment': '{{username}} commented: {{comment}}',
    'messages:notificationCommentMention':
      '{{username}} mentioned you in a comment: {{comment}}',
    'messages:notificationCreatorOfficialCreatorInvitation':
      '{{host_leader_username}} just sent an invitation to promote you as an {{creator_type}} creator for {{host_tag}}.',
    'messages:notificationCreatorOfficialCreatorInvitationAccepted':
      'Congratulations! You just become an {{creator_type}} creator for {{host_tag}}.',
    'messages:notificationCreatorOfficialCreatorInvitationRejected':
      'Thank you for your answer.',
    'messages:notificationCreatorReviewedInfoFirst':
      'Your content sold! {{invoice_id}}',
    'messages:notificationCreatorReviewedInfoSecond':
      "I'm so happy for you! Cheers!",
    'messages:notificationCreatorReviewedOpening': 'Hi {{username}}, ',
    'messages:notificationCreatorReviewedRating':
      '{{buyer_nickname}} has given you a rating.',
    'messages:notificationFollow': '{{username}} started following you.',
    'messages:notificationHostAdminInvitationAccepted':
      'Congratulations! {{host_member_username}} just accepted the invitation to become host admin for {{host_tag}}.',
    'messages:notificationHostAdminInvitationRejected':
      '{{host_member_username}} does not confirmed the invitation to become host admin for {{host_tag}}.',
    'messages:notificationHostOfficialCreatorInvitationAccepted':
      'Congratulations! {{host_member_username}} just accepted the invitation to become {{creator_type}} creator for {{host_tag}}.',
    'messages:notificationHostOfficialCreatorInvitationRejected':
      '{{host_member_username}} does not confirmed the invitation to become {{creator_type}} creator for {{host_tag}}.',
    'messages:notificationInviteFarmer':
      '{{owner_username}} just added you to become a farmer of {{tree_name}} FotoTree',
    'messages:notificationLike': '<bold>{{username}}</bold> liked your photo.',
    'messages:notificationLikeComment':
      '{{username}} liked your comment: {{comment}}',
    'messages:notificationLikeCommentGroup': '{{username}} liked your comment.',
    'messages:notificationMakeItFree':
      '{{creator_username}} has decided to make a refund of the content you have purchased:',
    'messages:notificationPromotionHostCancel':
      '{{host_tag}} cancelled the invitation.',
    'messages:notificationPurchase':
      "Hi {{username}},\n\nCongrats! Your purchase is complete. And we're just as excited as you are.",
    'messages:notificationPurchaseCreator':
      'Hi {{username}},\n\nYour content has been successfully purchased by {{collector_nickname}}',
    'messages:notificationPurchaseCreatorExtra': "I'm happy too! Cheers!",
    'messages:notificationPurchaseReviewButton': 'Give Rating',
    'messages:notificationPurchaseReviewInfo':
      "Congrats! Your purchase is complete. And we're just as excited as you are.",
    'messages:notificationPurchaseReviewOpening': 'Hi {{username}}, ',
    'messages:notificationPurchaseReviewRatingFilled': 'Your Rating:',
    'messages:notificationPurchaseReviewRatingQuestion':
      'How would you rate {{creator_username}}?',
    'messages:notificationReceiveHiresTitle':
      'Congratulations {{collector_username}}!',
    'messages:notificationReceiveHiresDescription':
      'Your purchase is complete. {{creator_username}} has sent you a high-resolution image of the content you purchased:',
    'messages:notificationRefundTitle': "You've received a refund!",
    'messages:notificationRefund':
      "{{creator_username}} didn't send you Hi-Res content within 2 days. You will receive {{refundAmount}} from your purchase of:",
    'messages:notificationRefundAmount': 'Refund Amount:',
    'messages:notificationRefundExtra':
      'You may check your refund on your balance screen.',
    'messages:notificationRefundCreatorTitle':
      'Your transaction is closed half completed.\n',
    'messages:notificationRefundCreatorSubtitle':
      "You didn't send your Hi-Res content within 2 days. Therefore you cannot claim the full price amount from this transaction:",
    'messages:notificationRefundCreatorExtra':
      'The amount of {{creator_refund_amount}} will be deducted from this transaction, and refunded to {{collector_nickname}}.\n\nYou may check the transaction detail on your transaction history.',
    'messages:notificationRemoveFarmer':
      'You are no longer a farmer at {{tree_name}} FotoTree',
    'messages:notificationReplyComment':
      '{{username}} replied your comment: {{comment}}',
    'messages:notificationResendApprovalButton': 'Confirm hi-res',
    'messages:notificationResendApprovalFirst':
      '{{creator_nickname}} has sent the hi-res of the following content:',
    'messages:notificationResendApprovalAdditionalContent': '+ {{total}} more',
    'messages:notificationResendApprovalInvoiceId':
      'Invoice ID: {{invoice_id}}',
    'messages:notificationResendApprovalSecond':
      'Please confirm the hi-res content so that the transaction can be completed. Thank you!',
    'messages:notificationResendpologyFirst':
      "{{creator_username}} didn't send you a high-resolution content you purchased at {{purchased_at}} within 2 days,",
    'messages:notificationResendpologySecond':
      'therefore has resend the high-resolution content as an apology for your inconvenience.',
    'messages:notificationResendpologyCreatorFirst':
      "You didn't send your Hi-Res content within 2 days. Therefore you cannot claim the full price amount from this transaction:",
    'messages:notificationResendpologyCreatorPurchaseDate':
      'Purchase date: {{purchased_at}}',
    'messages:notificationResendpologyCreatorSecond':
      'The amount of {{collector_refund_amount}} was deducted from this transaction and refunded to {{collector_nickname}}. You may check the transaction detail on your transaction history.',
    'messages:notificationResendpologyCreatorThird':
      'If you like to, you could still resend the Hi-Res for free as a service.\n\nYou can also delete this button, or let it disappear after 7 days.',
    'messages:notificationStorageAddition':
      'Hi {{username}},\n\nCongrats! You get additional 50MB storage for the first buy from {{collector_nickname}}.',
    'messages:notificationStorageRequestSuccess':
      'Hi {{username}},\n\nYour storage has increased by {{storage_addition_amount}} GB! Please check your Storage menu.',
    'messages:notificationTreeApproved':
      'Congratulations <username>{{username}}!</username>\n\nYour <tree_name>{{tree_name}}</tree_name> FotoTree has been approved and is now public!',
    'messages:notificationTreeNonPublic':
      'Hi <username>{{username}}</username>,\n\nYour <tree_name>{{tree_name}}</tree_name> FotoTree has been planted successfully. It will be unpublic untill admin publish it.',
    'messages:notificationTreeRemoved':
      'Hi <username>{{username}}</username>,\n\nYour <tree_name>{{tree_name}}</tree_name> FotoTree has been removed, due to not complying with one or several of our community guidelines.',
    'messages:notificationWithdraw':
      'Hi {{username}},\n\nYour withdrawal {{withdraw_id}} has been successfully made on {{withdraw_date}}, amounting to {{withdraw_amount}}.\n\nIf this activity was not carried out by yourself, please change your password and contact us immediately.',
    'messages:notificationWithdrawCancelled':
      'Hi <bold>{{username}}</bold>,\n\nYour withdrawal request <bold>{{withdraw_id}}</bold> of <bold>{{withdraw_amount}}</bold> has been canceled at <bold>{{withdraw_date}}</bold>.',
    'messages:openInvitationButton': 'Open invitation',
    'messages:reminderAbusiveContent':
      "Oh no {{username}},\n\nThe content you recently uploaded may violate our community guidelines and we can't upload it to giftshop.",
    'messages:reminderAbusiveContentExtra':
      "We don't allow the sharing or publishing of content that violating our community guidelines\n\nYou can read our community guidelines again here",
    'messages:reminderCart':
      "Hi {{username}},\n\nI think you recently accidentally left some amazing stuff in your cart.\n\nYour cart hasn't been empty since you left. So we’ve saved them for you.",
    'messages:reminderCreatorActivation':
      "Hi {{username}},\n\nWelcome to the club, we're happy to inform you that your account has been successfully verified.\n\nYou've joined with all creative creators around the world\n\nstart uploading your first artwork today!\n\nnote:\nAll the club has rule, so we are you can read it here",
    'messages:reminderResendContent':
      'Hi {{username}},\n\n{{collector_nickname}} has bought your creation.',
    'messages:reminderResendContentExtra':
      'Please resend your high-resolution creation within 2 days.',
    'messages:reminderRobopet':
      'Long time no see {{username}},\n\nI has just choose some amazing content for you\n\nCheck it now at explore',
    'messages:reminderWaitingHires':
      'You just bought a creation from {{creator_username}}.',
    'messages:reminderWaitingHiresGreet': 'Hi {{username}},',
    'messages:reminderWaitingHiresExtra':
      'If your content is still in preview version, the creator will resend the Hi-Res within 2 days, if not, you will receive a full refund (excluding bank fee).',
    'messages:reminderWishlist':
      "Hi {{username}},\n\nDid you know that creator recently changed something in content detail in your wishlist\n\nlet's find out what's new",
    'messages:resendpologyButton': 'Resendpology',
    'messages:system': 'System',
    'messages:system_input': 'You cannot reply to system messages.',
    'messages:system_notification': 'Notifications',
    'messages:system_robopet': 'RoboYu',
    'messages:unsupported': 'Unsupported Message',
    'messages:userNotFound': 'Yuser Not Found',
  },
}
