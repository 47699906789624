import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  formatCooldownTime,
  getFontFamily,
  showSnackbar,
  useHistory,
  useLocation,
} from 'utils'
import {useTheme} from 'themes'
import {requestData} from 'services'
import {Button, Icon, Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch} from 'lib/redux'

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(10)};
`
const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(12)};
  margin-top: ${convertUnit(40)};
`
const StyledDisclaimerContainer = styled.div`
  background-color: ${({theme}) => theme.white_3};
  border-radius: ${convertUnit(8)};
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(8)} ${convertUnit(12)};
  gap: ${convertUnit(12)};
`
const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fill-available;
  gap: ${convertUnit(12)};
`
const StyledButton = styled(Button)`
  width: 100%;
`

export default function AuthRecoverAccount() {
  const {translate} = useTranslation()
  const theme = useTheme()
  const history = useHistory()
  const params = useLocation('auth_recover_account').state
  const {
    remaining_time,
    identifier,
    email,
    phone_code,
    phone_number,
    member_id,
    recovery_code,
    password,
    method,
    token,
  } = params
  const [loading, setLoading] = useState(false)
  const {update} = useDispatch()

  const remainingDay = useMemo(() => {
    const {day} = formatCooldownTime(remaining_time || '', true)
    return parseInt(day, 10) <= 1 ? '1' : day
  }, [remaining_time])

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeaderContainer>
        <StyledTitleContainer>
          <Icon type="refresh" size={108} />
          <Paragraph fontSize="xl" fontWeight="bold">
            {translate('auth:recoverAccount')}
          </Paragraph>
        </StyledTitleContainer>
        <Paragraph fontSize="m">
          <ParsedText
            parse={[
              {
                pattern: /<recover>.*?<\/recover>/,
                style: {
                  fontFamily: getFontFamily('bold'),
                },
                renderText: (text) =>
                  text.replace(/<recover>|<\/recover>/g, ''),
              },
            ]}>
            {translate('auth:recoverAccountDescription')}
          </ParsedText>
        </Paragraph>
      </StyledHeaderContainer>
    ),
    [translate],
  )

  const handleRecoverAccount = useCallback(() => {
    setLoading(true)
    if (password || token) {
      // hit recover endpoint if this account is logged in via gosso/apple or has password
      requestData('auth_recover_account', {
        data: {
          member_id,
          recovery_code,
          is_sso_login: !!token,
        },
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            history.replace('auth_recover_account_success', {
              identifier,
              password,
              method,
              token,
            })
          } else {
            showSnackbar(
              translate('global:messageError', {context: `code-${status}`}),
            )
          }
        },
      })
    } else {
      // send recovery link if this account isn't sso and has no password
      requestData('auth_recover_account_send_link', {
        data: {member_id, recovery_code},
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status, data: {detail}}) => {
          if (status === 200) {
            update(
              'lastUserState',
              email
                ? {emailTime: new Date().getTime()}
                : {phoneOTPTime: new Date().getTime()},
            )
            history.replace('auth_recover_account_link_verif', {
              email,
              phone_code,
              phone_number,
              identifier,
              method: email ? 'email' : 'whatsapp',
            })
          } else if (status === 410 && detail) {
            const errorDetail = detail
            const {hour, minute, second} = formatCooldownTime(
              errorDetail.resend_in,
            )
            showSnackbar(
              translate(
                errorDetail.is_limit
                  ? 'auth:registerEasySignupResendLinkLimitSnackbar'
                  : 'auth:registerEasySignupResendLinkCooldownSnackbar',
                {
                  hour,
                  minute,
                  second,
                },
              )
                .replace(
                  hour === ''
                    ? /<hourStamp>.*?<\/hourStamp>/
                    : /<hourStamp>|<\/hourStamp>/g,
                  '',
                )
                .replace(
                  minute === ''
                    ? /<minuteStamp>.*?<\/minuteStamp>/
                    : /<minuteStamp>|<\/minuteStamp>/g,
                  '',
                )
                .replace(/<secondStamp>|<\/secondStamp>/g, ''),
              [
                {
                  pattern: /<timestamp>.*?<\/timestamp>/,
                  style: {
                    fontFamily: 'Roboto-Bold',
                  },
                  renderText: (text) =>
                    text.replace(/<timestamp>|<\/timestamp>/g, ''),
                },
              ],
            )
          }
        },
      })
    }
  }, [
    email,
    history,
    identifier,
    member_id,
    method,
    password,
    phone_code,
    phone_number,
    recovery_code,
    token,
    translate,
    update,
  ])

  const handleRenderFooter = useMemo(
    () => (
      <StyledFooterContainer>
        <StyledDisclaimerContainer>
          <Icon type="info" size={convertUnit(16)} />
          <Paragraph>
            <ParsedText
              parse={[
                {
                  pattern: /<time>.*?<\/time>/,
                  style: {
                    color: theme.danger_5,
                    fontFamily: getFontFamily('bold'),
                  },
                  renderText: (text) => text.replace(/<time>|<\/time>/g, ''),
                },
              ]}>
              {translate('auth:recoverAccountNotice', {
                time: remainingDay || '',
              })}
            </ParsedText>
          </Paragraph>
        </StyledDisclaimerContainer>
        <StyledButtonContainer>
          <StyledButton
            label={translate('global:recover')}
            onClick={handleRecoverAccount}
            isLoading={loading}
          />
          <StyledButton
            color="primary_5"
            backgroundColor="white_3"
            label={translate('global:logout')}
            onClick={() => history.goBack()}
          />
        </StyledButtonContainer>
      </StyledFooterContainer>
    ),
    [
      handleRecoverAccount,
      history,
      loading,
      remainingDay,
      theme.danger_5,
      translate,
    ],
  )

  return (
    <>
      {handleRenderHeader}
      {handleRenderFooter}
    </>
  )
}
