import React, {CSSProperties} from 'react'
import styled from 'styled-components'
import {getThemeColor} from 'utils'
import convertUnit from 'lib/unit'
import {ModalProps} from './ModalProps'

interface StyledModalOverlayProps {
  full: boolean
}

interface StyledModalProps extends CSSProperties {
  isHalfScreen?: boolean
}

const StyledModalOverlay = styled.div<StyledModalOverlayProps>`
  ${({full}) => !full && {padding: convertUnit(25), boxSizing: 'border-box'}}
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  align-items: center;
  justify-content: center;
`

const StyledBackdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.5)};
`

const StyledModal = styled.div<StyledModalProps>`
  background-color: ${({theme}) => theme.white_1};
  transition: all 0.5s ease-in-out;
  z-index: 10;
  ${({isHalfScreen, borderRadius = convertUnit(16)}) => ({
    width: isHalfScreen ? '100%' : 'auto',
    bottom: '0',
    position: isHalfScreen ? 'fixed' : 'relative',
    borderRadius: isHalfScreen
      ? `${convertUnit(16)} ${convertUnit(16)} 0 0`
      : borderRadius,
  })}
`

export default function Modal({
  visible,
  children,
  full = true,
  onBackdropPress,
  isHalfScreen = false,
  ...props
}: ModalProps) {
  return visible ? (
    <StyledModalOverlay full={full}>
      <StyledBackdrop onClick={onBackdropPress} />
      <StyledModal {...props} isHalfScreen={isHalfScreen}>
        {children}
      </StyledModal>
    </StyledModalOverlay>
  ) : null
}
