import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  FORKYGRAM_UPLOAD_MAX_VIDEO_SIZE,
  FORKYGRAM_UPLOAD_TYPE,
  TREE_UPLOAD_VIDEO_TYPE,
} from 'consts'
import {useTranslation} from 'i18n'
import {FullscreenVideoIntroModal} from 'pages'
import {getBorder, handleTreeReadFile, showSnackbar, useDidMount} from 'utils'
import {DragDrop, Icon, ConfirmationModal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {TreePlantUploadVideoProps} from './TreePlantUploadVideoProps'

const StyledVideo = styled.video`
  width: ${convertUnit(113)};
  height: ${convertUnit(136)};
  object-fit: scale-down;
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.gray_1};
  cursor: pointer;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${convertUnit(20)};
  gap: ${convertUnit(4)};
`

const StyledDragContainer = styled.div`
  ${({theme}) => ({
    border: getBorder(2, 'dashed', theme.gray_3),
    ':hover': {
      borderColor: theme.primary_5,
    },
  })}
  height: 100%;
  min-height: ${convertUnit(165)};
  width: 100%;
  border-radius: ${convertUnit(16)};
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`

const StyledUl = styled.ul`
  margin: 0;
`

const StyledVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const StyledIconContainer = styled.div`
  display: flex;
  position: absolute;
  top: ${convertUnit(40)};
  left: ${convertUnit(30)};
  cursor: pointer;
`

export default function TreePlantUploadVideo({
  srcState,
  videoForm,
  onFileAccept,
}: TreePlantUploadVideoProps) {
  const [src, setSrc] = srcState
  const {translate} = useTranslation()
  const {setValue, register} = videoForm
  const stateVideoModal = useState(false)
  const setVideoModal = stateVideoModal[1]
  const [confirmationModal, setConfirmationModal] = useState(false)

  const handleInitialValidation = useCallback(
    (type: string, size: number) => {
      if (!TREE_UPLOAD_VIDEO_TYPE.includes(type)) {
        showSnackbar(translate('tree:plantFototreeVideoInvalidFormat'))
        return false
      }

      if (type.startsWith('video') && size > FORKYGRAM_UPLOAD_MAX_VIDEO_SIZE) {
        showSnackbar(translate('forkygram:uploadMaxVideoSizeMessage'))
        return false
      }

      return true
    },
    [translate],
  )

  const handleCheckVideo = useCallback(
    async (inputFile: File) => {
      if (inputFile) {
        let {type} = inputFile
        const {size: fileSize, name} = inputFile

        if (type === '') {
          type = name.substring(name.lastIndexOf('.') + 1).toLowerCase() ?? ''
          FORKYGRAM_UPLOAD_TYPE.some((currentType) => {
            const typeMatchesWithExtension = currentType.includes(type)
            if (typeMatchesWithExtension) type = currentType
            return typeMatchesWithExtension
          })
        }

        const validate = handleInitialValidation(type, fileSize)
        if (validate) {
          const metadata = await handleTreeReadFile(inputFile)
          metadata && srcState && srcState[1](metadata?.src)

          setValue('extension', metadata?.extension)
          setValue('height', metadata?.height)
          setValue('width', metadata?.width)
          setValue('size', metadata?.size)
          setValue('file', metadata?.file)
          setValue('mime', metadata?.type)
        }
      }
    },
    [handleInitialValidation, setValue, srcState],
  )

  const handleRenderContent = useMemo(
    () =>
      src ? (
        <StyledVideoContainer>
          <StyledIconContainer>
            <Icon type="play" size={40} color="white_1" />
          </StyledIconContainer>
          <StyledVideo autoPlay={false} onClick={() => setVideoModal(true)}>
            <source src={src} type="video/mp4" />
            <source src={src} type="video/mov" />
            <source src={src} type="video/heiv" />
          </StyledVideo>
          <Paragraph
            fontSize={'s'}
            fontWeight="bold"
            color="primary_5"
            onClick={() => setConfirmationModal(true)}
            style={{cursor: 'pointer'}}>
            {translate('global:delete')}
          </Paragraph>
        </StyledVideoContainer>
      ) : (
        <StyledDragContainer>
          <DragDrop
            icon="upload"
            accept={TREE_UPLOAD_VIDEO_TYPE}
            header={
              <Paragraph fontSize="s" fontWeight="medium" color="gray_5">
                {translate('global:selectVideo')}
              </Paragraph>
            }
            description={
              <StyledUl>
                <li>
                  <Paragraph fontSize="xs" fontWeight="regular" color="gray_5">
                    {translate('tree:plantFototreeVideoExtension')}
                  </Paragraph>
                </li>
                <li>
                  <Paragraph fontSize="xs" fontWeight="regular" color="gray_5">
                    {translate('tree:plantFototreeVideoMaxWidth')}
                  </Paragraph>
                </li>
                <li>
                  <Paragraph fontSize="xs" fontWeight="regular" color="gray_5">
                    {translate('tree:plantFototreeVideoMaxSize')}
                  </Paragraph>
                </li>
              </StyledUl>
            }
            multiple={false}
            onDrop={(acceptedFiles) => {
              handleCheckVideo(acceptedFiles[0])
            }}
            onDropRejected={() => {
              showSnackbar(translate('tree:plantFototreeVideoInvalidFormat'))
            }}
            onDropAccepted={onFileAccept}
          />
        </StyledDragContainer>
      ),
    [handleCheckVideo, onFileAccept, setVideoModal, src, translate],
  )

  const handleRenderVideo = useMemo(
    () => (
      <FullscreenVideoIntroModal
        isLocal
        stateModal={stateVideoModal}
        src={src}
      />
    ),
    [stateVideoModal, src],
  )

  const handleRenderConfirmationModal = useMemo(
    () => (
      <ConfirmationModal
        title={translate('global:delete')}
        confirmText={translate('global:yesImSure')}
        cancelText={translate('global:stay')}
        text={translate('tree:deleteVideoConfirmation')}
        toggleModal={() => setConfirmationModal((prev) => !prev)}
        onConfirm={() => setSrc('')}
        visible={confirmationModal}
      />
    ),
    [translate, confirmationModal, setSrc],
  )

  useDidMount(() => {
    register('extension')
    register('height')
    register('width')
    register('size')
    register('file')
    register('mime')
  })

  return (
    <StyledContainer>
      {handleRenderConfirmationModal}
      {handleRenderVideo}
      <Paragraph fontSize={'m'} fontWeight="medium">
        {translate('global:uploadVideoOptional')}
      </Paragraph>
      {handleRenderContent}
    </StyledContainer>
  )
}
